import { makeStyles } from "@material-ui/core";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { ImportIcon } from "src/components/AssembleIcons/Brand/ImportIcon";
import { PlusIcon } from "src/components/AssembleIcons/Brand/PlusIcon";
import { useBonusGuidanceAndBudget } from "./useBonusGuidanceAndBudget";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(1.5),
  },
}));

type Props = {
  handleOpenTargetBonusesModal: () => void;
};

export function BonusGuidanceButtonBar({
  handleOpenTargetBonusesModal,
}: Props): JSX.Element {
  const classes = useStyles();
  const {
    modals: {
      companyAttainment: { setOpen: handleGuidancePopulationModalOpen },
    },
  } = useBonusGuidanceAndBudget();

  return (
    <div className={classes.root}>
      <AssembleButton
        size="medium"
        variant="outlined"
        label="Company Attainment"
        startIcon={<PlusIcon width="24px" height="24px" inherit />}
        onClick={() => handleGuidancePopulationModalOpen(true)}
      />
      <AssembleButton
        size="medium"
        variant="outlined"
        label="Update Targets"
        startIcon={<ImportIcon inherit />}
        onClick={handleOpenTargetBonusesModal}
      />
    </div>
  );
}
