import { makeStyles } from "@material-ui/core";
import { Outlet } from "react-router-dom";
import { SETTINGS_SIDEBAR_WIDTH, theme } from "src/theme";
import { Header } from "./Header";

export enum GuidanceAndBudgetTabs {
  Merit = "merit",
  Bonus = "bonus",
}

const useStyles = makeStyles(() => ({
  root: { paddingLeft: SETTINGS_SIDEBAR_WIDTH },
  outletContainer: { padding: theme.spacing(3, 4) },
}));

type Props = { compCycleId: number };

export function GuidanceAndBudget({ compCycleId }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Header compCycleId={compCycleId} />
      <div className={classes.outletContainer}>
        <Outlet />
      </div>
    </div>
  );
}
