import { Table, TableBody, makeStyles } from "@material-ui/core";
import { ComponentType, useEffect, useMemo, useRef } from "react";
import {
  CellProps,
  Column,
  useColumnOrder as useReactTableColumnOrder,
  useTable,
} from "react-table";
import { useVirtual } from "react-virtual";
import {
  CondensedTable_compCycle,
  CondensedTable_compCycleBudget,
  CondensedTable_valuation,
  CondensedTable_participant as Participant,
} from "src/__generated__/graphql";

import { debounce } from "lodash";
import { BulkActionsBar } from "src/components/CompCycle/AppBar/BulkActionsBar";
import { CycleBudgetAppBar } from "src/components/CompCycle/AppBar/CycleBudgetAppBar";
import { WHITE } from "src/theme";
import { useBulkActionsData } from "../Contexts/BulkActionsContext";
import { useColumnOrder } from "../Contexts/ColumnOrderContext";
import { useTableData } from "../Contexts/TableDataContext2";
import { CondensedTableInnerHeader } from "./CondensedTableInnerHeader";
import { CondensedTableRow } from "./CondensedTableRow";

export type ColumnComponent2 = ComponentType<CellProps<Participant>> & {
  id: string;
  column: Column<Participant>;
  sortable?: boolean;
};

const useStyles = makeStyles(() => ({
  scrollContainer: {
    overflowX: "auto",
    paddingBottom: "7.5rem",
    zIndex: 0,
    height: "100%",
  },
  root: {
    backgroundColor: WHITE,
    position: "relative",
    width: "100%",
  },
}));

type Props = {
  columns: ColumnComponent2[];
  employees: Participant[];
  valuation: CondensedTable_valuation;
  budget: CondensedTable_compCycleBudget | null;
  compCycle: CondensedTable_compCycle;
};

export function CondensedTableInner({
  columns,
  employees,
  compCycle,
  valuation,
  budget,
}: Props): JSX.Element {
  const classes = useStyles();
  const {
    hiddenColumns,
    columns: orderedColumns,
    staticColumns,
  } = useColumnOrder();

  const { isViewOnly } = useTableData();
  const { selectedCount } = useBulkActionsData();

  const showBulkActions = selectedCount > 0 && !isViewOnly;
  const columnConfig = useMemo(() => columns.map((c) => c.column), [columns]);

  const data = useMemo(() => employees, [employees]);

  const memoColumns = useMemo(() => columnConfig, [columnConfig]);

  const {
    getTableProps,
    getTableBodyProps,
    headers,
    rows,
    prepareRow,
    setColumnOrder,
    setHiddenColumns,
  } = useTable(
    {
      columns: memoColumns,
      data,
      initialState: { hiddenColumns },
    },
    useReactTableColumnOrder
  );

  useEffect(() => {
    setColumnOrder([...staticColumns, ...orderedColumns]);
  }, [orderedColumns, setColumnOrder, staticColumns]);

  useEffect(() => {
    setHiddenColumns(hiddenColumns);
  }, [hiddenColumns, setHiddenColumns]);

  const parentRef = useRef<HTMLDivElement>(null);
  // used for budget drawer to take into account horizontal scrollbar
  const scrollBarHeight = parentRef.current
    ? parentRef.current.offsetHeight - parentRef.current.clientHeight
    : 0;

  // store the user's scroll position so table
  // can be restored after filtering or sorting
  const saveScrollPosition = (scrollableDiv: HTMLDivElement) => {
    localStorage.setItem(
      "planTableScrollPosition",
      scrollableDiv.scrollLeft.toString()
    );
  };

  useEffect(() => {
    const scrollPosition = localStorage.getItem("planTableScrollPosition");
    if (scrollPosition && parentRef.current) {
      parentRef.current.scrollLeft = parseInt(scrollPosition, 10);
    }
  }, []);

  const debouncedHandleScroll = debounce(() => {
    if (parentRef.current) {
      saveScrollPosition(parentRef.current);
    }
  }, 1000);

  const rowVirtualizer = useVirtual({
    size: rows.length,
    parentRef,
    overscan: 10,
    paddingStart: 40,
  });

  return (
    <>
      <div
        ref={parentRef}
        className={classes.scrollContainer}
        onScroll={debouncedHandleScroll}
      >
        <Table
          {...getTableProps()}
          className={classes.root}
          style={{ height: `${rowVirtualizer.totalSize}px` }}
        >
          <CondensedTableInnerHeader headers={headers} />
          <TableBody {...getTableBodyProps()}>
            {rowVirtualizer.virtualItems.map((virtualRow) => {
              return (
                <CondensedTableRow
                  key={virtualRow.index}
                  virtualRow={virtualRow}
                  rows={rows}
                  prepareRow={prepareRow}
                />
              );
            })}
          </TableBody>
        </Table>
      </div>
      <CycleBudgetAppBar
        compCycle={compCycle}
        budget={budget}
        valuation={valuation}
        bottomOffset={scrollBarHeight}
      />
      {showBulkActions && <BulkActionsBar />}
    </>
  );
}
