import { gql } from "@apollo/client";
import { pluckOptionsBy } from "@asmbl/shared/filter";
import { distinct, mapMaybe } from "@asmbl/shared/utils";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import {
  CompCycleReviewRequests_compCycle,
  CompRecommendationStatus,
  Noun,
  RecReviewStatus,
} from "../../../__generated__/graphql";
import { AssembleLink } from "../../../components/AssembleLink";
import { useAuth } from "../../../components/Auth/AuthContext";
import { CompleteCompCycleButton } from "../../../components/CompCycle/Buttons/CompleteCompCycleButton";
import {
  CompleteReviewDialog,
  useCompleteReviewDialogState,
} from "../../../components/CompCycle/Dialogs/CompleteReviewDialog";
import { CompleteReviewDialogOpen } from "../../../components/CompCycle/Dialogs/CompleteReviewDialogOpen";
import { CompCycleCsvExportWrapper } from "../../../components/CsvExport/CompCycleExportWrapper";
import { ExplanatoryTooltip } from "../../../components/ExplanatoryTooltip";
import { FilterSelect } from "../../../components/Filter/FilterSelect";
import { FilterParam, getNumericParam } from "../../../models/FilterParams";
import { useURLSearchParams } from "../../../models/URLSearchParams";
import { GRAY_6, WHITE } from "../../../theme";
import { ApproverCard } from "./ApproverCard";
import { CompCycleReviewer } from "./CompCycleReviewRequestsBoundary";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "80px",
    background: WHITE,
    padding: theme.spacing(2, 4, 2, 4),
    borderBottom: `1px solid ${GRAY_6}`,
  },
  filterBar: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(3),
  },
  leftSideFilterBar: {
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
  },
  rightSideFilterBar: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "1rem",
  },
  managerCardWrapper: {
    marginBottom: theme.spacing(2),
  },
  managerCardLink: {
    display: "block",
    borderRadius: "5px",
    overflow: "hidden",
    "&:active": {
      boxShadow: "0px 0px 0px 3px rgba(100, 91, 255, 0.3)",
    },
  },
  filters: {
    padding: theme.spacing(3, 2),
  },
  phaseHeader: {
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}));

export type CompCycleReviewerWithStatus = CompCycleReviewer & {
  status: RecReviewStatus | CompRecommendationStatus;
};

type Props = {
  authors: CompCycleReviewer[];
  compCycle: CompCycleReviewRequests_compCycle;
  onComplete: () => Promise<boolean>;
};

export function CompCycleReviewRequests({
  authors,
  compCycle,
  onComplete,
}: Props): JSX.Element {
  const classes = useStyles();
  const { permissions } = useAuth();
  const [dialogState, setDialogState] = useCompleteReviewDialogState(
    compCycle.closeDate
  );
  const canCompleteCompCycle = permissions.canEditGlobal(Noun.CompCycle);

  const urlSearchParams = useURLSearchParams();
  const filterableAuthors = authors.map(addReviewStatus);

  const locationParam = getNumericParam(urlSearchParams, FilterParam.LOCATION);
  const managerParam = getNumericParam(urlSearchParams, FilterParam.MANAGER);

  const filteredAuthors = filterableAuthors
    .filter((a) => locationParam === "all" || a.location?.id === locationParam)
    .filter((a) => managerParam === "all" || a.id === managerParam);

  const allCompRecs = mapMaybe(
    authors.flatMap((a) => a.assignees),
    (r) => r.compRecommendation
  );

  const uniqueRecs = distinct(allCompRecs, "subjectId");

  const awaitingReviewCount = uniqueRecs.filter(
    (r) => r.reviewStatus === CompRecommendationStatus.NEEDS_REVIEW
  ).length;

  return (
    <>
      {dialogState !== "open" && (
        <CompleteReviewDialog
          compCycle={compCycle}
          dialogState={dialogState}
          setDialogState={setDialogState}
        />
      )}
      {dialogState === "open" && (
        <CompleteReviewDialogOpen
          setDialogState={setDialogState}
          onSubmit={onComplete}
          reviewCount={awaitingReviewCount}
        />
      )}
      <div className={classes.filters}>
        <div className={classes.filterBar}>
          <div className={classes.leftSideFilterBar}>
            <FilterSelect
              label="Managers"
              param={FilterParam.MANAGER}
              options={[
                { label: "All Managers", value: "all" },
                ...pluckOptionsBy(
                  filterableAuthors,
                  (author) => author.id,
                  (author) => author.displayName
                ),
              ]}
            />
            <FilterSelect
              label="Locations"
              param={FilterParam.LOCATION}
              options={[
                { label: "All Locations", value: "all" },
                ...pluckOptionsBy(
                  filterableAuthors,
                  (author) => author.location?.id,
                  (author) => author.location?.name
                ),
              ]}
            />
          </div>
          <div className={classes.rightSideFilterBar}>
            <ExplanatoryTooltip
              title="Export your comp changes"
              body={
                <p>
                  Export every change that has been <b>approved</b> by your comp
                  admin or is <b>awaiting approval</b>.
                </p>
              }
            >
              <span>
                <CompCycleCsvExportWrapper
                  compCycleId={compCycle.id}
                  compCycleName={compCycle.name}
                  variant="button"
                />
              </span>
            </ExplanatoryTooltip>
            {canCompleteCompCycle && (
              <CompleteCompCycleButton
                compCycle={compCycle}
                setDialogState={setDialogState}
              />
            )}
          </div>
        </div>
        {filteredAuthors.map((author) =>
          compCycle.closeDate === null ? (
            <AssembleLink
              to={`/comp-cycles/${compCycle.id}/requests?manager=${author.id}`}
              color="textSecondary"
              underline="none"
              key={author.id}
              className={clsx(
                classes.managerCardWrapper,
                classes.managerCardLink
              )}
            >
              <ApproverCard approver={author} />
            </AssembleLink>
          ) : (
            <div className={classes.managerCardWrapper} key={author.id}>
              <ApproverCard approver={author} />
            </div>
          )
        )}
      </div>
    </>
  );
}

function addReviewStatus(
  author: CompCycleReviewer
): CompCycleReviewerWithStatus {
  const reports = author.assignees;
  if (
    reports.every(
      (r) =>
        r.compRecommendation?.latestSubmittedReviews.at(0)?.status ===
        RecReviewStatus.APPROVED
    )
  ) {
    return { ...author, status: RecReviewStatus.APPROVED };
  }
  if (
    reports.some(
      (r) =>
        r.compRecommendation?.latestSubmittedReviews.at(0)?.status ===
        RecReviewStatus.DENIED
    )
  ) {
    return { ...author, status: RecReviewStatus.DENIED };
  }

  return { ...author, status: CompRecommendationStatus.NEEDS_REVIEW };
}

CompCycleReviewRequests.fragments = {
  reviewer: gql`
    ${ApproverCard.fragments.reviewer}
    fragment CompCycleReviewRequests_employee on Employee2 {
      id
      location {
        id
        name
      }
      ...ApproverCard_employee
    }
  `,
  compCycle: gql`
    fragment CompCycleReviewRequests_compCycle on CompCycle2 {
      id
      closeDate
      name
    }
  `,
};
