import { Money } from "@asmbl/shared/money";
import { formatNumeral } from "@asmbl/shared/utils";
import { TableCell, Typography } from "@material-ui/core";
import clsx from "clsx";
import { CompUnit, RecItemType } from "src/__generated__/graphql";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { ExplanatoryTooltip } from "src/components/ExplanatoryTooltip";
import { useRecItemRowStyles } from "../CompTable/LineItemRows/styles";
import { CompCycleLineItemRowProps } from "../CompTable/LineItemRows/types";

interface Props extends CompCycleLineItemRowProps {
  label: string;
  basePay: { annualCashEquivalent: Money; hourlyCashEquivalent: Money } | null;
  guidance?: number | { message: string } | null;
}

export function ApplyGuidanceCell({
  label,
  basePay,
  onChangeRecommendationItem,
  guidance,
}: Props): JSX.Element {
  const classes = useRecItemRowStyles();

  const applyGuidance = () => {
    if (typeof guidance !== "number" || basePay == null) return;
    onChangeRecommendationItem({
      note: "Applied from guidance suggestion",
      recommendationType: RecItemType.MERIT_INCREASE,
      // guidance is a percentage, so to avoid incorrect floats being stored,
      // we multiply by 10_000, round, and then divide by 100
      recommendedPercentValue: Math.round(guidance * 10_000) / 100,
      unitType: CompUnit.PERCENT_OF_SALARY,
    });
  };

  const isGuidanceConfigured =
    typeof guidance === "number" ||
    (typeof guidance === "object" &&
      guidance?.message !== "Guidance not configured");

  const hasGuidance = typeof guidance === "number";

  return (
    <TableCell
      role="gridcell"
      colSpan={5}
      padding="none"
      className={classes.textCell}
    >
      <Typography
        className={clsx(classes.rowLabel, {
          [classes.inline]: true,
        })}
      >
        {label}
      </Typography>
      {isGuidanceConfigured && guidance != null ? (
        <Typography
          className={clsx(
            classes.rowLabel,
            classes.guidanceLabel,
            classes.inline
          )}
        >
          Guidance:{" "}
          {hasGuidance
            ? formatNumeral(guidance, {
                style: "percent",
                maximumFractionDigits: 4,
              })
            : "N/A"}
          <ExplanatoryTooltip
            body={typeof guidance !== "number" ? guidance.message : ""}
            disabled={hasGuidance}
          >
            <span>
              <AssembleButton
                className={clsx(classes.applyElement, {
                  [classes.noGuidance]: !hasGuidance,
                })}
                size="small"
                variant="text"
                label={hasGuidance ? "Apply" : "Why not?"}
                onClick={applyGuidance}
                disabled={!hasGuidance}
              />
            </span>
          </ExplanatoryTooltip>
        </Typography>
      ) : null}
    </TableCell>
  );
}
