import { gql, useQuery } from "@apollo/client";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import {
  CondensedTablePeopleLoadingBoundaryQuery,
  CondensedTablePeopleLoadingBoundaryQueryVariables,
  CondensedTableView_compCycle,
} from "src/__generated__/graphql";
import { ErrorView } from "src/components/ErrorView";
import { useFeatureFlags } from "src/components/FeatureContext";
import { LoadingSpinner } from "src/components/LoadingSpinner";
import { usePagination } from "../Contexts/PaginationContext";
import { useTableFilterContext } from "../Contexts/TableFilterContext";
import { CondensedTable } from "./CondensedTable";
import { CurrencyCodeWithAll } from "./CondensedTableCurrencyPicker";

type Props = {
  compCycleId: number;
  isAdmin: boolean;
  nameSearch: string | null;
  selectedCurrency: CurrencyCodeWithAll;
  compCycle: CondensedTableView_compCycle;
  isViewOnly: boolean;
};

export function CondensedTablePeopleLoadingBoundary({
  compCycleId,
  isAdmin,
  nameSearch,
  selectedCurrency,
  compCycle,
  isViewOnly,
}: Props): JSX.Element {
  const { filter, actingManagerEmployeeId } = useTableFilterContext();
  const {
    pageSize,
    currentPage,
    setTotalCount,
    sortBy,
    sortDir,
    setCurrentEmployeeIds,
  } = usePagination();

  const { isEnabled } = useFeatureFlags();
  const hideLastCompChange = isEnabled(FeatureFlag.HideLastCompChange);
  const currencyCode = selectedCurrency === "all" ? null : selectedCurrency;

  const queryVariables = {
    compCycleId,
    filter: { ...filter, displayName: nameSearch },
    currencyCode,
    // compCycleBudget arg, but same as managerIds filter
    employeeIds: filter.managerIds ?? [],
    limit: pageSize,
    offset: currentPage * pageSize,
    isAdmin,
    skipEligibility: true,
    sort: { sortBy, sortDir },
    actingManagerEmployeeId,
    loadSalary: compCycle.allowSalary,
    loadMerit: compCycle.allowSalaryMerit,
    loadMarket: compCycle.allowSalaryMarket,
    loadPromotions: compCycle.allowSalaryPromotion,
    loadTargetCommission: compCycle.allowTargetCommission,
    loadEquity: compCycle.allowEquity,
    loadBonus: compCycle.allowBonus,
    loadActualRecurringBonus: compCycle.allowActualRecurringBonus,
    loadTargetRecurringBonus: compCycle.allowTargetRecurringBonus,
    loadTargetCash:
      compCycle.allowSalary ||
      compCycle.allowTargetCommission ||
      compCycle.allowTargetRecurringBonus,
    skipLastCompChange: hideLastCompChange,
  };

  const { data, loading } = useQuery<
    CondensedTablePeopleLoadingBoundaryQuery,
    CondensedTablePeopleLoadingBoundaryQueryVariables
  >(CondensedTablePeopleLoadingBoundary.query, {
    variables: queryVariables,
    onCompleted: (data: CondensedTablePeopleLoadingBoundaryQuery) => {
      if (data.compCycle2?.participants.count != null) {
        setTotalCount(data.compCycle2.participants.count);
        setCurrentEmployeeIds(
          data.compCycle2.participants.participants.map((e) => e.subjectId)
        );
      }
    },
  });

  if (
    !data ||
    data.compCycle2 == null ||
    data.valuation === null ||
    data.whoami?.user == null
  ) {
    return loading ? (
      <LoadingSpinner />
    ) : (
      <ErrorView text="Reach out to your comp admin for assistance." />
    );
  }

  return (
    <CondensedTable
      selectedCurrency={selectedCurrency}
      compCycle={{ ...compCycle, ...data.compCycle2 }}
      employees={data.compCycle2.participants.participants}
      isActiveCycle={data.compCycle2.closeDate === null}
      availablePositions={data.positions}
      budget={data.compCycleBudgets}
      valuation={data.valuation}
      user={data.whoami.user}
      matrices={data.compCycle2.matrices}
      isViewOnly={isViewOnly}
    />
  );
}

const MATRIX_PERF_RATING_OPTION = gql`
  fragment CondensedTable_matrixPerfRatingOption on MatrixPerfRatingOption {
    id
    name
    organizationId
    rank
  }
`;

const MATRIX_RANGE = gql`
  fragment CondensedTable_matrixRange on MatrixRange {
    id
    rangeStart
  }
`;

const MATRIX_GUIDE = gql`
  ${MATRIX_PERF_RATING_OPTION}
  ${MATRIX_RANGE}
  fragment CondensedTable_matrixGuide on MatrixGuide {
    id
    matrixPerfRatingOption {
      id
      ...CondensedTable_matrixPerfRatingOption
    }
    matrixRange {
      id
      ...CondensedTable_matrixRange
    }
    percent
    matrixId
  }
`;

const MATRIX = gql`
  ${MATRIX_GUIDE}
  fragment CondensedTable_matrix on Matrix {
    id
    type
    eligibleParticipants
    siblingMatrices {
      id
      type
    }
    matrixGuides {
      id
      ...CondensedTable_matrixGuide
    }
    bonusGuidanceSettings {
      id
      isDimensionEnabled
      customDimensionName
      customDimensionValueType
    }
  }
`;

CondensedTablePeopleLoadingBoundary.query = gql`
  ${CondensedTable.fragments.compCycle}
  ${CondensedTable.fragments.compCycleBudget}
  ${CondensedTable.fragments.participant}
  ${CondensedTable.fragments.position}
  ${CondensedTable.fragments.valuation}
  ${CondensedTable.fragments.user}
  ${MATRIX}
  query CondensedTablePeopleLoadingBoundaryQuery(
    $compCycleId: Int!
    $filter: GetParticipantsInput
    $currencyCode: CurrencyCode
    $employeeIds: [Int!]!
    $limit: Int
    $offset: Int
    $sort: Sort
    $isAdmin: Boolean!
    $loadSalary: Boolean!
    $loadMerit: Boolean!
    $loadMarket: Boolean!
    $loadPromotions: Boolean!
    $loadBonus: Boolean!
    $loadTargetCommission: Boolean!
    $loadActualRecurringBonus: Boolean!
    $loadTargetRecurringBonus: Boolean!
    $loadEquity: Boolean!
    $loadTargetCash: Boolean!
    $skipLastCompChange: Boolean!
    $skipEligibility: Boolean
    $actingManagerEmployeeId: Int
  ) {
    compCycle2(id: $compCycleId) {
      id
      name
      closeDate
      ...CondensedTable_compCycle

      participants(
        input: $filter
        limit: $limit
        offset: $offset
        sort: $sort
      ) {
        count
        participants {
          compCycleId
          subjectId
          compRecommendation(skipEligibility: $skipEligibility) {
            latestSubmittedReviews {
              id
              status
              authorId
            }
            reviewStatus
          }
          ...CondensedTable_participant
        }
      }

      matrices {
        id
        ...CondensedTable_matrix
      }
    }
    positions {
      id
      ...CondensedTable_position
    }
    valuation {
      id
      ...CondensedTable_valuation
    }
    compCycleBudgets(
      compCycleId: $compCycleId
      employeeIds: $employeeIds
      currencyCode: $currencyCode
    ) {
      ...CondensedTable_compCycleBudget
    }
    whoami {
      user {
        id
        ...CondensedTable_user
      }
    }
  }
`;
