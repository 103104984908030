import { gql } from "@apollo/client";
import { formatCurrency, preferredPrice, zero } from "@asmbl/shared/money";
import { TableCell, TableRow, Typography } from "@material-ui/core";
import { EquityRefreshCondensedSlider } from "src/components/CompSlider/Equity/EquityRefreshCondensedSlider";
import {
  CompComponent,
  CompUnit,
  CurrencyCode,
  EquityGrantMethod,
  EquityGrantRow_employee,
  EquityGrantRow_valuation,
  RecItemType,
} from "../../../../__generated__/graphql";
import { useTrack } from "../../../../analytics";
import { hasUnpublishedChanges } from "../../../../models/CompRecommendation";
import { useCompStructure } from "../../../CompStructureContext";
import { useCurrencies } from "../../../CurrenciesContext";
import { EquityGrantInputCell } from "../../Cells/EquityGrantInputCell";
import { CompCycleLineItemRowButton } from "../CompCycleLineItemRowButton";
import { useRecItemRowStyles } from "./styles";
import { CompCycleLineItemRowProps } from "./types";

interface Props extends CompCycleLineItemRowProps {
  currency: CurrencyCode | null;
  valuation: EquityGrantRow_valuation;
  employee: EquityGrantRow_employee;
}

export function EquityGrantRow({
  employee,
  submittedRecommendation,
  revisedRecommendation,
  onChangeRecommendationItem,
  valuation,
  currency,
}: Props): JSX.Element | null {
  const { Track } = useTrack({
    compensationComponent: CompComponent.EQUITY,
    employeeId: employee.id,
  });
  const { compStructure } = useCompStructure();
  const classes = useRecItemRowStyles();
  const { currencies, defaultCurrency } = useCurrencies();
  const fallbackCurrency =
    currencies.get(valuation.valuation.currency) ?? defaultCurrency;
  const employeeCurrency =
    currency != null
      ? currencies.get(currency) ?? fallbackCurrency
      : fallbackCurrency;

  const submittedValue = submittedRecommendation?.items.get(
    RecItemType.EQUITY_GRANT
  );

  const equityGrantItem = revisedRecommendation?.items.get(
    RecItemType.EQUITY_GRANT
  );
  const sharePrice = preferredPrice(valuation.fdso, valuation.valuation);

  return (
    <Track>
      <TableRow aria-level={3} className={classes.root}>
        <TableCell role="gridcell" padding="none" />
        <TableCell
          role="gridcell"
          colSpan={5}
          padding="none"
          className={classes.textCell}
        >
          <Typography className={classes.rowLabel}>
            {"New Equity Grant "}
            {compStructure?.equityGrantMethod !== EquityGrantMethod.CASH && (
              <Typography className={classes.extraRowText} component="span">
                {`(Current unit price = ${formatCurrency(sharePrice, {
                  maximumFractionDigits: 2,
                })})`}
              </Typography>
            )}
            <span>
              <div id="candu-bar-two" className={classes.canduBar}></div>
            </span>
          </Typography>
        </TableCell>
        <EquityGrantInputCell
          equityGrant={
            equityGrantItem?.recommendedCashValue ?? zero(defaultCurrency.code)
          }
          hasUnpublishedChanges={hasUnpublishedChanges(
            submittedValue,
            equityGrantItem
          )}
          sharePrice={sharePrice}
          localCurrency={employeeCurrency}
          onChange={(newGrant) => {
            onChangeRecommendationItem({
              recommendationType: RecItemType.EQUITY_GRANT,
              recommendedCashValue: newGrant,
              unitType: CompUnit.CASH,
              note: equityGrantItem?.note,
            });
          }}
        />
        <TableCell role="gridcell" className={classes.textCell}>
          <EquityRefreshCondensedSlider
            value={
              equityGrantItem?.recommendedCashValue ??
              zero(employeeCurrency.code)
            }
            bands={employee.adjustedEquityBands}
            position={employee.activeEmployment?.position}
          />
        </TableCell>
        <TableCell role="gridcell" className={classes.textCell} />
        <TableCell role="gridcell" className={classes.noteTextCell} colSpan={1}>
          <CompCycleLineItemRowButton
            disabled={
              equityGrantItem?.recommendedCashValue === undefined &&
              equityGrantItem?.recommendedPercentValue === undefined
            }
            employee={employee}
            note={equityGrantItem?.note ?? null}
            onClick={(note: string) =>
              onChangeRecommendationItem({
                ...equityGrantItem,
                recommendationType: RecItemType.EQUITY_GRANT,
                note: note,
              })
            }
          />
        </TableCell>
      </TableRow>
    </Track>
  );
}

EquityGrantRow.fragments = {
  employee: gql`
    ${CompCycleLineItemRowButton.fragments.employee}
    ${EquityRefreshCondensedSlider.fragments.equityBand}
    ${EquityRefreshCondensedSlider.fragments.position}
    fragment EquityGrantRow_employee on Employee2 {
      id
      adjustedEquityBands {
        ...EquityRefreshCondensedSlider_equityBand
      }
      activeEmployment {
        id
        position {
          ...EquityRefreshCondensedSlider_position
        }
      }
      ...CompCycleLineItemRowButton_employee
    }
  `,
  valuation: gql`
    fragment EquityGrantRow_valuation on Valuation {
      id
      valuation
      fdso
    }
  `,
};
