import { makeStyles, Tab, Tabs } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { LinkTab } from "src/components/Layout/LinkTab";
import { PageHeader, PageTitle } from "src/components/Layout/PageHeader";
import { GRAY_1, GRAY_4 } from "src/theme";
import { capitalize } from "src/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(1.5),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
  },
  tabBar: {
    display: "flex",
    flexGrow: 1,
    minHeight: theme.spacing(4.4),
  },
  tabsContainer: {
    display: "flex",
    gap: theme.spacing(3),
  },
  tab: {
    color: GRAY_4,
    textDecoration: "none",

    "&:hover": {
      color: GRAY_1,
      opacity: 1,
    },
  },
  activeTab: {
    color: GRAY_1,
  },
}));

export enum GuidanceAndBudgetTabs {
  Merit = "merit",
  Bonus = "bonus",
}

type Props = { compCycleId: number };

export function Header({ compCycleId }: Props): JSX.Element {
  const classes = useStyles();
  const location = useLocation();
  const segments = location.pathname.split("/");
  const tab = segments[segments.length - 1] as GuidanceAndBudgetTabs;

  function buildTabProps(tab: GuidanceAndBudgetTabs) {
    return {
      value: tab,
      component: LinkTab,
      title: capitalize(tab),
      classes: {
        selected: classes.activeTab,
        textColorInherit: classes.tab,
      },
      route: {
        pathname: `/comp-cycles/${compCycleId}/settings/guidance-and-budget/${tab}`,
      },
    };
  }

  return (
    <PageHeader>
      <div className={classes.root}>
        <PageTitle>Guidance & Budget</PageTitle>
        <Tabs
          value={tab}
          className={classes.tabBar}
          classes={{ flexContainer: classes.tabsContainer }}
        >
          {Object.values(GuidanceAndBudgetTabs).map((tab) => (
            <Tab {...buildTabProps(tab)} key={tab} />
          ))}
        </Tabs>
      </div>
    </PageHeader>
  );
}
