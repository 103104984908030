import { FetchResult, gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import {
  UpdateBonusGuidanceSettings,
  UpdateBonusGuidanceSettingsInput,
} from "src/__generated__/graphql";

const UPDATE_BONUS_GUIDANCE_SETTINGS = gql`
  mutation UpdateBonusGuidanceSettings(
    $data: UpdateBonusGuidanceSettingsInput!
  ) {
    updateBonusGuidanceSettings(data: $data) {
      id
      compCycleId
      organizationId
      matrixId
      isDimensionEnabled
      customDimensionName
      customDimensionValueType
    }
  }
`;

export function useUpdateBonusGuidanceSettings(): (
  data: UpdateBonusGuidanceSettingsInput
) => Promise<FetchResult<UpdateBonusGuidanceSettings>> {
  const [updateBonusGuidanceSettings] = useMutation(
    UPDATE_BONUS_GUIDANCE_SETTINGS
  );

  return useCallback(
    (data) => updateBonusGuidanceSettings({ variables: { data } }),
    [updateBonusGuidanceSettings]
  );
}
