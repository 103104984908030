import { Route, Routes } from "react-router-dom";
import { NoRouteFound } from "src/components/EmptyState/NoRouteFound";
import { MultipleMeritMatricesLoadingBoundary } from "../MultipleMeritMatrices/MultipleMeritMatricesLoadingBoundary";
import { BonusGuidanceAndBudgetLoadingBoundary } from "./BonusGuidanceAndBudget/BonusGuidanceAndBudgetLoadingBoundary";
import { GuidanceAndBudget } from "./GuidanceAndBudget";

type Props = { compCycleId: number };

export function GuidanceAndBudgetRouter({ compCycleId }: Props): JSX.Element {
  return (
    <Routes>
      <Route
        path="/*"
        element={<GuidanceAndBudget compCycleId={compCycleId} />}
      >
        <Route
          path="merit/*"
          element={
            <MultipleMeritMatricesLoadingBoundary compCycleId={compCycleId} />
          }
        />
        <Route
          path="bonus/*"
          element={
            <BonusGuidanceAndBudgetLoadingBoundary compCycleId={compCycleId} />
          }
        />
        <Route path="*" element={<NoRouteFound />} />
      </Route>
    </Routes>
  );
}
