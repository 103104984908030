import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  MultipleMeritMatrices_compCycle as CompCycle,
  MatrixTypeEnum,
  MultipleMeritMatrices_organization as Organization,
} from "src/__generated__/graphql";
import { AssembleTypography } from "src/components/AssembleTypography";
import { SaveButton } from "src/components/Form/SaveButton";
import UnsavedChangesWarning from "src/components/UnsavedChangesWarning";
import Section from "src/components/Workflow/Section";
import {
  useBuildEmptyMatrix,
  useUpdateMatrixEligibilityRules,
  useUpdateMatrixName,
} from "src/mutations/Matrix";
import { SETTINGS_SIDEBAR_WIDTH } from "src/theme";
import { GuidanceDetailedView } from "./Content/GuidanceDetailedView";
import { ButtonBar } from "./Header/ButtonBar";
import { GuidancePopulationList } from "./Header/GuidancePopulationList";
import { EligiblePeopleModal } from "./Modals/EligiblePeopleModal";
import {
  MeritGuidancePopulation,
  useMultipleMeritMatrices,
} from "./useMultipleMatrices";
import { getUnassignedParticipants } from "./utils";

const useStyles = makeStyles((theme) => ({
  root: { paddingLeft: SETTINGS_SIDEBAR_WIDTH },
  rootOverride: { paddingLeft: theme.spacing(0), paddingTop: theme.spacing(0) },
  description: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    gap: theme.spacing(5),
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
  buttonBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: theme.spacing(4),
  },
  saveButton: {
    width: "max-content",
    height: theme.spacing(5),
  },
}));

type Props = {
  organization: Organization;
  compCycle: CompCycle;
};

export function MultipleMeritMatrices({
  organization,
  compCycle,
}: Props): JSX.Element | null {
  const classes = useStyles();
  const {
    persistChanges,
    matrices,
    matrices: { selectedMatrix },
    allEligible,
    meta: { pageEdited },
  } = useMultipleMeritMatrices();
  const location = useLocation();
  const createEmptyMatrices = useBuildEmptyMatrix(compCycle.id);
  const updateMatrixName = useUpdateMatrixName();
  const updateMatrixEligibilityRules = useUpdateMatrixEligibilityRules();
  const [isEligiblePeopleModalOpen, setIsEligiblePeopleModalOpen] =
    useState(false);
  const [isUnassignedPeopleModalOpen, setIsUnassignedPeopleModalOpen] =
    useState(false);
  const [isEligiblePreSaveModalOpen, setIsEligiblePreSaveModalOpen] =
    useState(false);

  // since we don't run validation on client-side population creation
  // (so we don't show an error banner as soon as the user creates a
  // population) we have this additional check to prevent saving
  // before rules have been added
  const totalAssigned = matrices.meritMatrices.reduce(
    (acc, matrix) =>
      acc + (matrix.eligibleParticipants?.length ?? allEligible.length),
    0
  );

  const hasValidationErrors = matrices.meritMatrices.some(
    (matrix) => matrix.eligibilityValidations.duplicates.length > 0
  );

  const disableSave = hasValidationErrors || totalAssigned > allEligible.length;

  const persist = () => {
    return persistChanges(
      () => createEmptyMatrices([MatrixTypeEnum.BUDGET, MatrixTypeEnum.MERIT]),
      updateMatrixName,
      updateMatrixEligibilityRules
    );
  };

  const handleSave = () => {
    if (totalAssigned < allEligible.length) {
      setIsEligiblePreSaveModalOpen(true);
      return Promise.resolve();
    }

    return persist();
  };

  const generateKey = (matrix: MeritGuidancePopulation) => {
    return `guidance-id-${matrix.guidance.id ?? "guidance-id"}-budget-id-${matrix.budget.id ?? "budget-id"}`;
  };

  if (!selectedMatrix) return null;

  return (
    <div
      className={clsx(classes.root, {
        [classes.rootOverride]: location.pathname.includes(
          "guidance-and-budget/merit"
        ),
      })}
    >
      <UnsavedChangesWarning pageEdited={pageEdited} />
      <EligiblePeopleModal
        type="eligible-pre-save"
        isOpen={isEligiblePreSaveModalOpen}
        compCycle={compCycle}
        handleClose={() => setIsEligiblePreSaveModalOpen(false)}
        employeeIds={allEligible.map((employee) => employee.subjectId)}
        handleSave={async () => {
          await persist();
          setIsEligiblePreSaveModalOpen(false);
        }}
      />

      <EligiblePeopleModal
        type="eligible"
        isOpen={isEligiblePeopleModalOpen}
        compCycle={compCycle}
        handleClose={() => setIsEligiblePeopleModalOpen(false)}
        employeeIds={allEligible.map((employee) => employee.subjectId)}
      />

      <EligiblePeopleModal
        type="unassigned"
        isOpen={isUnassignedPeopleModalOpen}
        compCycle={compCycle}
        handleClose={() => setIsUnassignedPeopleModalOpen(false)}
        employeeIds={getUnassignedParticipants(
          allEligible,
          matrices.meritMatrices
        )}
      />
      <Section
        active
        header={
          location.pathname.includes("guidance-and-budget/merit")
            ? "Merit"
            : "Merit Guidance & Budget"
        }
        description={
          <div className={classes.description}>
            <AssembleTypography variant="productParagraphSmall">
              Customize salary merit and budget guidance in percentages based on
              performance ratings and compa-ratio for different populations in
              your organization.
            </AssembleTypography>
            <ButtonBar
              handleEligiblePeopleModalOpen={() =>
                setIsEligiblePeopleModalOpen(true)
              }
              handleUnassignedPeopleModalOpen={() =>
                setIsUnassignedPeopleModalOpen(true)
              }
            />
          </div>
        }
      >
        <div className={classes.content}>
          <GuidancePopulationList />
          <GuidanceDetailedView
            key={generateKey(selectedMatrix)}
            compCycle={compCycle}
            organization={organization}
          />
          <div className={classes.buttonBar}>
            <div />
            <SaveButton
              className={classes.saveButton}
              hideEndIcon
              disabled={disableSave}
              onSave={handleSave}
            />
          </div>
        </div>
      </Section>
    </div>
  );
}

MultipleMeritMatrices.fragments = {
  organization: gql`
    ${GuidanceDetailedView.fragments.organization}
    fragment MultipleMeritMatrices_organization on Organization {
      id
      ...GuidanceDetailedView_organization
    }
  `,
  compCycle: gql`
    ${ButtonBar.fragments.compCycle}
    ${GuidanceDetailedView.fragments.compCycle}
    ${EligiblePeopleModal.fragments.compCycle}
    fragment MultipleMeritMatrices_compCycle on CompCycle2 {
      id
      ...ButtonBar_compCycle
      ...GuidanceDetailedView_compCycle
      ...EligiblePeopleModal_compCycle
    }
  `,
};
