import { plainDateToUTCString } from "@asmbl/shared/time";
import { isEmptyString } from "@asmbl/shared/utils";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
} from "@material-ui/core";
import clsx from "clsx";
import { memo, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { useTrack } from "src/analytics";
import { usePhaseConfiguration } from "src/components/PhaseConfiguration/usePhaseConfiguration";
import {
  useEmplaceCompCyclePhases,
  useReplacePhaseAssignments,
} from "src/mutations/CompCyclePhase";
import {
  AllocationUnit,
  convertAllocationToCash,
} from "../../../models/Budget";
import {
  useCreateCompCycle,
  useImportEmployeePerfScores,
} from "../../../mutations/CompCycle";
import { useEmplaceCompRecommendationsWithCompCycleId } from "../../../mutations/CompRecommendation";
import { GRAY_6, WHITE } from "../../../theme";
import { withMinWait } from "../../../utils";
import { CompCycleWizardSidebar } from "./CompCycleWizardSidebar";
import CompCycleStep from "./CompCycleWizardStep";
import { CompCycleData, CompCycleDataChangeHandler, Valuation } from "./types";

type Props = {
  valuation: Valuation;
};

type StyleProps = {
  transitioning: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
  },
  content: {
    flex: 1,
    position: "relative",
  },
  noPadding: {
    padding: "0 !important",
  },
  contentPadding: {
    padding: theme.spacing(12, 5),
  },
  sidebar: {
    backgroundColor: WHITE,
    boxShadow: `1px 0px 0px ${GRAY_6}`,
    flexShrink: 0,
    height: "100%",
    left: "81px",
    marginRight: "1px",
    padding: theme.spacing(6, 2),
    position: "fixed",
    width: theme.spacing(31),
    zIndex: 10,
  },
  transition: {
    marginTop: (p: StyleProps) => (p.transitioning ? "40px" : "0px"),
    opacity: (p: StyleProps) => (p.transitioning ? 0 : 1),
    transition: (p: StyleProps) => (p.transitioning ? "none" : "all 350ms"),
  },
  stepName: {
    fontSize: ".875rem",
  },

  dialogPending: {
    paddingBlock: theme.spacing(5),
  },
  dialogPendingTitle: {
    textAlign: "center",
  },
  dialogPendingContent: {
    textAlign: "center",
    marginTop: theme.spacing(1),
  },
  createCycleTextContainer: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  hierarchyView: {
    overflowX: "hidden",
    overflowY: "hidden",
  },
}));

export const CompCycleNew = memo(function GenerateCompCycle({
  valuation,
}: Props) {
  const { trackEvent } = useTrack();
  const intercom = useIntercom();

  const [transitioning, setTransitioning] = useState<boolean>(false);
  const classes = useStyles({ transitioning });
  const navigate = useNavigate();
  const [hierarchyView, setHierarchyView] = useState<boolean>(false);
  const { phaseConfiguration, endDate } = usePhaseConfiguration();

  const [step, setStep] = useState<number>(0);
  const updateStep = useCallback((updatedStep: number) => {
    setTransitioning(true);
    setHierarchyView(false);

    setTimeout(() => {
      window.scrollTo(0, 0);
      setStep(updatedStep);
      setTransitioning(false);
    }, 150);
  }, []);

  const [isSaving, setIsSaving] = useState(false);

  const [wizardData, setWizardData] = useState<CompCycleData>({
    perfData: [],
    phasesData: [],
    requestsAndRecommendations: [],
    compComponents: {
      allowSalary: false,
      allowSalaryMarket: false,
      allowSalaryMerit: false,
      allowSalaryPromotion: false,
      allowEquity: false,
      allowBonus: false,
      allowTargetCommission: false,
      allowTargetRecurringBonus: false,
      allowActualRecurringBonus: false,
    },
    salaryBudget: null,
    equityBudget: { unit: AllocationUnit.CASH, value: null },
    bonusBudget: null,
    targetCommissionBudget: null,
    targetRecurringBonusBudget: null,
    actualRecurringBonusBudget: null,
  });

  const handleChange: CompCycleDataChangeHandler = useCallback((id, value) => {
    setWizardData((prevWizardData) => ({ ...prevWizardData, [id]: value }));
  }, []);

  const createCompCycle = useCreateCompCycle();
  const importEmployeePerfScores = useImportEmployeePerfScores();
  const emplaceCompRecommendations =
    useEmplaceCompRecommendationsWithCompCycleId();
  const emplaceCompCyclePhases = useEmplaceCompCyclePhases();
  const replacePhaseAssignments = useReplacePhaseAssignments();

  const onSubmit = async (finalData: CompCycleData) => {
    if (finalData.name === undefined || isEmptyString(finalData.name)) return;

    setIsSaving(true);

    const compCycleFetch = await createCompCycle({
      name: finalData.name,
      compComponents: finalData.compComponents,
      endDate,
      totalBudget: {
        salary: finalData.salaryBudget,
        equity: convertAllocationToCash(finalData.equityBudget, valuation),
        bonus: finalData.bonusBudget,
        targetCommission: finalData.targetCommissionBudget,
        targetRecurringBonus: finalData.targetRecurringBonusBudget,
        actualRecurringBonus: finalData.actualRecurringBonusBudget,
      },
    });

    const compCycle = compCycleFetch.data;
    const compCycleId = compCycle?.createOneCompCycle.id;

    trackEvent({
      object: "Create Comp Cycle",
      action: "Submitted",
      compCycleId,
    });

    intercom.trackEvent("Comp Cycle Created", {
      compCycleId,
    });

    const hasPerfData = finalData.perfData.length > 0;
    const hasRequestsAndRecommendationsData =
      finalData.requestsAndRecommendations.length > 0;
    const hasPhasesData =
      phaseConfiguration.length > 0 &&
      phaseConfiguration.every((p) => p.startDate != null);

    if (hasPerfData && compCycleId != null) {
      await withMinWait(
        () =>
          importEmployeePerfScores({
            compCycleId,
            employeePerfScores: finalData.perfData.map((row) => ({
              email: row.email,
              perfRating: row.perfRating,
              note: row.note,
            })),
          }),
        2_000
      );

      trackEvent({
        object: "Employee Performance Ratings",
        action: "Submitted",
        compCycleId,
      });
      intercom.trackEvent("Employee Performance Ratings Uploaded", {
        compCycleId,
        type: "Comp Cycle Creation",
      });
    }

    if (hasRequestsAndRecommendationsData && compCycleId != null) {
      await withMinWait(
        () =>
          emplaceCompRecommendations(
            compCycleId,
            finalData.requestsAndRecommendations
          ),
        2_000
      );

      trackEvent({
        object: "Comp Recommendations Upload",
        action: "Submitted",
        compCycleId,
      });
      intercom.trackEvent("Comp Recommendations Uploaded", {
        compCycleId,
        type: "Comp Cycle Creation",
      });
    }

    if (hasPhasesData && compCycleId != null) {
      await withMinWait(async () => {
        const result = await emplaceCompCyclePhases(
          compCycleId,
          phaseConfiguration.map((phase) => ({
            phaseOrder: phase.phaseOrder,
            startDate: plainDateToUTCString(phase.startDate!),
          }))
        );

        await Promise.all(
          result.map(async (point) => {
            // given the IDs of the phases that were created,
            // we need to make sure that the phase assignments
            // assignees are mapped to the correct assignment:
            // we do this by matching the phase order
            const assigneeIds = phaseConfiguration.find(
              ({ phaseOrder }) => point.phaseOrder === phaseOrder
            )?.assigneeIds;

            const phaseAssignmentData = {
              compCycleId: compCycleId,
              phaseId: point.id,
              assigneeIds: assigneeIds ?? [],
            };

            await replacePhaseAssignments(
              phaseAssignmentData.compCycleId,
              phaseAssignmentData.phaseId,
              phaseAssignmentData.assigneeIds
            );
          })
        );

        return result;
      }, 2_000);

      trackEvent({
        object: "Comp Cycle Phases",
        action: "Submitted",
        compCycleId,
      });
      intercom.trackEvent("Comp Cycle Phases Created", {
        compCycleId,
        type: "Comp Cycle Creation",
      });
    }

    compCycleId != null
      ? navigate(`/comp-cycles/${compCycleId}`)
      : navigate("/comp-cycles");
  };

  return (
    <div className={classes.container}>
      {isSaving && wizardData.perfData.length === 0 && (
        <Dialog open maxWidth="xs" classes={{ paper: classes.dialogPending }}>
          <DialogTitle className={classes.dialogPendingTitle}>
            Creating comp cycle...
          </DialogTitle>
          <DialogContent className={classes.dialogPendingContent}>
            <CircularProgress />
          </DialogContent>
        </Dialog>
      )}

      {/* if we are in the hierarchy view, then we need a different sidebar */}
      <CompCycleWizardSidebar step={step} updateStep={updateStep} />

      <div
        className={clsx(classes.content, {
          [classes.contentPadding]: !hierarchyView,
          [classes.noPadding]: hierarchyView,
        })}
      >
        <div className={classes.transition}>
          <CompCycleStep
            step={step}
            data={wizardData}
            valuation={valuation}
            handleChange={handleChange}
            updateStep={updateStep}
            onSubmit={onSubmit}
            hierarchyView={hierarchyView}
            setHierarchyView={setHierarchyView}
          />
        </div>
      </div>
    </div>
  );
});
