import { gql } from "@apollo/client";
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { useState } from "react";
import { NotesEmptyIcon } from "src/components/AssembleIcons/Small/NotesEmpty";
import { NotesIcon } from "src/components/AssembleIcons/Small/NotesIcon";
import { GRAY_4, PURPLE_2 } from "src/theme";
import { CompCycleLineItemRowButton_employee } from "../../../__generated__/graphql";
import { useTrack } from "../../../analytics";
import { CompCycleManagerItemNoteContent } from "../../CompNoteModal/CompCycleManagerItemNoteContent";
import { CompNoteModal2 } from "../../CompNoteModal/CompNoteModal2";

const useStyles = makeStyles(() => ({
  noteButton: {
    padding: "0px",
  },
}));

export function CompCycleLineItemRowButton({
  employee,
  title = "Why are you giving this adjustment?",
  note,
  onClick,
  disabled = false,
}: {
  employee: CompCycleLineItemRowButton_employee;
  note: string | null;
  onClick: (newNote: string) => unknown;
  title?: string;
  disabled?: boolean;
}): JSX.Element {
  const { trackEvent } = useTrack();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [iconColor, setIconColor] = useState(GRAY_4);

  const onOpen = () => {
    setOpen(true);
    trackEvent({
      object: "Change Request Notes",
      action: "Clicked",
      employeeId: employee.id,
    });
  };
  return (
    <Tooltip
      // tooltip is disabled when the input isn't
      disableHoverListener={!disabled}
      disableFocusListener={!disabled}
      title="This field is disabled or you need to enter a value before leaving a note."
    >
      <span
        onFocus={() => setIconColor(PURPLE_2)}
        onBlur={() => setIconColor(GRAY_4)}
      >
        {open && (
          <CompNoteModal2 employee={employee} onClose={() => setOpen(false)}>
            <CompCycleManagerItemNoteContent
              title={title}
              note={note}
              onSave={(note: string) => {
                onClick(note);
                setOpen(false);
              }}
            />
          </CompNoteModal2>
        )}
        <IconButton
          size="small"
          disabled={disabled}
          onClick={onOpen}
          className={classes.noteButton}
          data-cy="note-icon-button"
        >
          {note !== null && note.length > 0 ? (
            <NotesIcon color={iconColor} hoverColor={PURPLE_2} />
          ) : (
            <NotesEmptyIcon color={iconColor} hoverColor={PURPLE_2} />
          )}
        </IconButton>
      </span>
    </Tooltip>
  );
}

CompCycleLineItemRowButton.fragments = {
  employee: gql`
    ${CompNoteModal2.fragments.employee}
    fragment CompCycleLineItemRowButton_employee on Employee2 {
      id
      ...CompNoteModal2_employee
    }
  `,
};
