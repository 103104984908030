import { gql } from "@apollo/client";
import { Card, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import { MatrixCard_employee as Employee } from "src/__generated__/graphql";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { ChevronDownIcon } from "src/components/AssembleIcons/Brand/ChevronDownIcon";
import { ChevronRightIcon } from "src/components/AssembleIcons/Brand/ChevronRightIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import { GRAY_4, GRAY_6 } from "src/theme";
import { MatrixColumn } from "./MatrixColumn";
import { MatrixTable } from "./MatrixTable";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),

    boxShadow: "none",
    border: `1px solid ${GRAY_6}`,

    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  headerGroup: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cardHeaderGroupInfo: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(0.5),
  },
  cardHeaderGroupAction: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(1.5),
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
  },
  button: {
    "&:hover": {
      border: "1px solid transparent",
      boxShadow: "none",
    },
  },
  pointer: {
    cursor: "pointer",
  },
}));

type Props = {
  label: string;
  defaultOpen?: boolean;
  employees: Employee[];
};

export function MatrixCard({ label, defaultOpen = true, employees }: Props) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);

  return (
    <Card
      className={clsx(classes.card, { [classes.pointer]: !isOpen })}
      // only want the whole card clickable if it's closed
      onClick={() => {
        if (!isOpen) {
          setIsOpen(true);
        }
      }}
    >
      <div className={classes.cardHeader}>
        <div className={classes.cardHeaderGroupInfo}>
          <AssembleButton
            className={classes.button}
            size="small"
            variant="text"
            startIcon={
              isOpen ? (
                <ChevronDownIcon color={GRAY_4} />
              ) : (
                <ChevronRightIcon color={GRAY_4} />
              )
            }
            onClick={() => setIsOpen(!isOpen)}
          />
          <div className={classes.headerGroup}>
            <AssembleTypography variant="productSectionHeader">
              {label}
            </AssembleTypography>
          </div>
        </div>
      </div>
      {isOpen ? <MatrixTable employees={employees} /> : null}
    </Card>
  );
}

MatrixCard.fragments = {
  matrix: gql`
    ${MatrixColumn.fragments.matrixGuide}
    fragment MatrixCard_matrix on Matrix {
      id
      name
      eligibilityRules
      eligibleParticipants
      type
      siblingMatrices {
        id
        type
      }
      matrixGuides {
        id
        ...MatrixColumn_matrixGuide
      }
    }
  `,
  compCycle: gql`
    ${MatrixTable.fragments.compCycle}
    fragment MatrixCard_compCycle on CompCycle2 {
      id
      ...MatrixTable_compCycle
    }
  `,
  employee: gql`
    ${MatrixTable.fragments.employee}
    fragment MatrixCard_employee on Employee {
      id
      ...MatrixTable_employee
    }
  `,
};
