import { gql } from "@apollo/client";
import { IconButton, Link, makeStyles } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { CheckCircleSolidIcon } from "src/components/AssembleIcons/Brand/CheckCircleSolidIcon";
import { useAuth } from "src/components/Auth/AuthContext";
import { UserAvatar } from "src/components/UserAvatar";
import { GRAY_6, PURPLE_1 } from "src/theme";
import { CondensedTableNameCell2_participant as Participant } from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { useURLSearchParams } from "../../../../models/URLSearchParams";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { useBulkActionsData } from "../Contexts/BulkActionsContext";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { useTableData } from "../Contexts/TableDataContext2";
import { XLG_COL_WIDTH } from "./dimensions";

const useStyles = makeStyles((theme) => ({
  employeeNameCell: {
    display: "flex",
    alignItems: "center",
    gap: 7,
  },
  nameText: {
    paddingLeft: theme.spacing(0),
    color: "inherit",
  },
  iconButton: {
    cursor: "pointer",
    padding: 0,
    width: "24px",
    height: "24px",
  },
  selectedIcon: {
    border: `1px solid ${GRAY_6}`,
    borderRadius: "50%",
  },
}));

type Props = {
  row: { id: string; original: Participant };
};

export function CondensedTableNameCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { selectedParticipants } = useBulkActionsData();
  const { isViewOnly } = useTableData();
  const classes = useStyles();
  const urlSearchParams = useURLSearchParams();
  const { userId } = useAuth();

  const isSelected: boolean =
    selectedParticipants.get(employee.subject.id)?.isSelected ?? false;
  const currUserIsAuthor = employee.lastActivity?.author.id === userId;

  return (
    <div className={classes.employeeNameCell}>
      <IconButton
        disabled={isViewOnly}
        className={classes.iconButton}
        id="name-icon"
      >
        {isSelected ? (
          <CheckCircleSolidIcon height="24" width="24" color={PURPLE_1} />
        ) : (
          <UserAvatar
            photoURL={employee.subject.photoURL}
            displayName={employee.subject.displayName}
            size="mini"
            grayscale={currUserIsAuthor}
            id="user-avatar"
          />
        )}
      </IconButton>
      <AssembleTruncatedTypography
        lines={2}
        title={employee.subject.displayName}
      >
        <Link
          component={RouterLink}
          className={classes.nameText}
          to={`?${urlSearchParams
            .set("employee", employee.subject.id.toString())
            .toString()}`}
          underline="none"
          onClick={(e) => e.stopPropagation()}
        >
          {employee.subject.displayName}
        </Link>
      </AssembleTruncatedTypography>
    </div>
  );
}

CondensedTableNameCell2.fragments = {
  participant: gql`
    fragment CondensedTableNameCell2_participant on CompCycleParticipant {
      subject {
        id
        displayName
        userId
        photoURL
      }
      lastActivity {
        submittedAt
        type
        author {
          id
        }
      }
    }
  `,
};

CondensedTableNameCell2.Header = ColumnIdsToHeaders.get(ColumnIds.NAME);
CondensedTableNameCell2.id = ColumnIds.NAME;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableNameCell2,
  Header: CondensedTableNameCell2.Header,
  id: CondensedTableNameCell2.id,
  width: XLG_COL_WIDTH,
};
CondensedTableNameCell2.column = column;
