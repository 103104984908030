import { getSalaryCashComp } from "@asmbl/shared/compensation";
import { multiply } from "@asmbl/shared/money";
import { caseInsensitiveComparator } from "@asmbl/shared/sort";
import { mapMaybe } from "@asmbl/shared/utils";
import uniqBy from "lodash/uniqBy";
import {
  CashCompType,
  CompUnit,
  CondensedTable_matrix,
  CurrencyCode,
  CustomDimensionValueType,
  CondensedTable_matrix as Matrix,
  MatrixTypeEnum,
  BulkActionsBar_participant as Participant,
  RecItemInput,
  RecItemType,
} from "src/__generated__/graphql";
import { getCompaRatioNew } from "./Employee";

// Determine which merit guidance option should be returned for a given employee
export const getMeritGuidance = (
  employee: {
    activeEmployment: {
      id: number;
      positionId: number | null;
    } | null;
    adjustedCashBands:
      | {
          id: string;
          name: string;
          bandPoints: {
            __typename: string;
            id: string;
            bandName: string;
            name: string;
            value: {
              __typename: "CashValue";
              annualRate: GraphQL_Money | null;
              hourlyRate: GraphQL_Money | null;
              currencyCode: CurrencyCode;
            };
          }[];
        }[]
      | null;
    activeCashCompensation:
      | {
          __typename: "CashCompensation2";
          type: CashCompType;
          annualCashEquivalent: GraphQL_Money;
          unit: CompUnit;
          hourlyCashEquivalent: GraphQL_Money;
        }[]
      | null;
    perfRating: string | null;
  },
  matrixGuides: {
    perfRatingOptionId: number;
    matrixRangeId: number;
    percent: number | null;
  }[],
  matrixRanges: {
    rangeStart: number | null;
    id: number;
  }[],
  perfRatingOptions: {
    name: string;
    id: number;
  }[]
): number | { message: string } => {
  // If all merit guidance options are 0, then guidance hasn't been set
  if (matrixGuides.every((guide) => guide.percent === 0)) {
    return { message: "Guidance not configured" };
  }

  // Find the employee's comparatio, since guidance is based on this
  const compaRatio = getCompaRatioNew(
    employee.activeCashCompensation,
    employee.adjustedCashBands
  );

  if (compaRatio == null && employee.activeEmployment?.positionId == null)
    return {
      message: "Employee is unleveled",
    };

  if (compaRatio == null)
    return { message: "Employee's position has no bands defined" };

  const sorted: {
    rangeStart: number;
    id: number;
  }[] = mapMaybe(matrixRanges, (r) =>
    r.rangeStart !== null ? { rangeStart: r.rangeStart, id: r.id } : null
  ).sort((a, b) => a.rangeStart - b.rangeStart);

  // Find which range the employee's comparatio puts them in
  const matrixRange = sorted.find((range, i) => {
    if (i === 0 && range.rangeStart > compaRatio) {
      return true;
    }
    if (i === sorted.length - 1) {
      return true;
    }
    if (
      range.rangeStart <= compaRatio &&
      sorted[i + 1].rangeStart > compaRatio
    ) {
      return true;
    }
  });

  if (!matrixRange) return { message: "Employee compa ratio out of range" };

  // Find which perf rating the employee has been given
  // If there is only one without a name, it applies to all employees
  const perfRating =
    perfRatingOptions.length === 1 && perfRatingOptions[0].name === ""
      ? perfRatingOptions[0]
      : perfRatingOptions.find((rating) => rating.name === employee.perfRating);

  if (!perfRating)
    return {
      message:
        "Performance rating doesn't match any options defined in the matrix",
    };

  // Find the correct guidance based on a combination of their adjustment
  // range and perf rating
  const guide = matrixGuides.find(
    (guide) =>
      guide.perfRatingOptionId === perfRating.id &&
      guide.matrixRangeId === matrixRange.id
  );

  return guide?.percent ?? { message: "Guidance not found" };
};

export type BonusGuidanceOption =
  | {
      pay: number;
      percent: number;
      weighting: number;
      matrix: CondensedTable_matrix | null;
    }
  | {
      message: string;
      matrix: CondensedTable_matrix | null;
    };

export type BonusGuidanceResponse = {
  [MatrixTypeEnum.BONUS_GUIDANCE_COMPANY_PERFORMANCE]: BonusGuidanceOption;
  [MatrixTypeEnum.BONUS_GUIDANCE_INDIVIDUAL_PERFORMANCE]: BonusGuidanceOption;
  [MatrixTypeEnum.BONUS_GUIDANCE_CUSTOM_PERFORMANCE]: BonusGuidanceOption;
};

/**
 * Retrieves bonus guidance for an employee
 *
 * @param participant - The employee object containing performance ratings.
 * @param matrixMap - A map of bonus matrix types and their matrix data.
 * @returns An object containing bonus guidance or message
 * for company performance, individual performance, and custom performance.
 */
export function getBonusGuidance(
  participant: {
    subjectId: number;
    bonusIndividualPerfRating: string | null;
    bonusCustomPerfRating: string | null;
    subject: {
      activeCashCompensation:
        | {
            type: CashCompType;
            annualCashEquivalent: GraphQL_Money;
          }[]
        | null;
    };
  },
  matrixMap: Map<MatrixTypeEnum, CondensedTable_matrix[]>,
  orgPerfRating: number
): BonusGuidanceResponse {
  const targetCashComp = participant.subject.activeCashCompensation?.find(
    (c) => c.type === CashCompType.RECURRING_BONUS
  );
  const result: BonusGuidanceResponse = {
    [MatrixTypeEnum.BONUS_GUIDANCE_COMPANY_PERFORMANCE]: {
      message: "Guidance not configured",
      matrix: null,
    },
    [MatrixTypeEnum.BONUS_GUIDANCE_INDIVIDUAL_PERFORMANCE]: {
      message: "Guidance not configured",
      matrix: null,
    },
    [MatrixTypeEnum.BONUS_GUIDANCE_CUSTOM_PERFORMANCE]: {
      message: "Guidance not configured",
      matrix: null,
    },
  };

  const empGuidance = getEmployeeBonusMatrices(
    matrixMap,
    participant.subjectId
  );
  if (
    Object.values(empGuidance).every(
      (matrix) =>
        !matrix?.bonusGuidanceSettings ||
        matrix.bonusGuidanceSettings.isDimensionEnabled === false ||
        // If all bonus guidance rangeStart === 0, then guidance hasn't been set since this represents the bonus guidance's weighting
        matrix.matrixGuides.every((guide) => guide.matrixRange.rangeStart === 0)
    )
  ) {
    return result;
  }

  const companyMatrix =
    empGuidance[MatrixTypeEnum.BONUS_GUIDANCE_COMPANY_PERFORMANCE];

  // company bonus guidance will only have one guide
  const compMatrixGuide = companyMatrix?.matrixGuides.at(0);

  if (
    companyMatrix?.bonusGuidanceSettings?.isDimensionEnabled === true &&
    compMatrixGuide !== undefined
  ) {
    const percent = compMatrixGuide.percent ?? 0;
    const weighting = compMatrixGuide.matrixRange.rangeStart ?? 0;
    result[MatrixTypeEnum.BONUS_GUIDANCE_COMPANY_PERFORMANCE] = {
      percent,
      weighting,
      matrix: companyMatrix,
      pay: targetCashComp
        ? multiply(
            targetCashComp.annualCashEquivalent,
            // weighting & orgPerfRating are 0-100 format
            (weighting * orgPerfRating) / 10_000
          ).value
        : 0,
    };
  }

  // evaluate bonus individual performance
  const indMatrix =
    empGuidance[MatrixTypeEnum.BONUS_GUIDANCE_INDIVIDUAL_PERFORMANCE];
  const empIndRating = participant.bonusIndividualPerfRating;
  const indMatrixGuide =
    empIndRating !== null
      ? indMatrix?.matrixGuides.find(
          (guide) =>
            caseInsensitiveComparator(
              guide.matrixPerfRatingOption.name,
              empIndRating
            ) === 0
        )
      : undefined;

  if (!indMatrixGuide) {
    result[MatrixTypeEnum.BONUS_GUIDANCE_INDIVIDUAL_PERFORMANCE] = {
      message:
        "Performance rating doesn't match any options defined in the matrix",
      matrix: indMatrix ?? null,
    };
  } else if (
    indMatrixGuide.matrixRange.rangeStart &&
    indMatrixGuide.matrixRange.rangeStart > 0 &&
    indMatrix?.bonusGuidanceSettings?.isDimensionEnabled === true
  ) {
    const percent = indMatrixGuide.percent ?? 0;
    const weighting = indMatrixGuide.matrixRange.rangeStart ?? 0;
    result[MatrixTypeEnum.BONUS_GUIDANCE_INDIVIDUAL_PERFORMANCE] = {
      percent,
      weighting,
      matrix: indMatrix,
      pay: targetCashComp
        ? multiply(
            targetCashComp.annualCashEquivalent,
            // weighting is 0-100 format, percent is 0-1 format
            (weighting * percent) / 100
          ).value
        : 0,
    };
  }

  // evaluate bonus custom performance
  const customMatrix =
    empGuidance[MatrixTypeEnum.BONUS_GUIDANCE_CUSTOM_PERFORMANCE];
  const guide = customMatrix?.matrixGuides.at(0);

  if (
    !customMatrix ||
    !guide ||
    guide.matrixRange.rangeStart === 0 ||
    customMatrix.bonusGuidanceSettings?.isDimensionEnabled === false
  ) {
    return result;
  }

  if (
    customMatrix.bonusGuidanceSettings?.customDimensionValueType ===
    CustomDimensionValueType.SINGLE_SCORE
  ) {
    const percent = guide.percent ?? 0;
    const weighting = guide.matrixRange.rangeStart ?? 0;
    result[MatrixTypeEnum.BONUS_GUIDANCE_CUSTOM_PERFORMANCE] = {
      percent,
      weighting,
      matrix: customMatrix,
      pay: targetCashComp
        ? multiply(
            targetCashComp.annualCashEquivalent,
            // weighting is 0-100 format, percent is 0-1 format
            (weighting * percent) / 100
          ).value
        : 0,
    };
  } else if (customMatrix.matrixGuides.length > 1) {
    const empCustomRating = participant.bonusCustomPerfRating;
    const customMatrixGuide =
      empCustomRating !== null
        ? customMatrix.matrixGuides.find(
            (guide) =>
              caseInsensitiveComparator(
                guide.matrixPerfRatingOption.name,
                empCustomRating
              ) === 0
          )
        : undefined;

    if (!customMatrixGuide) {
      result[MatrixTypeEnum.BONUS_GUIDANCE_CUSTOM_PERFORMANCE] = {
        message:
          "Performance rating doesn't match any options defined in the matrix",
        matrix: customMatrix,
      };
    } else {
      const percent = customMatrixGuide.percent ?? 0;
      const weighting = customMatrixGuide.matrixRange.rangeStart ?? 0;
      result[MatrixTypeEnum.BONUS_GUIDANCE_CUSTOM_PERFORMANCE] = {
        percent,
        weighting,
        matrix: customMatrix,
        pay: targetCashComp
          ? multiply(
              targetCashComp.annualCashEquivalent,
              // weighting is 0-100 format, percent is 0-1 format
              (weighting * percent) / 100
            ).value
          : 0,
      };
    }
  }

  return result;
}

export function generateMeritGuidanceItem(
  participant: Participant,
  matrices: Matrix[]
) {
  const { compRecommendation } = participant;

  const basePay = getSalaryCashComp(participant.subject.activeCashCompensation);

  if (basePay == null) return;

  const meritMatrices = matrices.filter(
    (matrix) => matrix.type === MatrixTypeEnum.MERIT
  );

  const meritMatrix =
    meritMatrices.length > 1
      ? meritMatrices.find((matrix) => {
          return (matrix.eligibleParticipants as number[]).includes(
            participant.subjectId
          );
        })
      : meritMatrices[0];

  const matrixGuides =
    meritMatrix?.matrixGuides.flatMap((matrixGuide) => ({
      ...matrixGuide,
      perfRatingOptionId: matrixGuide.matrixPerfRatingOption.id,
      matrixRangeId: matrixGuide.matrixRange.id,
      percent: matrixGuide.percent ?? 0,
    })) ?? [];

  const perfRatingOptions = uniqBy(
    matrixGuides.flatMap((matrixGuide) => matrixGuide.matrixPerfRatingOption),
    "name"
  );

  const matrixRanges = uniqBy(
    matrixGuides.flatMap((matrixGuide) => matrixGuide.matrixRange),
    "rangeStart"
  );

  const guidanceValue = getMeritGuidance(
    {
      ...participant,
      ...participant.subject,
    },
    matrixGuides,
    matrixRanges,
    perfRatingOptions
  );

  const guidance = typeof guidanceValue === "number" ? guidanceValue : null;

  if (guidance == null) return;

  const guidanceItem = compRecommendation?.latestSubmittedItems.find(
    (item) => item.recommendationType === RecItemType.MERIT_INCREASE
  );

  // guidance already auto-applied, do not apply again
  if (guidanceItem && guidanceItem.recommendedPercentValue === guidance * 100)
    return;

  const latestItems = compRecommendation
    ? compRecommendation.latestSubmittedItems.filter(
        (item) => item.recommendationType !== RecItemType.MERIT_INCREASE
      )
    : [];

  const newItems: RecItemInput[] = [
    ...latestItems.map((item) => ({
      recommendationType: item.recommendationType,
      note: item.note,
      recommendedPercentValue: item.recommendedPercentValue,
      recommendedCashValue: item.recommendedCashValue,
      recommendedTitle: item.recommendedTitle,
      recommendedPositionId: item.recommendedPosition?.id,
      unitType: item.unitType,
    })),
    {
      recommendationType: RecItemType.MERIT_INCREASE,
      note: "Applied from guidance suggestion",
      recommendedPercentValue: guidance * 100,
      unitType: CompUnit.PERCENT_OF_SALARY,
    },
  ];

  return {
    items: newItems,
    subjectId: participant.subjectId,
  };
}

export function findEligibleMatrix<
  T extends { eligibleParticipants: number[] | null },
>(matrices: T[], employeeId: number) {
  return matrices.length > 1
    ? matrices.find((matrix) => {
        return matrix.eligibleParticipants?.includes(employeeId);
      })
    : matrices.at(0);
}

function getEmployeeBonusMatrices<
  T extends { eligibleParticipants: number[] | null },
>(matrixMap: Map<MatrixTypeEnum, T[]>, employeeId: number) {
  const compPerf = matrixMap.get(
    MatrixTypeEnum.BONUS_GUIDANCE_COMPANY_PERFORMANCE
  );
  const indPerf = matrixMap.get(
    MatrixTypeEnum.BONUS_GUIDANCE_INDIVIDUAL_PERFORMANCE
  );
  const customPerf = matrixMap.get(
    MatrixTypeEnum.BONUS_GUIDANCE_CUSTOM_PERFORMANCE
  );
  const empsCompPerf = compPerf
    ? findEligibleMatrix(compPerf, employeeId)
    : undefined;

  const empIndPerf = indPerf
    ? findEligibleMatrix(indPerf, employeeId)
    : undefined;
  const empCustomPerf = customPerf
    ? findEligibleMatrix(customPerf, employeeId)
    : undefined;
  return {
    [MatrixTypeEnum.BONUS_GUIDANCE_COMPANY_PERFORMANCE]: empsCompPerf,
    [MatrixTypeEnum.BONUS_GUIDANCE_INDIVIDUAL_PERFORMANCE]: empIndPerf,
    [MatrixTypeEnum.BONUS_GUIDANCE_CUSTOM_PERFORMANCE]: empCustomPerf,
  };
}
