/* eslint-disable @typescript-eslint/no-base-to-string */
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { isEmptyString } from "@asmbl/shared/utils";
import { ISettings } from "@flatfile/adapter/build/main/interfaces";
import {
  ScalarDictionaryWithCustom as FlatFileRecord,
  IDataHookResponse,
} from "@flatfile/react";
import { Button } from "@material-ui/core";
import { AssembleFlatfileButton } from "src/components/AssembleFlatfileButton";
import { useCompStructure } from "src/components/CompStructureContext";
import { useFeatureFlags } from "src/components/FeatureContext";
import { EmploymentStatus } from "../../../../__generated__/graphql";
import { DATE_REGEX } from "../../../../constants";

enum EmploymentType {
  CONTRACTOR,
  FREELANCE,
  FULL_TIME,
  INTERN,
  PART_TIME,
}

export type FlatfileRecord = {
  employeeNumber: string;
  workEmail: string;
  personalEmail: string | null;
  displayName: string;
  employmentStatus: string;
  managerWorkEmail: string;
  city: string;
  state: string | null;
  country: string;
  firstName: string | null;
  lastName: string | null;
  gender: string | null;
  ethnicity: string | null;
  startDate: string | null;
  terminationDate: string | null;
  payRate: string | null;
  hourlyPayRate: string | null;
  payPeriod: string;
  payCurrency: string;
  employmentType: string;
  jobTitle: string;
  effectiveDate: string;
};
export type RipplingImportRecord = {
  employeeNumber: string;
  workEmail: string;
  personalEmail: string | null;
  displayName: string;
  employmentStatus: string;
  managerWorkEmail: string;
  city: string;
  state: string | null;
  country: string;
  firstName: string | null;
  lastName: string | null;
  gender: string | null;
  ethnicity: string | null;
  startDate: string | null;
  terminationDate: string | null;
  payRate: string;
  payPeriod: string;
  payCurrency: string;
  employmentType: string;
  jobTitle: string;
  effectiveDate: string;
};

const FLATFILE_SETTINGS: ISettings = {
  managed: true,
  title: "Import your Employee data from a Payroll CSV.",
  type: "RipplingImportRow",
  fields: [
    {
      label: "Employee Number",
      key: "employeeNumber",
      description:
        "The employee's id needs to match to the id found in your HRIS",
      validators: [{ validate: "required" }],
      alternates: ["Employee ID"],
    },
    {
      label: "Work Email",
      key: "workEmail",
      validators: [{ validate: "required" }],
      alternates: ["email"],
    },
    {
      label: "Personal Email",
      key: "personalEmail",
      alternates: ["email"],
    },
    {
      label: "Full Name",
      key: "displayName",
      validators: [{ validate: "required" }],
      alternates: ["Display Name"],
    },
    {
      label: "Job Status",
      key: "employmentStatus",
      type: "select",
      options: Object.values(EmploymentStatus).map((status) => ({
        label: status,
        value: status,
      })),
      validators: [{ validate: "required" }],
      alternates: ["Employment Status"],
    },
    {
      label: "Manager Work Email",
      key: "managerWorkEmail",
    },
    {
      label: "Annual Compensation",
      key: "payRate",
      validators: [{ validate: "required" }],
      alternates: ["Salary"],
    },
    {
      label: "Currency",
      key: "payCurrency",
      validators: [{ validate: "required" }],
      alternates: ["Compensation Currency"],
    },
    {
      label: "Employment Type",
      key: "employmentType",
      type: "select",
      options: Object.values(EmploymentType).map((type) => ({
        label: type.toString(),
        value: type,
      })),
      validators: [{ validate: "required" }],
    },
    {
      label: "Job Title",
      key: "jobTitle",
      validators: [{ validate: "required" }],
      alternates: ["Title"],
    },
    {
      label: "Pay Period Start",
      key: "effectiveDate",
      validators: [
        { validate: "required" },
        {
          validate: "regex_matches",
          regex: DATE_REGEX.source,
          error: "Must be in format YYYY-MM-DD or MM/DD/YYYY",
        },
      ],
    },
    {
      label: "City",
      key: "city",
      validators: [{ validate: "required" }],
      alternates: ["Employee City"],
    },
    {
      label: "State",
      key: "state",
      alternates: ["Employee State"],
    },
    {
      label: "Country",
      key: "country",
      validators: [{ validate: "required" }],
      alternates: ["Employee Country"],
    },
    {
      label: "First Name",
      key: "firstName",
    },
    {
      label: "Last Name",
      key: "lastName",
    },
    {
      label: "Gender",
      key: "gender",
      alternates: ["Legal Gender", "Identified Gender"],
    },
    {
      label: "Ethnicity",
      key: "ethnicity",
    },
    {
      label: "Start Date",
      key: "startDate",
      validators: [
        {
          validate: "regex_matches",
          regex: DATE_REGEX.source,
          error: "Must be in format YYYY-MM-DD or MM/DD/YYYY",
        },
      ],
    },
    {
      label: "End Date",
      key: "terminationDate",
      validators: [
        {
          validate: "regex_matches",
          regex: DATE_REGEX.source,
          error: "Must be in format YYYY-MM-DD or MM/DD/YYYY",
        },
      ],
    },
  ],
  ignoreColumns: [
    "Employee",
    "Middle Name",
    "Preferred Name",
    "Role Id",
    "Signing bonus",
    "Target annual bonus",
    "Target annual bonus %",
    "On-target commission",
    "Relocation reimbursement",
    "W2 start date",
    "Department",
    "Organization",
    "Teams",
    "Manager",
    "Payroll name",
    "Payroll run type",
    "Pay period",
    "Pay period end",
    "Pay schedule",
  ],
};
const FLATFILE_HOURLY_SETTINGS: ISettings = {
  managed: true,
  title: "Import your Employee data from a Payroll CSV.",
  type: "RipplingImportRow",
  fields: [
    {
      label: "Employee Number",
      key: "employeeNumber",
      description:
        "The employee's id needs to match to the id found in your HRIS",
      validators: [{ validate: "required" }],
      alternates: ["Employee ID"],
    },
    {
      label: "Work Email",
      key: "workEmail",
      validators: [{ validate: "required" }],
      alternates: ["email"],
    },
    {
      label: "Personal Email",
      key: "personalEmail",
      alternates: ["email"],
    },
    {
      label: "Full Name",
      key: "displayName",
      validators: [{ validate: "required" }],
      alternates: ["Display Name"],
    },
    {
      label: "Job Status",
      key: "employmentStatus",
      type: "select",
      options: Object.values(EmploymentStatus).map((status) => ({
        label: status,
        value: status,
      })),
      validators: [{ validate: "required" }],
      alternates: ["Employment Status"],
    },
    {
      label: "Manager Work Email",
      key: "managerWorkEmail",
    },
    {
      label: "Annual Compensation",
      key: "payRate",
      validators: [
        {
          validate: "required_with_values",
          fieldValues: { hourlyPayRate: null },
        },
      ],
      alternates: ["Salary"],
      description:
        "Annual salary for the employee. Required if no hourly compensation is provided.",
    },
    {
      label: "Hourly Compensation",
      key: "hourlyPayRate",
      validators: [
        {
          validate: "required_with_values",
          fieldValues: { payRate: null },
        },
      ],
      description:
        "Hourly pay rate for the employee. Required if no annual compensation is provided.",
    },
    {
      label: "Currency",
      key: "payCurrency",
      validators: [{ validate: "required" }],
      alternates: ["Compensation Currency"],
    },
    {
      label: "Employment Type",
      key: "employmentType",
      type: "select",
      options: Object.values(EmploymentType).map((type) => ({
        label: type.toString(),
        value: type,
      })),
      validators: [{ validate: "required" }],
    },
    {
      label: "Job Title",
      key: "jobTitle",
      validators: [{ validate: "required" }],
      alternates: ["Title"],
    },
    {
      label: "Pay Period Start",
      key: "effectiveDate",
      validators: [
        { validate: "required" },
        {
          validate: "regex_matches",
          regex: DATE_REGEX.source,
          error: "Must be in format YYYY-MM-DD or MM/DD/YYYY",
        },
      ],
    },
    {
      label: "City",
      key: "city",
      validators: [{ validate: "required" }],
      alternates: ["Employee City"],
    },
    {
      label: "State",
      key: "state",
      alternates: ["Employee State"],
    },
    {
      label: "Country",
      key: "country",
      validators: [{ validate: "required" }],
      alternates: ["Employee Country"],
    },
    {
      label: "First Name",
      key: "firstName",
    },
    {
      label: "Last Name",
      key: "lastName",
    },
    {
      label: "Gender",
      key: "gender",
      alternates: ["Legal Gender", "Identified Gender"],
    },
    {
      label: "Ethnicity",
      key: "ethnicity",
    },
    {
      label: "Start Date",
      key: "startDate",
      validators: [
        {
          validate: "regex_matches",
          regex: DATE_REGEX.source,
          error: "Must be in format YYYY-MM-DD or MM/DD/YYYY",
        },
      ],
    },
    {
      label: "End Date",
      key: "terminationDate",
      validators: [
        {
          validate: "regex_matches",
          regex: DATE_REGEX.source,
          error: "Must be in format YYYY-MM-DD or MM/DD/YYYY",
        },
      ],
    },
  ],
  ignoreColumns: [
    "Employee",
    "Middle Name",
    "Preferred Name",
    "Role Id",
    "Signing bonus",
    "Target annual bonus",
    "Target annual bonus %",
    "On-target commission",
    "Relocation reimbursement",
    "W2 start date",
    "Department",
    "Organization",
    "Teams",
    "Manager",
    "Payroll name",
    "Payroll run type",
    "Pay period",
    "Pay period end",
    "Pay schedule",
  ],
};

interface Props {
  handleChange: (data: RipplingImportRecord[]) => unknown;
  handleCancel?: () => unknown;
}

export function RipplingImportButton({
  handleChange,
  handleCancel,
}: Props): JSX.Element {
  const { isEnabled } = useFeatureFlags();
  const { compStructure } = useCompStructure();
  const enabledHourlyEmployees =
    isEnabled(FeatureFlag.HourlyEmployees) &&
    compStructure?.allowHourlyEmployees === true;
  const settings = enabledHourlyEmployees
    ? FLATFILE_HOURLY_SETTINGS
    : FLATFILE_SETTINGS;
  const validateRecord = recordValidator();
  return (
    <AssembleFlatfileButton
      settings={settings}
      onRecordChange={validateRecord}
      onData={(results: { validData: FlatfileRecord[] }) => {
        const processedRecords: RipplingImportRecord[] = results.validData.map(
          (record) => {
            // explicity cast to RipplingImportRecord to avoid passing hourlyPayRate to the API
            return {
              displayName: record.displayName,
              employeeNumber: record.employeeNumber,
              workEmail: record.workEmail,
              personalEmail: record.personalEmail,
              employmentStatus: record.employmentStatus,
              managerWorkEmail: record.managerWorkEmail,
              city: record.city,
              state: record.state,
              country: record.country,
              firstName: record.firstName,
              lastName: record.lastName,
              gender: record.gender,
              ethnicity: record.ethnicity,
              startDate: record.startDate,
              terminationDate: record.terminationDate,
              payCurrency: record.payCurrency,
              employmentType: record.employmentType,
              jobTitle: record.jobTitle,
              effectiveDate: record.effectiveDate,
              ...processPayType(record.payRate, record.hourlyPayRate),
            };
          }
        );
        handleChange(processedRecords);
        return Promise.resolve();
      }}
      onCancel={handleCancel}
      render={(_, launch) => (
        <Button onClick={launch} variant="contained" color="secondary">
          Payroll Report
        </Button>
      )}
    />
  );
}

function processPayType(
  payRate: string | null,
  hourlyPayRate: string | null
): {
  payRate: string;
  payPeriod: "HOUR" | "YEAR";
} {
  return payRate != null && !isEmptyString(payRate)
    ? {
        payRate,
        payPeriod: "YEAR",
      }
    : {
        payRate: hourlyPayRate ?? "",
        payPeriod: "HOUR",
      };
}

function recordValidator() {
  return function validateRecord(record: FlatFileRecord): IDataHookResponse {
    const noPayRate =
      (!record.payRate || isEmptyString(record.payRate.toString())) &&
      (!record.hourlyPayRate || isEmptyString(record.hourlyPayRate.toString()));
    // make sure only one compensation column has a value
    if (record.payRate && record.hourlyPayRate) {
      return {
        payRate: {
          info: [
            {
              message:
                "Cannot provide both annual compensation and hourly compensation. Please only provide one.",
              level: "error",
            },
          ],
        },
        hourlyPayRate: {
          info: [
            {
              message:
                "Cannot provide both annual compensation and hourly compensation. Please only provide one.",
              level: "error",
            },
          ],
        },
      };
    } else if (noPayRate) {
      return {
        payRate: {
          info: [
            {
              message:
                "Either annual compensation or hourly compensation is required.",
              level: "error",
            },
          ],
        },
        hourlyPayRate: {
          info: [
            {
              message:
                "Either annual compensation or hourly compensation is required.",
              level: "error",
            },
          ],
        },
      };
    }
    return {};
  };
}
