import { gql, useQuery } from "@apollo/client";
import { useState } from "react";
import { useAuth } from "src/components/Auth/AuthContext";
import { useURLSearchParams } from "src/models/URLSearchParams";
import { useTableData } from "src/pages/CompCycle/Plan/Contexts/TableDataContext2";
import {
  CompCycleSingleRecModal_participant,
  CompCycleSingleRecModal_position,
  CompCycleSingleRecQuery,
  CompCycleSingleRecQueryVariables,
  CurrencyCode,
} from "../../../__generated__/graphql";
import { CompCycleSingleRecModal } from "./CompCycleSingleRecModal";
import { CompRecProvider } from "./CompRecommendationContext";

type Props = {
  compCycleId: number;
  employeeId: number;
  isIndirectReport: boolean;
  isPhaseConfigurationPeriod: boolean;
  onClose: () => unknown;
  employeeCurrency: string | null;
};

export function CompCycleSingleRecLoadingBoundary({
  compCycleId,
  employeeId,
  isIndirectReport,
  isPhaseConfigurationPeriod,
  employeeCurrency,
  onClose,
}: Props): JSX.Element | null {
  const { employees, valuation, budget, availablePositions, compCycle } =
    useTableData();

  const { permissions } = useAuth();
  const urlSearchParams = useURLSearchParams();
  const managerId = urlSearchParams.get("manager");
  const actingManagerEmployeeId =
    managerId != null && permissions.isHRBP()
      ? Number.parseInt(managerId)
      : null;
  const selectedCurrency = employeeCurrency as CurrencyCode;
  const [revisedPerfRating, setRevisedPerfRating] = useState<string | null>(
    employees.find((p) => p.subjectId === employeeId)?.perfRating ?? null
  );
  const { data, loading } = useQuery<
    CompCycleSingleRecQuery,
    CompCycleSingleRecQueryVariables
  >(CompCycleSingleRecLoadingBoundary.query, {
    variables: {
      compCycleId,
      employeeId,
      currencyCode: selectedCurrency,
      actingManagerEmployeeId,
      filter: null,
    },
    onCompleted: (data) => {
      setRevisedPerfRating(data.compCycle2?.participant?.perfRating ?? null);
    },
  });

  if (!valuation) return null;

  const participant =
    data?.compCycle2?.participant ??
    ({
      __typename: "CompCycleParticipant",
      compCycleId,
      compRecommendation: null,
      subject: {
        __typename: "Employee2",
        id: employeeId,
        minimalManager: {
          displayName: "Loading...",
        },
        displayName: "",
        activeAt: new Date().toDateString(),
        managerId: managerId === null ? null : Number.parseInt(managerId),
        adjustedCashBands: [],
        activeEmployment: null,
        activeCashCompensation: null,
        ...(employees.find((p) => p.subjectId === employeeId)?.subject ?? null),
      },
      subjectId: employeeId,
      managerCompCycleBudget: null,
      perfRating: revisedPerfRating,
      bonusIndividualPerfRating: null,
      bonusCustomPerfRating: null,
    } as CompCycleSingleRecModal_participant);

  const compCycleData = data?.compCycle2 ?? compCycle!;

  if (data?.compCycle2?.participant == null) {
    return (
      <CompCycleSingleRecModal
        selectedCurrency={selectedCurrency}
        handleClose={onClose}
        compCycleId={compCycleId}
        employee={participant}
        isIndirectReport={isIndirectReport}
        isPhaseConfigurationPeriod={isPhaseConfigurationPeriod}
        positions={availablePositions as CompCycleSingleRecModal_position[]}
        currentValuation={valuation}
        compCycle={compCycleData}
        revisedPerfRating={revisedPerfRating}
        setRevisedPerfRating={setRevisedPerfRating}
        budget={budget ?? null}
        loading={loading}
      />
    );
  }

  return (
    <CompRecProvider
      participant={participant}
      dataCompCycle={data.compCycle2 ?? null}
      revisedPerfRating={revisedPerfRating}
      loading={loading}
    >
      <CompCycleSingleRecModal
        selectedCurrency={selectedCurrency}
        handleClose={onClose}
        compCycleId={compCycleId}
        employee={participant}
        isIndirectReport={isIndirectReport}
        isPhaseConfigurationPeriod={isPhaseConfigurationPeriod}
        positions={availablePositions as CompCycleSingleRecModal_position[]}
        currentValuation={valuation}
        compCycle={compCycleData}
        revisedPerfRating={revisedPerfRating}
        setRevisedPerfRating={setRevisedPerfRating}
        budget={budget ?? null}
        loading={loading}
      />
    </CompRecProvider>
  );
}

CompCycleSingleRecLoadingBoundary.query = gql`
  ${CompCycleSingleRecModal.fragments.participant}
  ${CompCycleSingleRecModal.fragments.compCycle}

  query CompCycleSingleRecQuery(
    $compCycleId: Int!
    $employeeId: Int!
    $currencyCode: CurrencyCode
    $skipEligibility: Boolean = true
    $actingManagerEmployeeId: Int
    $filter: GetParticipantsInput
  ) {
    compCycle2(id: $compCycleId) {
      id
      recommendationsPreFill
      bonusGuidanceRecommendationsPrefill
      ...CompCycleSingleRecModal_compCycle
      participant(id: $employeeId) {
        subjectId
        compCycleId
        ...CompCycleSingleRecModal_participant
        subject {
          activeEmployment {
            id
            positionId
          }
          adjustedCashBands {
            id
            name
            bandPoints {
              id
              bandName
              name
              value {
                ... on CashValue {
                  annualRate
                  hourlyRate
                  currencyCode
                }
              }
            }
          }
          activeCashCompensation(currencyCode: $currencyCode) {
            type
            annualCashEquivalent
            hourlyCashEquivalent
            unit
            employeeId
          }
        }
      }
    }
  }
`;
