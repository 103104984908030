import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";
import clsx from "clsx";
import { memo } from "react";
import { GRAY_4, WHITE } from "../theme";

type Color =
  | "green"
  | "pink"
  | "blue"
  | "gold"
  | "purple"
  | "cyan"
  | "red"
  | "yellow";

const GRADIENTS: Record<Color, CSSProperties> = {
  green: {
    background: "linear-gradient(145.81deg, #81EB5C 17.19%, #BCF4A9 89.26%)",
    color: "#144304",
  },
  pink: {
    background: "linear-gradient(153.43deg, #CA4BF7 20.83%, #E3ADF5 83.33%)",
  },
  gold: {
    background: "linear-gradient(144.73deg, #FFA927 19.13%, #FFE178 75.42%)",
    color: "#7D2803",
  },
  red: {
    background: "linear-gradient(151.93deg, #FF3A6A 25.68%, #FF6464 84.58%)",
  },
  blue: {
    background: "linear-gradient(155.23deg, #015EE3 19.68%, #0FC4FA 93.44%)",
  },
  cyan: {
    background: "linear-gradient(151.93deg, #61FFF6 25.68%, #C6F8FF 84.58%)",
    color: "#0C6192",
  },
  purple: {
    background: "linear-gradient(154.44deg, #410391 19.67%, #8442D9 79.41%)",
  },
  yellow: {
    background: "linear-gradient(151.93deg, #FCFF61 25.68%, #FFF9C6 84.58%)",
    color: "#825B10",
  },
};

const useStyles = makeStyles(() => ({
  root: {
    background: "linear-gradient(155.23deg, #12163A 19.68%, #10236F 93.44%)",
    border: "none",
    borderRadius: "100%",
    color: WHITE,
    outline: "none",
    textAlign: "center",
    textDecoration: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  userImage: {
    borderRadius: "100%",
    height: "100%",
    width: "100%",
  },

  initials: {
    fontWeight: 900,
    display: "grid",
    alignItems: "center",
  },

  // Sizes
  mini: {
    minHeight: "24px",
    minWidth: "24px",
    height: "24px",
    width: "24px",
    "& $initials": {
      fontSize: "10px",
      lineHeight: "24px",
    },
  },
  small: {
    minHeight: "32px",
    minWidth: "32px",
    height: "32px",
    width: "32px",
    "& $initials": {
      fontSize: "12px",
      lineHeight: "32px",
    },
  },
  mediumSmall: {
    minHeight: "48px",
    minWidth: "48px",
    height: "48px",
    width: "48px",
    "& $initials": {
      fontSize: "20px",
      letterSpacing: "-0.5px",
    },
  },
  medium: {
    minHeight: "80px",
    minWidth: "80px",
    height: "80px",
    width: "80px",
    "& $initials": {
      fontSize: "26px",
      letterSpacing: "-0.5px",
    },
  },
  large: {
    minHeight: "104px",
    minWidth: "104px",
    height: "104px",
    width: "104px",
    "& $initials": {
      fontSize: "38px",
      letterSpacing: "-0.5px",
    },
  },
  grayscaleImage: {
    filter: "grayscale(100%)",
    opacity: "50%",
  },
  grayscaleGradient: {
    background: "linear-gradient(158.21deg, #FFFFFF 10.48%, #E8F5FF 91.8%)",
    color: GRAY_4,
  },
  border: {
    background: WHITE,
    border: `2px solid ${WHITE}`,
    boxShadow: "0px 1px 2px rgba(10, 36, 64, 0.2)",
  },

  // Colors
  ...GRADIENTS,
}));

type Size = "mini" | "small" | "mediumSmall" | "medium" | "large";

interface Props {
  photoURL: string | null | undefined;
  displayName: string | null | undefined;
  size?: Size;
  grayscale?: boolean;
  showTooltip?: boolean;
  border?: boolean;
  tooltipTitle?: string;
  // this prop will override the badge's random color generation
  backgroundColor?: string;
  id?: string;
}

export const UserAvatar = memo(function UserAvatar({
  photoURL,
  displayName,
  size = "small",
  grayscale = false,
  showTooltip = false,
  border = false,
  backgroundColor,
  tooltipTitle,
  id,
}: Props): JSX.Element {
  const classes = useStyles();

  // This regex matches capital letters A-Z and includes characters with accents
  const allCapsRegex = new RegExp(/[A-Z\u00C0-\u00DC]+/g);
  const letters = displayName?.match(allCapsRegex);

  const initials =
    displayName === "-" ? displayName : letters?.join("").slice(0, 2);
  const grayscaleClassName =
    photoURL !== null && grayscale
      ? classes.grayscaleImage
      : classes.grayscaleGradient;

  const avatar = (
    <div
      style={{ backgroundColor }}
      className={clsx(classes.root, classes[size], {
        [grayscaleClassName]: grayscale,
        [classes.border]: border,
        [classes[getColor(displayName ?? "")]]:
          backgroundColor === undefined && !grayscale,
      })}
      id={id}
    >
      {photoURL !== null && photoURL !== undefined ? (
        <img
          className={clsx(classes.userImage, classes.initials)}
          src={photoURL}
          alt={initials}
        />
      ) : (
        <Typography variant="body2" className={classes.initials}>
          {initials}
        </Typography>
      )}
    </div>
  );

  return showTooltip === true && displayName != null ? (
    <Tooltip placement="top" title={tooltipTitle ? tooltipTitle : displayName}>
      {avatar}
    </Tooltip>
  ) : (
    avatar
  );
});

const COLORS = Object.keys(GRADIENTS) as Color[];
function getColor(displayName: string): Color {
  let charSum = 0;
  for (let i = 0; i < displayName.length; i++) {
    charSum += displayName.charCodeAt(i);
  }

  return COLORS[charSum % COLORS.length];
}
