import { gql } from "@apollo/client";
import { RecItemType } from "@asmbl/shared/types";
import { formatNumeral, partition } from "@asmbl/shared/utils";
import {
  CashCompType,
  CondensedTable_matrix,
  MatrixTypeEnum,
  CondensedTableBonusGuidanceDifferenceCell_participant as Participant,
} from "src/__generated__/graphql";
import { getBonusGuidance } from "src/models/Guidance";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { useTableData } from "../Contexts/TableDataContext2";
import { DefaultCell } from "./DefaultCell";

type Props = {
  row: { original: Participant };
};

export function CondensedTableBonusGuidanceDifferenceCell({
  row: { original: employee },
}: Props): JSX.Element {
  const { matrices, compCycle } = useTableData();

  const [_, allOtherMatrices] = partition(
    matrices,
    (matrix) => matrix.type === MatrixTypeEnum.MERIT
  );

  const bonusMatricesMap = new Map<MatrixTypeEnum, CondensedTable_matrix[]>();
  allOtherMatrices.forEach((matrix) => {
    if (matrix.type !== MatrixTypeEnum.BUDGET) {
      if (bonusMatricesMap.has(matrix.type)) {
        bonusMatricesMap.get(matrix.type)?.push(matrix);
      } else {
        bonusMatricesMap.set(matrix.type, [matrix]);
      }
    }
  });

  const bonusGuidance = getBonusGuidance(
    employee,
    bonusMatricesMap,
    Number(compCycle?.organizationCompCyclePerfRating)
  );

  const target = employee.subject.activeCashCompensation?.find(
    (comp) => comp.type === CashCompType.RECURRING_BONUS
  )?.annualCashEquivalent.value;

  const individualValue =
    "pay" in bonusGuidance.BONUS_GUIDANCE_INDIVIDUAL_PERFORMANCE
      ? bonusGuidance.BONUS_GUIDANCE_INDIVIDUAL_PERFORMANCE.pay
      : 0;

  const companyValue =
    "pay" in bonusGuidance.BONUS_GUIDANCE_COMPANY_PERFORMANCE
      ? bonusGuidance.BONUS_GUIDANCE_COMPANY_PERFORMANCE.pay
      : 0;

  const customValue =
    "pay" in bonusGuidance.BONUS_GUIDANCE_CUSTOM_PERFORMANCE
      ? bonusGuidance.BONUS_GUIDANCE_CUSTOM_PERFORMANCE.pay
      : 0;

  const guidance =
    target != null
      ? (individualValue + companyValue + customValue) / target
      : null;

  const actualRecurringBonusItems =
    employee.compRecommendation?.latestSubmittedItems.filter(
      (item) =>
        item.recommendationType === RecItemType.ACTUAL_RECURRING_BONUS ||
        item.recommendationType ===
          RecItemType.ACTUAL_RECURRING_BONUS_COMPANY ||
        item.recommendationType === RecItemType.ACTUAL_RECURRING_BONUS_CUSTOM
    ) ?? [];

  const actualRecurringBonusItem = actualRecurringBonusItems.reduce(
    (acc, item) => {
      return acc + (item.recommendedCashValue?.value ?? 0);
    },
    0
  );

  const actualVsTarget =
    target != null ? actualRecurringBonusItem / target : null;

  const diff =
    actualVsTarget != null && guidance != null
      ? actualVsTarget - guidance
      : null;

  return (
    <div>
      {diff != null ? (
        <AssembleTruncatedTypography align="right">
          {formatNumeral(diff, {
            style: "percent",
            maximumFractionDigits: 2,
          })}
        </AssembleTruncatedTypography>
      ) : (
        <DefaultCell />
      )}
    </div>
  );
}

CondensedTableBonusGuidanceDifferenceCell.fragments = {
  participant: gql`
    fragment CondensedTableBonusGuidanceDifferenceCell_participant on CompCycleParticipant {
      subject {
        activeCashCompensation(currencyCode: $currencyCode) {
          type
          annualCashEquivalent
          hourlyCashEquivalent
          unit
          employeeId
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedItems {
          id
          recommendationType
          recommendedPercentValue
          recommendedCashValue(currencyCode: $currencyCode)
          recommendedPercentValue
          unitType
        }
      }
      bonusIndividualPerfRating
      bonusCustomPerfRating
      subjectId
    }
  `,
};

CondensedTableBonusGuidanceDifferenceCell.Header = ColumnIdsToHeaders.get(
  ColumnIds.BONUS_GUIDANCE_DIFF
);
CondensedTableBonusGuidanceDifferenceCell.id = ColumnIds.BONUS_GUIDANCE_DIFF;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableBonusGuidanceDifferenceCell,
  Header: CondensedTableBonusGuidanceDifferenceCell.Header,
  id: CondensedTableBonusGuidanceDifferenceCell.id,
  defaultCanSort: false,
};

CondensedTableBonusGuidanceDifferenceCell.column = column;
