import { gql } from "@apollo/client";
import {
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
} from "@material-ui/core";
import { UpdateTargetBonusesModal_employee as Employee } from "src/__generated__/graphql";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { ImportIcon } from "src/components/AssembleIcons/Brand/ImportIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import { VariableCompImportButton } from "src/components/Settings/VariableComp/VariableCompImportButton";
import { WARNING_TEXT } from "src/theme";
import CsvImportIcon from "../../../../../assets/svgs/illustrations/csv-import.svg?react";

const useStyles = makeStyles((theme) => ({
  dialogHeader: {
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  dialogTitle: {
    display: "flex",
    flexDirection: "column",
  },

  dialogContent: {
    padding: theme.spacing(1, 6),

    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
  },

  listGroup: {
    padding: "0",
    margin: "0",
    paddingLeft: theme.spacing(3),
  },

  dualPane: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(1.5),
  },

  warningText: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },

  dialogActions: {
    paddingRight: 0,
    paddingBottom: theme.spacing(0.5),
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),
  },
}));

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  employees: Employee[];
};

export function UpdateTargetBonusesModal({
  isOpen,
  handleClose,
  employees,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="sm"
      disableEnforceFocus
    >
      <div className={classes.dialogHeader}>
        <div className={classes.dialogTitle}>
          <AssembleTypography variant="h4">
            Update Target Bonuses
          </AssembleTypography>
          <AssembleTypography variant="productParagraphMedium">
            If you have people in this guidance population whose target has
            changed or needs to be prorated, please take the time to update them
            now, otherwise we'll pull in the current variable pay you've
            previously uploaded into Assemble.
          </AssembleTypography>
        </div>
      </div>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.dualPane}>
          <div>
            <AssembleTypography>
              <strong>Make sure:</strong>
            </AssembleTypography>
            <ul className={classes.listGroup}>
              <li>
                <AssembleTypography variant="productParagraphMedium">
                  There's only 1 person per line
                </AssembleTypography>
              </li>
              <li>
                <AssembleTypography variant="productParagraphMedium">
                  You have a unique email address for each person
                </AssembleTypography>
              </li>
              <li>
                <AssembleTypography variant="productParagraphMedium">
                  Bonuses are prorated accordingly
                </AssembleTypography>
              </li>
              <li>
                <AssembleTypography variant="productParagraphMedium">
                  You include additional scoring as it pertains to bonus
                  calculation (like a Manager's Score)
                </AssembleTypography>
              </li>
            </ul>
          </div>
          <div>
            <CsvImportIcon />
          </div>
        </div>
      </DialogContent>
      <div className={classes.warningText}>
        <AssembleTypography textColor={WARNING_TEXT}>
          <em>
            Note: as of today, we don't prorate anything in app so you'll need
            to take care of that in your upload.
          </em>
        </AssembleTypography>
      </div>
      <DialogActions className={classes.dialogActions}>
        <AssembleButton
          variant="text"
          color="primary"
          label="Cancel"
          size="medium"
          onClick={handleClose}
        />

        <VariableCompImportButton
          employees={employees}
          onCancel={handleClose}
          buttonOverrideProps={{
            size: "medium",
            variant: "contained",
            label: "Update Bonuses",
            startIcon: <ImportIcon inherit />,
          }}
        />
      </DialogActions>
    </Dialog>
  );
}

UpdateTargetBonusesModal.fragments = {
  employee: gql`
    ${VariableCompImportButton.fragments.employee}
    fragment UpdateTargetBonusesModal_employee on Employee {
      id
      ...VariableCompImportButton_employee
    }
  `,
};
