import { gql } from "@apollo/client";
import { round } from "@asmbl/shared/utils";
import uniqBy from "lodash/uniqBy";
import {
  groupMeritMatrices_matrix as Matrix,
  MatrixCard_matrixGuide as MatrixGuide,
  MatrixTypeEnum,
  UpdateMatrixInput,
} from "src/__generated__/graphql";
import { GuidancePopulation } from "../GuidanceAndBudget/types";
import { Matrix as MatrixComponent } from "./Content/Matrix";
import {
  GuidanceEligibilityPool,
  GuideDraft,
  MeritGuidancePopulation,
} from "./useMultipleMatrices";

export function isMatrixTypeMeritGuidance(
  matrix: GuidancePopulation
): matrix is MeritGuidancePopulation {
  return "guidance" in matrix;
}

export function groupMeritMatrices(
  matrices: Matrix[]
): MeritGuidancePopulation[] {
  const result: MeritGuidancePopulation[] = [];
  const processed = new Set<number>();

  const baseMatrix = matrices.filter(
    (matrix) => matrix.type === MatrixTypeEnum.MERIT
  );

  for (const matrix of baseMatrix) {
    const {
      id,
      siblingMatrices,
      name,
      matrixGuides,
      eligibilityRules,
      eligibleParticipants,
      estimatedCost,
    } = matrix;

    const hasBeenProcessed = processed.has(id);

    if (hasBeenProcessed) {
      continue;
    }

    const ids = [id];

    siblingMatrices.forEach((sibling) => {
      if (!processed.has(sibling.id)) {
        ids.push(sibling.id);
      }
    });

    const budgetMatrix = matrices.find(
      (matrix) =>
        siblingMatrices
          .map((siblingMatrix) => siblingMatrix.id)
          .includes(matrix.id) && matrix.type === MatrixTypeEnum.BUDGET
    );

    // eventually we'll need to use the current matrix + the sibling matrix
    // to fill in the guidance and budget values
    result.push({
      name,
      selected: false,
      eligibilityRules,
      eligibleParticipants,
      edited: false,
      eligibilityValidations: {
        duplicates: [],
      },
      guidance: {
        id: matrix.id,
        type: MatrixTypeEnum.MERIT,
        matrixGuides: matrixGuides,
        estimatedCost: estimatedCost,
      },
      budget: {
        id: budgetMatrix?.id,
        type: MatrixTypeEnum.BUDGET,
        matrixGuides: budgetMatrix?.matrixGuides,
        estimatedCost: budgetMatrix?.estimatedCost,
      },
    });

    processed.add(id);
  }

  return result.map((group, index) => ({ ...group, selected: index === 0 }));
}

export const buildMatrix = (
  guides?: (MatrixGuide | GuideDraft)[]
): { label: string | number; id?: string | number }[][] => {
  if (guides == null) {
    return [[]];
  }

  const orderedPerfRatings = uniqBy(
    guides.map((guide) => guide.matrixPerfRatingOption),
    "id"
  ).sort((a, b) => (a.rank < b.rank ? -1 : 1));

  const orderedRanges = uniqBy(
    guides.map((guide) => guide.matrixRange),
    "id"
  ).sort((a, b) => {
    const aRange = a.rangeStart ?? 0;
    const bRange = b.rangeStart ?? 0;

    return aRange < bRange ? -1 : 1;
  });

  const matrix: { label: string; id?: number | string }[][] = [];

  orderedPerfRatings.forEach((rating, ratingIdx) => {
    matrix[ratingIdx] = [{ label: rating.name, id: rating.id }];
    orderedRanges.forEach((range) => {
      const guide = guides.find(
        (guide) =>
          guide.matrixPerfRatingOption.id === rating.id &&
          guide.matrixRange.id === range.id
      );

      const percent = guide?.percent;

      if (guide != null && percent != null) {
        matrix[ratingIdx].push({
          label: round(percent * 100, 3),
          id: guide.id,
        });
      } else {
        matrix[ratingIdx].push({ label: "-" });
      }
    });
  });

  return matrix;
};

/**
 * given an employeeId, find the guidance population that the employee
 * belongs to
 * @param matrices
 * @param employeeId
 */
export function findMatrixByDuplicateTargetEmployeeId(
  matrices: GuidancePopulation[],
  employeeId: number
): GuidancePopulation[] {
  const result: GuidancePopulation[] = [];

  matrices.forEach((matrix) => {
    if (matrix.eligibilityValidations.duplicates.includes(employeeId)) {
      result.push(matrix);
    }
  });

  return result;
}

export function getUnassignedParticipants(
  allEligible: GuidanceEligibilityPool[],
  matrices: GuidancePopulation[]
): number[] {
  const allAssigned: number[] = [];
  const allUnassigned: number[] = [];

  matrices.forEach((matrix) => {
    matrix.eligibleParticipants?.forEach((participant) => {
      allAssigned.push(participant);
    });
  });

  allEligible.forEach((employee) => {
    if (!allAssigned.includes(employee.subjectId)) {
      allUnassigned.push(employee.subjectId);
    }
  });

  return allUnassigned;
}

export const matrixToUpdateArgs = ({
  id,
  matrixGuides,
}: {
  id?: number;
  matrixGuides?: (MatrixGuide | GuideDraft)[];
}): UpdateMatrixInput => ({
  id: id as number,
  matrixGuides:
    matrixGuides?.map((guide) => ({
      id: (guide.id as number | string).toString(),
      percent: guide.percent,
      matrixId: guide.matrixId,
      matrixPerfRatingOptionId: guide.matrixPerfRatingOptionId.toString(),
      matrixRangeId: guide.matrixRangeId.toString(),
      matrixPerfRatingOption: {
        id: guide.matrixPerfRatingOptionId.toString(),
        rank: guide.matrixPerfRatingOption.rank,
        name: guide.matrixPerfRatingOption.name,
      },
      matrixRange: {
        id: guide.matrixRangeId.toString(),
        rangeStart: guide.matrixRange.rangeStart,
      },
    })) ?? [],
});

groupMeritMatrices.fragments = {
  matrix: gql`
    ${MatrixComponent.fragments.guide}
    fragment groupMeritMatrices_matrix on Matrix {
      id
      type
      name
      eligibilityRules
      eligibleParticipants
      estimatedCost
      siblingMatrices {
        id
        type
      }
      matrixGuides {
        id
        ...MatrixCard_matrixGuide
      }
      bonusGuidanceSettings {
        id
        matrixId
        isDimensionEnabled
        customDimensionName
        customDimensionValueType
      }
    }
  `,
};
