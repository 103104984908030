import { gql } from "@apollo/client";
import { getSalaryCashComp } from "@asmbl/shared/compensation";
import { CurrencyCode } from "@asmbl/shared/constants";
import { money } from "@asmbl/shared/money";
import { mapMaybe, partition } from "@asmbl/shared/utils";

import {
  ClickAwayListener,
  Grow,
  IconButton,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
} from "@material-ui/core";
import uniqBy from "lodash/uniqBy";
import { enqueueSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { useIntercom } from "react-use-intercom";
import {
  CompUnit,
  CondensedTable_matrix as Matrix,
  MatrixTypeEnum,
  BulkActionsBar_participant as Participant,
  RecItemInput,
  RecItemType,
  RecommendationReviewStatus,
} from "src/__generated__/graphql";
import { useTrack } from "src/analytics";
import { AssembleTypography } from "src/components/AssembleTypography";
import { getBonusGuidance, getMeritGuidance } from "src/models/Guidance";
import {
  useEmplaceCompRecommendations,
  useSubmitRecReviews,
} from "src/mutations/CompRecommendation";
import { formatError } from "src/pages/Authentication/Authentication";
import { useBulkActionsData } from "src/pages/CompCycle/Plan/Contexts/BulkActionsContext";
import { useTableData } from "src/pages/CompCycle/Plan/Contexts/TableDataContext2";
import { useTableFilterContext } from "src/pages/CompCycle/Plan/Contexts/TableFilterContext";
import { GRAY_3, GRAY_4, GRAY_6, PURPLE_1, WHITE } from "../../../theme";
import { AssembleBarModal } from "../../AssembleBarModal";
import { TargetIcon } from "../../AssembleIcons/Small/TargetIcon";
import { ThumbsUpIcon } from "../../AssembleIcons/Small/ThumbsUpIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    top: "auto",
    backgroundColor: "rgba(255, 0, 0, 0)",
    boxShadow: "none",
    left: theme.spacing(10.125), // account for navbar and border
    overflow: "hidden",
  },
  tabContainer: {
    display: "flex",
    overflow: "hidden",
    paddingTop: "5px",
    maxHeight: "2rem",
  },
  tab: {
    backgroundColor: WHITE,
    minWidth: "7.75rem",
    height: "2.5rem",
    boxShadow: `0px 0px 0px 1px ${GRAY_6}`,
    borderRadius: "0px 5px 0px 0px",
    zIndex: 1,
    justifySelf: "flex-start",
  },
  tabButton: {
    padding: theme.spacing(0, 0, 0, 1.5),
  },
  tabText: {
    color: GRAY_4,
    fontWeight: 700,
    fontSize: "0.75rem",
    lineHeight: "140%",
    "&:hover": {
      color: PURPLE_1,
    },
  },
  rotatedIcon: {
    transform: "rotate(180deg)",
  },
  barContainer: {
    display: "flex",
    flexDirection: "row",
    boxShadow: `0 -5px 15px -5px rgba(10,36,64,.1)`,
  },
  rightBuffer: {
    display: "flex",
    flexDirection: "column",
    width: "17%",
    boxShadow: `0px -1px 0px ${GRAY_6}`,
    backgroundColor: WHITE,
    overflow: "hidden",
  },
  barModal: {
    width: "310px",
    height: "54px",
  },
  popper: {
    zIndex: 1000,
    paddingBottom: theme.spacing(1),
  },
  menuListHeader: {
    padding: theme.spacing(0.25, 0.25),
  },
}));

export const BulkActionsBar = (): JSX.Element => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const { compCycleId, selectedCurrency, matrices, compCycle } = useTableData();
  const { actingManagerEmployeeId } = useTableFilterContext();

  const {
    getSelectedParticipants,
    clearSelectedParticipants,
    selectedCount,
    selectOrClearAll,
  } = useBulkActionsData();
  const intercom = useIntercom();

  const { trackEvent } = useTrack();

  const currencyCode = selectedCurrency === "all" ? null : selectedCurrency;

  const [approvalsLoading, setApprovalsLoading] = useState(false);
  const [guidanceLoading, setGuidanceLoading] = useState(false);

  const emplaceCompRecommendations = useEmplaceCompRecommendations(
    compCycleId,
    currencyCode,
    actingManagerEmployeeId
  );

  const submitCompRecReview = useSubmitRecReviews(currencyCode);

  const handleGuidanceOnClick = async () => {
    setGuidanceLoading(true);
    try {
      const guidance = mapMaybe(
        getSelectedParticipants().map((participant) =>
          generateGuidance(participant, matrices)
        ),
        (guidance) => guidance
      );

      if (guidance.length === 0) {
        enqueueSnackbar(
          "No guidance to submit; guidance has either already been submitted or there is no guidance to apply.",
          { variant: "info" }
        );
        clearSelectedParticipants();
        setGuidanceLoading(false);
        return;
      }

      const compRecs = await emplaceCompRecommendations(guidance);
      if (
        compRecs &&
        compRecs.emplaceCompRecommendations.participants.length > 0
      ) {
        clearSelectedParticipants();
        trackEvent({
          object: "Change Requests",
          subArea: "Guidance Button",
          action: "Submitted",
          compCycleId,
        });
        enqueueSnackbar("Guidance submitted.", { variant: "success" });
      } else {
        enqueueSnackbar("No guidance was submitted. Please try again.", {
          variant: "default",
        });
      }
      setGuidanceLoading(false);
    } catch (e) {
      setGuidanceLoading(false);
      return enqueueSnackbar(formatError(e), { variant: "error" });
    }
  };

  const handleBonusGuidanceOnClick = async () => {
    setGuidanceLoading(true);
    try {
      const guidance = mapMaybe(
        getSelectedParticipants().map((participant) =>
          generateBonusGuidance(
            participant,
            compCycle?.organizationCompCyclePerfRating,
            matrices
          )
        ),
        (guidance) => guidance
      );

      if (guidance.length === 0) {
        enqueueSnackbar(
          "No guidance to submit; guidance has either already been submitted or there is no guidance to apply.",
          { variant: "info" }
        );
        clearSelectedParticipants();
        setGuidanceLoading(false);
        return;
      }

      const compRecs = await emplaceCompRecommendations(guidance);
      if (
        compRecs &&
        compRecs.emplaceCompRecommendations.participants.length > 0
      ) {
        clearSelectedParticipants();
        trackEvent({
          object: "Change Requests",
          subArea: "Guidance Button",
          action: "Submitted",
          compCycleId,
        });
        enqueueSnackbar("Guidance submitted.", { variant: "success" });
      } else {
        enqueueSnackbar("No guidance was submitted. Please try again.", {
          variant: "default",
        });
      }
      setGuidanceLoading(false);
    } catch (e) {
      setGuidanceLoading(false);
      return enqueueSnackbar(formatError(e), { variant: "error" });
    }
  };

  const handleApproveOnClick = async () => {
    setApprovalsLoading(true);
    const compRecs = mapMaybe(getSelectedParticipants(), (participant) =>
      participant.compRecommendation &&
      participant.compRecommendation.latestSubmittedItems.length > 0
        ? participant
        : null
    );
    if (compRecs.length === 0) {
      enqueueSnackbar(
        "No reviews to submit. Make sure recommendations have changes to approve.",
        { variant: "info" }
      );
      setApprovalsLoading(false);
      return;
    }
    try {
      const updatedRecs = await submitCompRecReview({
        compCycleId,
        actingManagerEmployeeId,
        data: compRecs.map((rec) => ({
          subjectId: rec.subjectId,
          compCycleId,
          status: RecommendationReviewStatus.APPROVED,
        })),
      });
      if (updatedRecs != null) {
        clearSelectedParticipants();
        trackEvent({
          object: "Change Requests",
          subArea: "Bulk Actions Bar",
          action: "Submitted",
          compCycleId,
        });

        intercom.trackEvent("Comp Recommendations Submitted");
        enqueueSnackbar("Your reviews have been submitted.", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("No reviews were submitted. Please try again.", {
          variant: "default",
        });
      }
      setApprovalsLoading(false);
    } catch (e) {
      setApprovalsLoading(false);

      return enqueueSnackbar(formatError(e), { variant: "error" });
    }
  };

  return (
    <>
      <Popper
        className={classes.popper}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <AssembleTypography
                    className={classes.menuListHeader}
                    variant="productEyebrow"
                    textColor={GRAY_4}
                  >
                    APPLY GUIDANCE
                  </AssembleTypography>

                  <MenuItem
                    onClick={async () => {
                      await handleGuidanceOnClick();
                      handleClose();
                    }}
                  >
                    Merit Guidance
                  </MenuItem>
                  <MenuItem
                    onClick={async () => {
                      await handleBonusGuidanceOnClick();
                      handleClose();
                    }}
                  >
                    Bonus Guidance
                  </MenuItem>
                  <MenuItem
                    onClick={async () => {
                      await handleGuidanceOnClick();
                      await handleBonusGuidanceOnClick();
                      handleClose();
                    }}
                  >
                    Both
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <AssembleBarModal
        selectedCount={selectedCount}
        className={classes.barModal}
        iconOnClick={selectOrClearAll}
      >
        <div>
          <Tooltip title="Accept Requests" placement="top" arrow>
            <IconButton
              onClick={handleApproveOnClick}
              disabled={approvalsLoading}
            >
              <ThumbsUpIcon color={GRAY_3} width={16} height={16} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Submit Guidance" placement="top" arrow>
            <IconButton
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              disabled={guidanceLoading}
            >
              <TargetIcon
                color={open ? PURPLE_1 : GRAY_3}
                width={16}
                height={16}
              />
            </IconButton>
          </Tooltip>
        </div>
      </AssembleBarModal>
    </>
  );
};

BulkActionsBar.fragments = {
  participant: gql`
    fragment BulkActionsBar_participant on CompCycleParticipant {
      subjectId
      compCycleId
      perfRating
      bonusIndividualPerfRating
      bonusCustomPerfRating
      subject {
        id
        activeEmployment {
          id
          positionId
          payCurrencyCode
        }
        adjustedCashBands(currencyCode: $currencyCode) {
          id
          name
          bandPoints {
            id
            bandName
            name
            value {
              ... on CashValue {
                annualRate
                hourlyRate
                currencyCode
              }
            }
          }
        }
        activeCashCompensation(currencyCode: $currencyCode) {
          type
          annualCashEquivalent
          hourlyCashEquivalent
          unit
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedItems {
          id
          submittedAt
          recommendationType
          recommendedCashValue(currencyCode: $currencyCode)
          recommendedPercentValue
          recommendedTitle
          recommendedPosition {
            id
          }
          note
          unitType
        }
      }
    }
  `,
};

function generateBonusGuidance(
  participant: Participant,
  orgPerfRating: string | null | undefined,
  matrices?: Matrix[]
) {
  const {
    compRecommendation,
    subject: { activeEmployment },
  } = participant;
  const currencyCode = activeEmployment?.payCurrencyCode as CurrencyCode | null;

  const [_, allOtherMatrices] = partition(
    matrices ?? [],
    (matrix) => matrix.type === MatrixTypeEnum.MERIT
  );

  const bonusMatricesMap = new Map<MatrixTypeEnum, Matrix[]>();
  allOtherMatrices.forEach((matrix) => {
    if (matrix.type !== MatrixTypeEnum.BUDGET) {
      if (bonusMatricesMap.has(matrix.type)) {
        bonusMatricesMap.get(matrix.type)?.push(matrix);
      } else {
        bonusMatricesMap.set(matrix.type, [matrix]);
      }
    }
  });

  const bonusGuidance = getBonusGuidance(
    participant,
    bonusMatricesMap,
    Number(orgPerfRating)
  );

  const individualBonusGuidance =
    "pay" in bonusGuidance.BONUS_GUIDANCE_INDIVIDUAL_PERFORMANCE
      ? bonusGuidance.BONUS_GUIDANCE_INDIVIDUAL_PERFORMANCE.pay
      : null;

  const companyBonusGuidance =
    "pay" in bonusGuidance.BONUS_GUIDANCE_COMPANY_PERFORMANCE
      ? bonusGuidance.BONUS_GUIDANCE_COMPANY_PERFORMANCE.pay
      : null;

  const customBonusGuidance =
    "pay" in bonusGuidance.BONUS_GUIDANCE_CUSTOM_PERFORMANCE
      ? bonusGuidance.BONUS_GUIDANCE_CUSTOM_PERFORMANCE.pay
      : null;

  if (
    individualBonusGuidance == null &&
    companyBonusGuidance == null &&
    customBonusGuidance == null
  )
    return;

  const individualBonusItem = compRecommendation?.latestSubmittedItems.find(
    (item) => item.recommendationType === RecItemType.ACTUAL_RECURRING_BONUS
  );

  const companyBonusItem = compRecommendation?.latestSubmittedItems.find(
    (item) =>
      item.recommendationType === RecItemType.ACTUAL_RECURRING_BONUS_COMPANY
  );

  const customBonusItem = compRecommendation?.latestSubmittedItems.find(
    (item) =>
      item.recommendationType === RecItemType.ACTUAL_RECURRING_BONUS_CUSTOM
  );

  const latestItems = compRecommendation
    ? compRecommendation.latestSubmittedItems.filter(
        (item) =>
          item.recommendationType !== RecItemType.ACTUAL_RECURRING_BONUS &&
          item.recommendationType !==
            RecItemType.ACTUAL_RECURRING_BONUS_COMPANY &&
          item.recommendationType !== RecItemType.ACTUAL_RECURRING_BONUS_CUSTOM
      )
    : [];

  const newItems: RecItemInput[] = [];

  const shouldApplyAnyRecs =
    currencyCode != null &&
    ((individualBonusGuidance != null &&
      individualBonusItem?.recommendedCashValue?.value !==
        individualBonusGuidance) ||
      (companyBonusGuidance != null &&
        companyBonusItem?.recommendedCashValue?.value !==
          companyBonusGuidance) ||
      (customBonusGuidance != null &&
        customBonusItem?.recommendedCashValue?.value !== customBonusGuidance));

  if (!shouldApplyAnyRecs) return;

  if (individualBonusGuidance != null) {
    newItems.push({
      recommendationType: RecItemType.ACTUAL_RECURRING_BONUS,
      note: "Applied from guidance suggestion",
      recommendedCashValue: money(individualBonusGuidance, currencyCode),
      unitType: CompUnit.CASH,
    });
  }

  if (companyBonusGuidance != null) {
    newItems.push({
      recommendationType: RecItemType.ACTUAL_RECURRING_BONUS_COMPANY,
      note: "Applied from guidance suggestion",
      recommendedCashValue: money(companyBonusGuidance, currencyCode),
      unitType: CompUnit.CASH,
    });
  }

  if (customBonusGuidance != null) {
    newItems.push({
      recommendationType: RecItemType.ACTUAL_RECURRING_BONUS_CUSTOM,
      note: "Applied from guidance suggestion",
      recommendedCashValue: money(customBonusGuidance, currencyCode),
      unitType: CompUnit.CASH,
    });
  }

  if (newItems.length === 0) return;

  const allItems = [
    ...latestItems.map((item) => ({
      recommendationType: item.recommendationType,
      note: item.note,
      recommendedPercentValue: item.recommendedPercentValue,
      recommendedCashValue: item.recommendedCashValue,
      recommendedTitle: item.recommendedTitle,
      recommendedPositionId: item.recommendedPosition?.id,
      unitType: item.unitType,
    })),
    ...newItems,
  ];

  return {
    items: allItems,
    subjectId: participant.subjectId,
  };
}

function generateGuidance(participant: Participant, matrices?: Matrix[]) {
  const { compRecommendation } = participant;

  const basePay = getSalaryCashComp(participant.subject.activeCashCompensation);

  if (basePay == null || matrices == null) return;

  const meritMatrices = matrices.filter(
    (matrix) => matrix.type === MatrixTypeEnum.MERIT
  );

  const meritMatrix =
    meritMatrices.length > 1
      ? meritMatrices.find((matrix) => {
          return (matrix.eligibleParticipants as number[]).includes(
            participant.subjectId
          );
        })
      : meritMatrices[0];

  const matrixGuides =
    meritMatrix?.matrixGuides.flatMap((matrixGuide) => ({
      ...matrixGuide,
      perfRatingOptionId: matrixGuide.matrixPerfRatingOption.id,
      matrixRangeId: matrixGuide.matrixRange.id,
    })) ?? [];

  const perfRatingOptions = uniqBy(
    matrixGuides.flatMap((matrixGuide) => matrixGuide.matrixPerfRatingOption),
    "name"
  );

  const matrixRanges = uniqBy(
    matrixGuides.flatMap((matrixGuide) => matrixGuide.matrixRange),
    "rangeStart"
  );

  const guidanceValue = getMeritGuidance(
    {
      ...participant,
      ...participant.subject,
    },
    matrixGuides,
    matrixRanges,
    perfRatingOptions
  );

  const guidance = typeof guidanceValue === "number" ? guidanceValue : null;

  if (guidance == null) return;

  const guidanceItem = compRecommendation?.latestSubmittedItems.find(
    (item) => item.recommendationType === RecItemType.MERIT_INCREASE
  );

  // guidance already auto-applied, do not apply again
  if (guidanceItem && guidanceItem.recommendedPercentValue === guidance * 100)
    return;

  const latestItems = compRecommendation
    ? compRecommendation.latestSubmittedItems.filter(
        (item) => item.recommendationType !== RecItemType.MERIT_INCREASE
      )
    : [];

  const newItems: RecItemInput[] = [
    ...latestItems.map((item) => ({
      recommendationType: item.recommendationType,
      note: item.note,
      recommendedPercentValue: item.recommendedPercentValue,
      recommendedCashValue: item.recommendedCashValue,
      recommendedTitle: item.recommendedTitle,
      recommendedPositionId: item.recommendedPosition?.id,
      unitType: item.unitType,
    })),
    {
      recommendationType: RecItemType.MERIT_INCREASE,
      note: "Applied from guidance suggestion",
      recommendedPercentValue: guidance * 100,
      unitType: CompUnit.PERCENT_OF_SALARY,
    },
  ];

  return {
    items: newItems,
    subjectId: participant.subjectId,
  };
}
