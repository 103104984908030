import { makeStyles } from "@material-ui/core";
import { useIntercom } from "react-use-intercom";
import { useTrack } from "src/analytics";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { CompCycleDataChangeHandler } from "src/components/CompCycle/CompCycleWizard/types";
import { GRAY_6, WHITE } from "src/theme";

const useStyles = makeStyles((theme) => ({
  content: {
    left: "50%",
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(2),
    position: "absolute",
    transform: "translate(-50%)",
    width: theme.spacing(67),
  },
  contentInner: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(4),
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(2),
  },
  card: {
    background: WHITE,
    border: `1px solid ${GRAY_6}`,
    borderRadius: "5px",
    padding: theme.spacing(5),
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    rowGap: theme.spacing(2),
  },
  buttonBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "relative",
  },
  buttonBarUpdate: {
    justifyContent: "flex-end",
  },
  cardButton: {
    left: "50%",
    position: "absolute",
    top: "-52px",
    translate: "-50% 0",
  },
}));

type Props = {
  handleChange: CompCycleDataChangeHandler;
  onHierarchyView: () => unknown;
  onConfigureHome: () => unknown;
  updateStep: (step: number) => unknown;
};

export function CompCycleConfigurationButtonBar({
  handleChange,
  onHierarchyView,
  onConfigureHome,
  updateStep,
}: Props): JSX.Element {
  const classes = useStyles();
  const { trackEvent } = useTrack();
  const intercom = useIntercom();

  const handleHierarchyViewButton = () => {
    trackEvent({
      object: "Configure Approval Steps Button",
      action: "Clicked",
    });
    intercom.trackEvent("Phases Configure Approval Steps Button Clicked");

    onHierarchyView();
  };

  const handleBack = () => {
    // reset the phases state
    handleChange("phasesData", []);
    // close the org chart and go to the configuration page
    onConfigureHome();
    updateStep(1);
  };

  const handleSave = () => {
    trackEvent({
      object: "Save Configuration Button",
      action: "Clicked",
    });
    intercom.trackEvent("Phases Save Configuration Button Clicked");

    onConfigureHome();
    updateStep(3);
  };

  return (
    <div className={classes.buttonBar}>
      <AssembleButton
        onClick={handleBack}
        label="Back"
        size="medium"
        variant="text"
      />
      <AssembleButton
        onClick={handleHierarchyViewButton}
        label="Configure Approval Steps"
        size="medium"
        variant="contained"
        className={classes.cardButton}
      />
      <AssembleButton
        onClick={handleSave}
        label="Skip this step"
        size="medium"
        variant="text"
      />
    </div>
  );
}
