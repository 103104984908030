import { gql } from "@apollo/client";
import { isEmptyString } from "@asmbl/shared/utils";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { AssembleTypography } from "src/components/AssembleTypography";
import { NumberInput } from "src/components/Form/NumberInput";
import { YELLOW, YELLOW_2 } from "src/theme";
import { useBonusGuidanceAndBudget } from "../useBonusGuidanceAndBudget";

const useStyles = makeStyles((theme) => ({
  dialogHeader: {
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogTitle: {
    display: "flex",
    flexDirection: "column",
  },
  dialogContent: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),

    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(3),
  },
  contentFocused: {
    background: `linear-gradient(148.88deg, ${YELLOW}1A 29.51%, ${YELLOW_2}1A 85.88%)`,
  },
  inputField: {
    width: "50%",
  },
  dialogActions: {
    paddingRight: 0,
    paddingBottom: theme.spacing(0.5),
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),
  },
}));

type Props = { isOpen: boolean; isFocused: boolean; handleClose: () => void };

export function CompanyAttainmentSettingsModal({
  isOpen,
  isFocused,
  handleClose,
}: Props): JSX.Element {
  const classes = useStyles();
  const {
    updateOrganizationCompCyclePerfRating,
    organizationCompCyclePerfRating,
    matrices: { selectedMatrix, onToggleDimension },
  } = useBonusGuidanceAndBudget();
  const initValue =
    organizationCompCyclePerfRating != null
      ? Number.parseFloat(organizationCompCyclePerfRating)
      : null;

  const [payout, setPayout] = useState<number | null>(initValue);

  const handleSave = async () => {
    if (payout == null) return;

    await updateOrganizationCompCyclePerfRating(payout.toString());

    handleClose();
  };

  const handleCancel = () => {
    setPayout(initValue);

    const isDimensionEnabled =
      selectedMatrix?.bonusCompany.bonusGuidanceSettings?.isDimensionEnabled ===
      true;
    const bgSettingsId = selectedMatrix?.bonusCompany.bonusGuidanceSettings?.id;

    if (bgSettingsId == null) return;

    if (
      isDimensionEnabled &&
      (initValue == null || isEmptyString(initValue.toString()))
    ) {
      onToggleDimension(bgSettingsId, false);
    }

    handleClose();
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm">
      <div className={classes.dialogHeader}>
        <div className={classes.dialogTitle}>
          <AssembleTypography variant="h4">
            Company Attainment
          </AssembleTypography>
          <AssembleTypography variant="productParagraphMedium">
            This is the bonus percentage payout connected to company attainment
            goals.
          </AssembleTypography>
        </div>
      </div>
      <Divider />
      <DialogContent
        className={clsx(classes.dialogContent, {
          [classes.contentFocused]: isFocused,
        })}
      >
        <NumberInput
          className={classes.inputField}
          label="Payout %"
          value={payout}
          onValueChange={(e) =>
            setPayout(e.floatValue === undefined ? null : e.floatValue)
          }
          allowNegative={false}
          endAdornment={payout == null ? undefined : "%"}
        />
        <AssembleTypography variant="productParagraphMedium">
          This is <strong>used globally throughout</strong> all of your created
          bonus guidance populations. You can turn it off and on for each
          individual population.
        </AssembleTypography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <AssembleButton
          variant="text"
          color="primary"
          label="Cancel"
          size="medium"
          onClick={handleCancel}
        />
        <AssembleButton
          disabled={payout == null || isEmptyString(payout.toString())}
          variant="contained"
          color="primary"
          size="medium"
          label="Save"
          onClick={handleSave}
        />
      </DialogActions>
    </Dialog>
  );
}

CompanyAttainmentSettingsModal.fragments = {
  compCycle: gql`
    fragment CompanyAttainmentSettingsModal_compCycle on CompCycle2 {
      id
      organizationCompCyclePerfRating
    }
  `,
};
