import { money, Money, zero } from "@asmbl/shared/money";
import { cx } from "@emotion/css";
import {
  InputAdornment,
  makeStyles,
  MenuItem,
  Select,
  TableCell,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import NumberFormat, {
  NumberFormatValues,
  SourceInfo,
} from "react-number-format";
import { ChevronDownIcon } from "src/components/AssembleIcons/Brand/ChevronDownIcon";
import { CurrencyCode } from "../../../__generated__/graphql";
import { currencySymbol } from "../../../models/Currency";
import {
  GRAY_10,
  GRAY_4,
  GRAY_6,
  GRAY_8,
  PURPLE_2,
  WHITE,
} from "../../../theme";
import { AssembleTypography } from "../../AssembleTypography";
import { useCurrencies } from "../../CurrenciesContext";
import { DogEarWithTooltip } from "./DogEarWithTooltip";

// GraphQL doesn't support sending numbers larger than 2^31, so we have to
// limit users from entering numbers larger than that.
const MAX_NUM = 2 ** 31 - 1;

export enum InputOption {
  PERCENT_OF_SALARY,
  CASH_VALUE,
  PERCENT_OF_TARGET,
}

interface Props {
  onChange: (
    newValue: Money | undefined,
    percentValue: number | undefined,
    option: InputOption
  ) => unknown;
  value: Money | null;
  baseValue: Money | null;
  inputOptions: { label: string; value: InputOption; disabled?: boolean }[];
  defaultInputOption: InputOption;
  payCurrencyCode: CurrencyCode | null;
  disabled?: boolean;
  hasUnpublishedChanges?: boolean;
}

const useStyles = makeStyles(() => ({
  inputCell: {
    position: "relative",
    "&$inputCell": {
      padding: 0,
    },
  },
  inputComponent: {
    borderRadius: 0,
    border: `1px solid ${GRAY_6}`,
    borderBottom: "none",
    boxShadow: "none",
    transition: "none",
    height: "2.6rem",
    fontSize: "0.8125rem",
    "&:hover": {
      background: GRAY_8,
      borderColor: GRAY_6,
      borderBottom: "none",
      transition: "none",
    },
    "&:focus-within, &.Mui-focused": {
      borderColor: "transparent",
      borderBottom: "none",
      boxShadow: "none",
      outline: `2px solid ${PURPLE_2}`,
      outlineOffset: "-2px",
      transition: "none",

      "& $symbolAdornment": {
        color: PURPLE_2,
      },
    },
  },

  hideLBorder: {
    borderLeft: "none",
    "&:hover": {
      borderLeft: "none",
    },
  },
  inputContainer: {
    display: "flex",
    justifyContent: "flex-end",
    height: "2.6rem",
  },
  cashRoot: {
    flex: "1 0 0",
  },
  percentRoot: {
    flex: "1 0 0",
  },
  input: {
    paddingBlock: 0,
    textAlign: "right",
  },
  symbolAdornment: {
    color: GRAY_4,
    fontSize: "0.875rem",
  },
  dropDownRoot: {
    border: `1px solid ${GRAY_6}`,
    borderBottom: "none",
    background: `${WHITE}`,
    padding: 0,
    flex: "1 0 0",

    "&:hover": {
      background: GRAY_8,
      borderColor: GRAY_6,
      transition: "none",
    },
    "&:focus-within, &.Mui-focused": {
      borderColor: "transparent",
      borderBottom: "none",
      boxShadow: "none",
      outline: `2px solid ${PURPLE_2}`,
      outlineOffset: "-2px",
      transition: "none",
    },
  },
  dropDownSelect: {
    paddingLeft: ".75rem",
    fontSize: "0.8125rem",
    "&:hover": {
      background: GRAY_8,
    },

    "&:focus-within, &.Mui-focused": {
      background: GRAY_8,
      boxShadow: "none",
      outlineOffset: "-3px",
    },
  },
  disabledSymbolAdornment: {
    color: GRAY_10,
    fontSize: "0.875rem",
  },
  dropDownIcon: {
    top: "calc(50% - 8px)",
    height: "16px",
    right: "16px",
  },
  disabledComponent: {
    borderRadius: 0,
    border: `1px solid ${GRAY_6}`,
    borderBottom: "none",
    boxShadow: "none",
    transition: "none",
    height: "2.6rem",
    fontSize: "0.8125rem",
    borderColor: GRAY_6,

    "&:hover": {
      borderColor: GRAY_6,
      borderBottom: "none",
      transition: "none",
    },
  },
  percentInputComponent: {
    borderLeft: "none",
    "&:hover": {
      borderLeft: "none",
    },
  },
}));

export function ConfigurableSalaryInputCell({
  value,
  baseValue,
  onChange,
  inputOptions,
  defaultInputOption,
  payCurrencyCode,
  hasUnpublishedChanges = false,
  disabled = false,
}: Props): JSX.Element {
  const classes = useStyles();
  const { defaultCurrencyCode } = useCurrencies();
  const currency =
    value?.currency ??
    baseValue?.currency ??
    payCurrencyCode ??
    defaultCurrencyCode;
  const absolute = value ?? undefined;

  const [inputUnit, setInputUnit] = useState(defaultInputOption);
  const [userClearedInput, setUserClearedInput] = useState(false);

  const absoluteInput = absolute?.value;
  const percentInput =
    absolute !== undefined
      ? calculatePercent(baseValue, absolute.value)
      : undefined;

  const handleAbsoluteChange = (
    floatValue: number | undefined,
    inputOption: InputOption
  ) => {
    if (floatValue === undefined) {
      // item value gets updated to 0, while input displays placeholder
      setUserClearedInput(true);
      return onChange(
        value == null ? undefined : zero(currency),
        value == null ? undefined : 0,
        inputUnit
      );
    } else {
      setUserClearedInput(false);
      return onChange(money(floatValue, currency), undefined, inputOption);
    }
  };

  const handlePercentChange = (
    floatValue: number | undefined,
    inputOption: InputOption
  ) => {
    if (floatValue === undefined) {
      // item value gets updated to 0, while input displays placeholder
      setUserClearedInput(true);
      return onChange(
        value == null ? undefined : zero(currency),
        value == null ? undefined : 0,
        inputUnit
      );
    } else {
      setUserClearedInput(false);
      return onChange(undefined, floatValue, inputOption);
    }
  };

  const handleAbsolute = (
    { floatValue }: NumberFormatValues,
    { source }: SourceInfo
  ) => {
    if (source !== "event") return;
    handleAbsoluteChange(floatValue, inputUnit);
  };

  const handlePercent = (
    { floatValue }: NumberFormatValues,
    { source }: SourceInfo
  ) => {
    if (source !== "event") return;
    handlePercentChange(floatValue, inputUnit);
  };

  const handleSelectedInput = (option: InputOption) => {
    setInputUnit(option);
    return option === InputOption.CASH_VALUE
      ? handleAbsoluteChange(absoluteInput, option)
      : handlePercentChange(percentInput, option);
  };

  return (
    <>
      <TableCell role="gridcell" className={classes.inputCell} colSpan={3}>
        <div className={classes.inputContainer}>
          <Select
            className={classes.dropDownRoot}
            classes={{
              select: classes.dropDownSelect,
              icon: classes.dropDownIcon,
            }}
            variant="standard"
            value={inputUnit}
            onChange={(e) => handleSelectedInput(e.target.value as InputOption)}
            IconComponent={AssembleIconComponent}
            displayEmpty
            disableUnderline
            disabled={disabled}
          >
            {inputOptions.map(({ value, label, disabled = false }) => {
              return disabled ? (
                <Tooltip
                  key={value}
                  title="This employee doesn't have a Current Target Recurring Bonus."
                  placement="right"
                >
                  <span
                    onClick={() => {
                      /* Do nothing. We need this because the tooltip would
                      otherwise allow this menu option to be selected. */
                    }}
                  >
                    <MenuItem key={value} value={value} disabled={disabled}>
                      <AssembleTypography variant="productSmaller">
                        {label}
                      </AssembleTypography>
                    </MenuItem>
                  </span>
                </Tooltip>
              ) : (
                <MenuItem key={value} value={value}>
                  <AssembleTypography variant="productSmaller">
                    {label}
                  </AssembleTypography>
                </MenuItem>
              );
            })}
          </Select>
          <>
            {hasUnpublishedChanges && <DogEarWithTooltip />}
            {inputUnit === InputOption.CASH_VALUE ? (
              <CashInput
                cashInput={userClearedInput ? undefined : absoluteInput}
                handleChange={handleAbsolute}
                currency={currency}
                hideLBorder
                disabled={disabled}
              />
            ) : (
              <PercentInput
                percentInput={userClearedInput ? undefined : percentInput}
                handleChange={handlePercent}
                hideLBorder
                disabled={disabled}
              />
            )}
          </>
        </div>
      </TableCell>
    </>
  );
}

function calculatePercent(baseValue: Money | null, absolute: number): number {
  return baseValue && baseValue.value !== 0
    ? Math.round((absolute / baseValue.value) * 10000) / 100
    : 0;
}

function AssembleIconComponent(props: Record<string, unknown>) {
  return (
    <div {...props}>
      <ChevronDownIcon color={GRAY_4} />
    </div>
  );
}

export function CashInput({
  cashInput,
  handleChange,
  currency,
  disabled = false,
  hideLBorder = false,
  isHourly = false,
}: {
  cashInput: number | undefined;
  handleChange: (value: NumberFormatValues, info: SourceInfo) => void;
  currency: CurrencyCode;
  disabled?: boolean;
  hideLBorder?: boolean;
  isHourly?: boolean;
}): JSX.Element {
  const classes = useStyles();
  const cashIsAllowed = (n: NumberFormatValues) =>
    n.floatValue === undefined || n.floatValue <= MAX_NUM;

  return (
    <NumberFormat
      customInput={TextField}
      variant="outlined"
      decimalScale={isHourly ? 2 : 0}
      onValueChange={handleChange}
      value={cashInput === undefined ? "" : cashInput}
      placeholder={disabled ? "" : "0"}
      className={classes.cashRoot}
      InputProps={{
        className: cx(classes.inputComponent, {
          [classes.disabledComponent]: disabled,
          [classes.hideLBorder]: hideLBorder,
        }),
        startAdornment: (
          <Typography
            className={
              disabled
                ? classes.disabledSymbolAdornment
                : classes.symbolAdornment
            }
          >
            {currencySymbol(currency)}
          </Typography>
        ),
        endAdornment: isHourly ? (
          <InputAdornment position="end">/hr</InputAdornment>
        ) : (
          <></>
        ),
      }}
      inputProps={{ className: classes.input, "data-cy": "cash-input-cell" }}
      thousandSeparator
      isAllowed={cashIsAllowed}
      allowLeadingZeros={false}
      disabled={disabled}
    />
  );
}

export function PercentInput({
  percentInput,
  handleChange,
  disabled = false,
  hideLBorder = false,
}: {
  percentInput: number | undefined;
  handleChange: (value: NumberFormatValues, info: SourceInfo) => void;
  disabled?: boolean;
  hideLBorder?: boolean;
}): JSX.Element {
  const classes = useStyles();
  return (
    <NumberFormat
      customInput={TextField}
      variant="outlined"
      onValueChange={handleChange}
      value={percentInput === undefined ? "" : percentInput}
      placeholder={disabled ? "" : "0"}
      className={classes.percentRoot}
      InputProps={{
        className: cx(classes.inputComponent, classes.percentInputComponent, {
          [classes.disabledComponent]: disabled,
          [classes.hideLBorder]: hideLBorder,
        }),
        endAdornment: (
          <Typography
            className={
              disabled
                ? classes.disabledSymbolAdornment
                : classes.symbolAdornment
            }
          >
            &nbsp;%
          </Typography>
        ),
      }}
      inputProps={{ className: classes.input }}
      thousandSeparator
      disabled={disabled}
    />
  );
}
