import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { CheckIcon } from "src/components/AssembleIcons/Brand/CheckIcon";
import { DV_YELLOW_LIGHT, GRAY_2, WARN_TEXT } from "../../../theme";
import { withMinWait } from "../../../utils";
import { AssembleTypography } from "../../AssembleTypography";
import { AllCompleteReviewDialogState } from "./CompleteReviewDialog";

const useStyles = makeStyles((theme) => ({
  dialogBody: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: GRAY_2,
  },
  boldedText: {
    fontWeight: 500,
  },
  dialogWarning: {
    backgroundColor: DV_YELLOW_LIGHT,
    borderRadius: "8px",
    display: "flex",
    marginTop: theme.spacing(1),
  },
  dialogWarningContent: {
    padding: theme.spacing(1, 2),
    display: "flex",
  },
  dialogWarningText: {
    paddingLeft: theme.spacing(2),
  },
  dialogWarningIcon: {
    marginTop: theme.spacing(1),
  },
}));

type CompleteReviewDialogProps = {
  setDialogState: (state: AllCompleteReviewDialogState) => void;
  onSubmit: () => Promise<boolean>;
  reviewCount: number | null;
};

export function CompleteReviewDialogOpen({
  setDialogState,
  reviewCount,
  onSubmit,
}: CompleteReviewDialogProps): JSX.Element {
  const classes = useStyles();

  const handleSubmit = async () => {
    setDialogState("pending");
    const result = await withMinWait(onSubmit, 1000);
    setDialogState(result ? "success" : "error");
  };

  return (
    <Dialog open={true}>
      <DialogTitle>
        You're about to accept all changes and close this comp cycle
      </DialogTitle>
      <DialogContent>
        <AssembleTypography
          variant="productDialogBody"
          className={classes.dialogBody}
        >
          <Typography component="span" className={classes.boldedText}>
            Important:
          </Typography>{" "}
          Once accepted and closed, this cannot be undone.
        </AssembleTypography>
        <AssembleTypography
          variant="productDialogBody"
          className={classes.dialogBody}
        >
          This will lock in all the requests and stop anyone from submitting
          further reviews. We recommend you do this once your comp cycle process
          is completed to maintain historical accuracy and prevent further
          changes.
        </AssembleTypography>
        {reviewCount !== null && reviewCount > 0 && (
          <div className={classes.dialogWarning}>
            <div className={classes.dialogWarningContent}>
              <CheckIcon
                className={classes.dialogWarningIcon}
                width="24px"
                height="24px"
                color={WARN_TEXT}
              />
              <AssembleTypography
                variant="productDialogBody"
                className={`${classes.dialogWarningText} ${classes.dialogBody}`}
              >
                This cycle has {reviewCount} pending{" "}
                {reviewCount === 1 ? "change" : "changes"}.{" "}
                <Typography component="span" className={classes.boldedText}>
                  {reviewCount === 1 ? "It" : "They"} will be marked as accepted
                  upon completion.
                </Typography>{" "}
                You can go back and make any changes you need to before closing
                the cycle.
              </AssembleTypography>
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => setDialogState("closed")}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          id="modal-complete-comp-cycle-button"
        >
          Approve and Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
