import { FetchResult, gql, useLazyQuery } from "@apollo/client";
import { Rule, RuleSet } from "@asmbl/shared/eligibility";
import { roundNumber } from "@asmbl/shared/utils";
import { cloneDeep, isEqual } from "lodash";
import { useSnackbar } from "notistack";
import { createContext, useContext, useEffect, useState } from "react";
import {
  BonusGuidanceAndBudgetProviderQuery,
  BonusGuidanceAndBudgetProviderQueryVariables,
  BuildEmptyMatrix,
  CustomDimensionValueType,
  DeleteMatrix,
  groupBonusGuidanceMatrices_matrix,
  MatrixTypeEnum,
  UpdateBonusGuidanceSettings,
  UpdateBonusGuidanceSettingsInput,
  UpdateMatrixEligibilityRules,
  UpdateMatrixEligibilityRulesInput,
  UpdateMatrixName,
  UpdateMatrixNameInput,
} from "src/__generated__/graphql";
import {
  useUpdateCompCycleBonusGuidancePreferences,
  useUpdateOrganizationCompCyclePerfScore,
} from "src/mutations/CompCycle";
import {
  useBuildEmptyMatrix,
  useCalculatePotentialParticipants,
  useSaveMatrix,
  useUpdateMatrixEligibilityRules,
  useUpdateMatrixName,
} from "src/mutations/Matrix";
import { noop } from "src/test-helpers";
import {
  GuidanceEligibilityPool,
  UseMatrixMatrix,
} from "../../MultipleMeritMatrices/useMultipleMatrices";
import { matrixToUpdateArgs } from "../../MultipleMeritMatrices/utils";
import { groupBonusGuidanceMatrices } from "../utils";

export type BonusGuidanceSettings = {
  id?: number;
  isDimensionEnabled?: boolean;
  customDimensionName?: string | null;
  customDimensionValueType?: CustomDimensionValueType;
};

export type BonusGuidancePopulation = {
  name: string;
  selected: boolean;
  edited: boolean;
  eligibilityRules: RuleSet | null;
  eligibleParticipants: number[] | null;
  eligibilityValidations: {
    duplicates: number[];
  };
  bonusIndividual: UseMatrixMatrix;
  bonusCompany: UseMatrixMatrix;
  bonusOther: UseMatrixMatrix;
};

export const BonusGuidanceAndBudgetContext = createContext<{
  meta: {
    loading: boolean;
    pageEdited: boolean;
    setPageEdited: (pageEdited: boolean) => void;
  };
  allEligible: GuidanceEligibilityPool[];
  matrices: {
    selectedMatrix: BonusGuidancePopulation | null;
    createMatrix: () => void;
    setSelectedMatrix: (index: number) => void;
    meritMatrices: BonusGuidancePopulation[];
    updateName: (name: string) => void;
    onToggleDimension: (bgSettingsId: number, isEnabled: boolean) => void;
    onChangeDimensionSettings: (
      bgSettingsId: number,
      settings: {
        customDimensionName?: string;
        customDimensionValueType?: CustomDimensionValueType;
      }
    ) => void;
  };
  rules: {
    addRule: () => void;
    addEmailRule: () => void;
    updateRule: (index: number) => (rule: Rule) => void;
    removeRule: (index: number) => void;
  };
  modals: {
    guidancePopulationSettings: {
      isOpen: boolean;
      isFocused: boolean;
      setOpen: (open: boolean) => void;
    };
    companyAttainment: {
      isOpen: boolean;
      isFocused: boolean;
      setOpen: (open: boolean) => void;
    };
  };
  shouldPrefillWithGuidance: boolean;
  onToggleShouldPrefillWithGuidance: (shouldPrefill: boolean) => Promise<void>;
  organizationCompCyclePerfRating: string | null;
  updateOrganizationCompCyclePerfRating: (rating: string) => Promise<void>;
  onUpdateGuidanceRange: (
    rangeId: number | string,
    newRangeStart: number
  ) => void;
  onUpdateMatrixGuide: (props: {
    newValue: string;
    currentValue: string | number | null;
    matrixGuideId?: number | null | string;
    matrixId: number;
    perfRatingOptionId?: number | string;
  }) => void;
  persistChanges: (
    createEmptyMatrices: () => Promise<FetchResult<BuildEmptyMatrix>>,
    updateMatrixName: (
      data: UpdateMatrixNameInput
    ) => Promise<FetchResult<UpdateMatrixName>>,
    updateMatrixEligibilityRules: (
      data: UpdateMatrixEligibilityRulesInput
    ) => Promise<FetchResult<UpdateMatrixEligibilityRules>>,
    updateBonusGuidanceSettings: (
      data: UpdateBonusGuidanceSettingsInput
    ) => Promise<FetchResult<UpdateBonusGuidanceSettings>>
  ) => Promise<void>;
  persistDelete: (
    deleteMatrix: (matrixId: number) => Promise<FetchResult<DeleteMatrix>>
  ) => Promise<void>;
}>({
  meta: {
    loading: false,
    pageEdited: false,
    setPageEdited: noop,
  },
  allEligible: [],
  matrices: {
    selectedMatrix: null,
    createMatrix: noop,
    setSelectedMatrix: noop,
    meritMatrices: [],
    updateName: noop,
    onToggleDimension: noop,
    onChangeDimensionSettings: noop,
  },
  rules: {
    addRule: noop,
    addEmailRule: noop,
    updateRule: () => noop,
    removeRule: noop,
  },
  modals: {
    guidancePopulationSettings: {
      isOpen: false,
      isFocused: false,
      setOpen: noop,
    },
    companyAttainment: {
      isOpen: false,
      isFocused: false,
      setOpen: noop,
    },
  },
  shouldPrefillWithGuidance: false,
  onToggleShouldPrefillWithGuidance: () => Promise.resolve(),
  organizationCompCyclePerfRating: null,
  updateOrganizationCompCyclePerfRating: () => Promise.resolve(),
  onUpdateGuidanceRange: noop,
  onUpdateMatrixGuide: noop,
  persistChanges: () => Promise.resolve(),
  persistDelete: () => Promise.resolve(),
});

export function useBonusGuidanceAndBudget() {
  return useContext(BonusGuidanceAndBudgetContext);
}

export function BonusGuidanceAndBudgetProvider({
  compCycleId,
  children,
  initValue,
}: {
  compCycleId: number;
  children: React.ReactNode;
  initValue?: {
    shouldPrefillWithGuidance?: boolean;
    allEligible?: GuidanceEligibilityPool[];
    meritMatrices?: BonusGuidancePopulation[];
    totalEligible?: number;
    organizationCompCyclePerfRating?: string | null;
  };
}): JSX.Element {
  const [rehydrate] = useLazyQuery<
    BonusGuidanceAndBudgetProviderQuery,
    BonusGuidanceAndBudgetProviderQueryVariables
  >(BonusGuidanceAndBudgetProvider.query, {
    variables: {
      compCycleId,
      types: [
        MatrixTypeEnum.BONUS_GUIDANCE_INDIVIDUAL_PERFORMANCE,
        MatrixTypeEnum.BONUS_GUIDANCE_COMPANY_PERFORMANCE,
        MatrixTypeEnum.BONUS_GUIDANCE_CUSTOM_PERFORMANCE,
      ],
    },
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loading) {
      document.body.style.cursor = "wait";
    } else {
      document.body.style.cursor = "default";
    }

    return () => {
      document.body.style.cursor = "default";
    };
  }, [loading]);

  const [pageEdited, setPageEdited] = useState(false);

  const { execute: calculateTotalEligible } =
    useCalculatePotentialParticipants();

  const [modals, setModals] = useState({
    guidancePopulationSettings: {
      isOpen: false,
      isFocused: false,
      setOpen: noop,
    },
    companyAttainmentModal: {
      isOpen: false,
      isFocused: false,
      setOpen: noop,
    },
  });

  const [allEligible] = useState<GuidanceEligibilityPool[]>(
    initValue?.allEligible ?? []
  );

  const [shouldPrefillWithGuidance, setShouldPrefillWithGuidance] =
    useState<boolean>(initValue?.shouldPrefillWithGuidance ?? false);

  const [organizationCompCyclePerfRating, setOrganizationCompCyclePerfRating] =
    useState<string | null>(initValue?.organizationCompCyclePerfRating ?? null);

  const [meritMatrices, setMeritMatrices] = useState<BonusGuidancePopulation[]>(
    initValue?.meritMatrices ?? [
      {
        name: "Guidance Population 1",
        selected: true,
        edited: false,
        eligibilityRules: [[]],
        eligibleParticipants: null,
        eligibilityValidations: {
          duplicates: [],
        },
        bonusIndividual: {},
        bonusCompany: {},
        bonusOther: {},
      },
    ]
  );

  const updateCompCycleBonusGuidancePreferences =
    useUpdateCompCycleBonusGuidancePreferences(compCycleId);
  const buildEmptyMatrix = useBuildEmptyMatrix(compCycleId);
  const updateMatrixNameHook = useUpdateMatrixName();
  const updateMatrixEligibilityRulesHook = useUpdateMatrixEligibilityRules();
  const updateOrganizationCompCyclePerfRatingHook =
    useUpdateOrganizationCompCyclePerfScore(compCycleId);

  const saveMatrix = useSaveMatrix();
  const { enqueueSnackbar } = useSnackbar();

  const refresh = async () => {
    const persisted = await rehydrate();
    const currentSelectedMatrixIndex = meritMatrices.findIndex(
      (matrix) => matrix.selected
    );

    setMeritMatrices(
      groupBonusGuidanceMatrices(persisted.data?.compCycle2?.matrices ?? [])
    );

    setSelectedMatrix(currentSelectedMatrixIndex);
  };

  const selectedMatrix =
    meritMatrices.find((matrix) => matrix.selected) ?? null;

  const setSelectedMatrix = (index: number) => {
    setMeritMatrices((prev) => {
      const newMatrices = [
        ...prev.map((matrix) => ({ ...matrix, selected: false })),
      ];
      newMatrices[index] = { ...newMatrices[index], selected: true };
      return newMatrices;
    });
  };

  const createMatrix = async () => {
    if (selectedMatrix?.eligibilityRules?.length === 0) {
      enqueueSnackbar(
        "Please add eligibility rules before creating a new guidance population",
        { variant: "error" }
      );

      return;
    }
    setLoading(true);
    setPageEdited(true);

    const newMatrix = await buildEmptyMatrix([
      MatrixTypeEnum.BONUS_GUIDANCE_INDIVIDUAL_PERFORMANCE,
      MatrixTypeEnum.BONUS_GUIDANCE_COMPANY_PERFORMANCE,
      MatrixTypeEnum.BONUS_GUIDANCE_CUSTOM_PERFORMANCE,
    ]);

    const newGroupedMatrices = groupBonusGuidanceMatrices([
      newMatrix.data?.buildEmptyMatrix[0] as groupBonusGuidanceMatrices_matrix,
      newMatrix.data?.buildEmptyMatrix[1] as groupBonusGuidanceMatrices_matrix,
      newMatrix.data?.buildEmptyMatrix[2] as groupBonusGuidanceMatrices_matrix,
    ]);

    setMeritMatrices((prev) => [...prev, newGroupedMatrices[0]]);
    setSelectedMatrix(meritMatrices.length);
    setLoading(false);
  };

  const updateName = (name: string) => {
    setPageEdited(true);
    setMeritMatrices((prev) => {
      if (selectedMatrix == null) return prev;

      const newMatrix = {
        ...selectedMatrix,
        name,
      };

      return prev.map((matrix) =>
        matrix.selected === true ? newMatrix : matrix
      );
    });
  };

  const onChangeDimensionSettings = (
    bgSettingsId: number,
    settings: {
      customDimensionName?: string;
      customDimensionValueType?: CustomDimensionValueType;
    }
  ) => {
    setPageEdited(true);

    setMeritMatrices((prev) => {
      if (selectedMatrix == null) return prev;

      const updatedMatrix = cloneDeep(selectedMatrix);

      updatedMatrix.bonusIndividual =
        bgSettingsId ===
        selectedMatrix.bonusIndividual.bonusGuidanceSettings?.id
          ? {
              ...selectedMatrix.bonusIndividual,
              bonusGuidanceSettings: {
                ...selectedMatrix.bonusIndividual.bonusGuidanceSettings,
                ...settings,
              },
            }
          : selectedMatrix.bonusIndividual;

      updatedMatrix.bonusCompany =
        bgSettingsId === selectedMatrix.bonusCompany.bonusGuidanceSettings?.id
          ? {
              ...selectedMatrix.bonusCompany,
              bonusGuidanceSettings: {
                ...selectedMatrix.bonusCompany.bonusGuidanceSettings,
                ...settings,
              },
            }
          : selectedMatrix.bonusCompany;

      updatedMatrix.bonusOther =
        bgSettingsId === selectedMatrix.bonusOther.bonusGuidanceSettings?.id
          ? {
              ...selectedMatrix.bonusOther,
              bonusGuidanceSettings: {
                ...selectedMatrix.bonusOther.bonusGuidanceSettings,
                ...settings,
              },
            }
          : selectedMatrix.bonusOther;

      updatedMatrix.edited = true;

      return prev.map((matrix) =>
        matrix.selected === true ? updatedMatrix : matrix
      );
    });
  };

  const onToggleDimension = (bgSettingsId: number, isEnabled: boolean) => {
    setPageEdited(true);

    setMeritMatrices((prev) => {
      if (selectedMatrix == null) return prev;

      const updatedMatrix = cloneDeep(selectedMatrix);

      updatedMatrix.bonusCompany =
        bgSettingsId === selectedMatrix.bonusCompany.bonusGuidanceSettings?.id
          ? {
              ...selectedMatrix.bonusCompany,
              bonusGuidanceSettings: {
                ...selectedMatrix.bonusCompany.bonusGuidanceSettings,
                isDimensionEnabled: isEnabled,
              },
            }
          : selectedMatrix.bonusCompany;

      updatedMatrix.bonusIndividual =
        bgSettingsId ===
        selectedMatrix.bonusIndividual.bonusGuidanceSettings?.id
          ? {
              ...selectedMatrix.bonusIndividual,
              bonusGuidanceSettings: {
                ...selectedMatrix.bonusIndividual.bonusGuidanceSettings,
                isDimensionEnabled: isEnabled,
              },
            }
          : selectedMatrix.bonusIndividual;

      updatedMatrix.bonusOther =
        bgSettingsId === selectedMatrix.bonusOther.bonusGuidanceSettings?.id
          ? {
              ...selectedMatrix.bonusOther,
              bonusGuidanceSettings: {
                ...selectedMatrix.bonusOther.bonusGuidanceSettings,
                isDimensionEnabled: isEnabled,
              },
            }
          : selectedMatrix.bonusOther;

      updatedMatrix.edited = true;

      return prev.map((matrix) =>
        matrix.selected === true ? updatedMatrix : matrix
      );
    });
  };

  const persistChanges = async (
    createEmptyMatrices: (
      types: MatrixTypeEnum[]
    ) => Promise<FetchResult<BuildEmptyMatrix>> = buildEmptyMatrix,
    updateMatrixName: (
      data: UpdateMatrixNameInput
    ) => Promise<FetchResult<UpdateMatrixName>> = updateMatrixNameHook,
    updateMatrixEligibilityRules: (
      data: UpdateMatrixEligibilityRulesInput
    ) => Promise<
      FetchResult<UpdateMatrixEligibilityRules>
    > = updateMatrixEligibilityRulesHook,
    updateBonusGuidanceSettings: (
      data: UpdateBonusGuidanceSettingsInput
    ) => Promise<FetchResult<UpdateBonusGuidanceSettings>>
  ) => {
    setLoading(true);

    try {
      await Promise.all(
        meritMatrices.map(async (matrix) => {
          const matrixIds =
            matrix.bonusIndividual.id != null &&
            matrix.bonusCompany.id != null &&
            matrix.bonusOther.id != null
              ? [
                  matrix.bonusIndividual.id,
                  matrix.bonusCompany.id,
                  matrix.bonusOther.id,
                ]
              : await (async () => {
                  const { data } = await createEmptyMatrices([
                    MatrixTypeEnum.BONUS_GUIDANCE_INDIVIDUAL_PERFORMANCE,
                    MatrixTypeEnum.BONUS_GUIDANCE_COMPANY_PERFORMANCE,
                    MatrixTypeEnum.BONUS_GUIDANCE_CUSTOM_PERFORMANCE,
                  ]);

                  if (!data) {
                    throw new Error("Failed to create empty matrices");
                  }

                  return data.buildEmptyMatrix.map((m) => m.id);
                })();

          await Promise.all(
            matrixIds.map(async (matrixId) => {
              await updateMatrixName({ matrixId, name: matrix.name });

              if (matrix.eligibilityRules) {
                await updateMatrixEligibilityRules({
                  matrixId,
                  rules: matrix.eligibilityRules,
                });
              }
            })
          );

          if (matrix.edited) {
            // Saving these in sequence because they share some values and we
            // don't want to create dupes
            const bonusIndividual = await saveMatrix(
              matrixToUpdateArgs(matrix.bonusIndividual)
            );

            const bonusIndividualId = bonusIndividual.data?.updateMatrix.id;

            if (bonusIndividualId != null) {
              await updateBonusGuidanceSettings({
                matrixId: bonusIndividualId,
                isDimensionEnabled:
                  matrix.bonusIndividual.bonusGuidanceSettings
                    ?.isDimensionEnabled,
              });
            }

            const bonusCompany = await saveMatrix(
              matrixToUpdateArgs(matrix.bonusCompany)
            );

            const bonusCompanyId = bonusCompany.data?.updateMatrix.id;

            if (bonusCompanyId != null) {
              await updateBonusGuidanceSettings({
                matrixId: bonusCompanyId,
                isDimensionEnabled:
                  matrix.bonusCompany.bonusGuidanceSettings?.isDimensionEnabled,
              });
            }

            const bonusOther = await saveMatrix(
              matrixToUpdateArgs(matrix.bonusOther)
            );

            const bonusOtherId = bonusOther.data?.updateMatrix.id;

            if (bonusOtherId != null) {
              await updateBonusGuidanceSettings({
                matrixId: bonusOtherId,
                isDimensionEnabled:
                  matrix.bonusOther.bonusGuidanceSettings?.isDimensionEnabled,
                customDimensionName:
                  matrix.bonusOther.bonusGuidanceSettings?.customDimensionName,
                customDimensionValueType:
                  matrix.bonusOther.bonusGuidanceSettings
                    ?.customDimensionValueType,
              });
            }
          }
        })
      );

      setPageEdited(false);

      await refresh();
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const addRule = () => {
    setPageEdited(true);
    setMeritMatrices((prev) => {
      if (selectedMatrix == null) return prev;

      const newMatrix = {
        ...selectedMatrix,
        eligibilityRules: [
          ...(selectedMatrix.eligibilityRules != null
            ? selectedMatrix.eligibilityRules
            : []),
          [],
        ],
      };

      return prev.map((matrix) =>
        matrix.selected === true ? newMatrix : matrix
      );
    });
  };

  const addEmailRule = () => {
    setPageEdited(true);
    setMeritMatrices((prev) => {
      if (selectedMatrix == null) return prev;

      const newMatrix = {
        ...selectedMatrix,
        eligibilityRules: [
          ...(selectedMatrix.eligibilityRules != null
            ? selectedMatrix.eligibilityRules
            : []),
          [{ alwaysIncludeIds: [] }, { alwaysExcludeIds: [] }],
        ],
      };

      return prev.map((matrix) =>
        matrix.selected === true ? newMatrix : matrix
      );
    });
  };

  const updateRule = (index: number) => async (rule: Rule) => {
    // skip the whole update, if the rule to update is the same
    // as the current rules
    const currentRules = selectedMatrix?.eligibilityRules ?? [];
    if (currentRules.length > 0 && isEqual(currentRules[index], rule)) return;

    setPageEdited(true);
    setLoading(true);

    const updateMatrix = async (
      matrices: BonusGuidancePopulation[],
      matrix: BonusGuidancePopulation
    ): Promise<BonusGuidancePopulation> => {
      const updateRules = (m: BonusGuidancePopulation) =>
        (m.selected
          ? m.eligibilityRules?.map((r, i) => (i === index ? rule : r))
          : m.eligibilityRules) ?? null;

      const currentMatrixRules = updateRules(matrix);

      const hasRuleBeenFilled = !!(
        currentMatrixRules != null &&
        !currentMatrixRules.every((rule) =>
          rule.every((condition) =>
            Object.values(condition).every(
              (c) => c == null || (Array.isArray(c) && c.length === 0)
            )
          )
        )
      );

      const potential = hasRuleBeenFilled
        ? await calculateTotalEligible({
            compCycleId: compCycleId,
            rules: currentMatrixRules,
          })
        : undefined;

      const result: BonusGuidancePopulation = {
        ...matrix,
        eligibleParticipants:
          potential?.data?.calculatePotentialParticipants ?? null,
        eligibilityRules: currentMatrixRules,
      };

      return result;
    };

    const updatedMatrices = await Promise.all(
      meritMatrices.map(
        async (matrix) => await updateMatrix(meritMatrices, matrix)
      )
    );

    const validatedMatrices = validateMatrices(updatedMatrices);

    setLoading(false);
    setMeritMatrices(validatedMatrices);
  };

  const removeRule = async (index: number) => {
    setLoading(true);
    setPageEdited(true);
    const updateMatrix = async (matrix: BonusGuidancePopulation) => {
      const updateRules = (m: BonusGuidancePopulation) =>
        (m.selected
          ? m.eligibilityRules?.filter((_, i) => i !== index)
          : m.eligibilityRules) ?? [];

      const currentMatrixRules = updateRules(matrix);

      const updatedRules =
        (matrix.selected
          ? matrix.eligibilityRules?.filter((_, i) => i !== index)
          : matrix.eligibilityRules) ?? [];

      const potential = await calculateTotalEligible({
        compCycleId: compCycleId,
        rules: currentMatrixRules,
      });

      const result: BonusGuidancePopulation = {
        ...matrix,
        eligibleParticipants:
          potential.data?.calculatePotentialParticipants ?? null,
        eligibilityRules: updatedRules,
      };

      return result;
    };

    // we need to run through the updates twice, once to go through
    // and remove the rule, and then again to update the potential and
    // validation data for each matrix (after the rule was removed)

    const updatedMatricesToRemove = await Promise.all(
      meritMatrices.map(async (matrix) => await updateMatrix(matrix))
    );

    const validatedMatrices = validateMatrices(updatedMatricesToRemove);

    setLoading(false);

    setMeritMatrices(validatedMatrices);
  };

  const onToggleShouldPrefillWithGuidance = async (shouldApply: boolean) => {
    setLoading(true);
    try {
      await updateCompCycleBonusGuidancePreferences(shouldApply);
      setShouldPrefillWithGuidance(shouldApply);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const updateOrganizationCompCyclePerfRating = async (rating: string) => {
    try {
      await updateOrganizationCompCyclePerfRatingHook(rating);
      setOrganizationCompCyclePerfRating(rating);
    } catch (e) {
      console.error(e);
    }
  };

  const persistDelete = async (
    deleteMatrix: (matrixId: number) => Promise<FetchResult<DeleteMatrix>>
  ) => {
    if (selectedMatrix == null) {
      throw new Error("No matrix selected");
    }

    setLoading(true);

    if (selectedMatrix.bonusIndividual.id != null) {
      await deleteMatrix(selectedMatrix.bonusIndividual.id);
    }

    if (selectedMatrix.bonusCompany.id != null) {
      await deleteMatrix(selectedMatrix.bonusCompany.id);
    }

    if (selectedMatrix.bonusOther.id != null) {
      await deleteMatrix(selectedMatrix.bonusOther.id);
    }

    const matricesPostRemoval = meritMatrices.filter(
      (m) => m.selected === false
    );

    const updateMatrix = async (mtx: BonusGuidancePopulation) => {
      const potential = await calculateTotalEligible({
        compCycleId: compCycleId,
        rules: mtx.eligibilityRules ?? [],
      });

      const result: BonusGuidancePopulation = {
        ...mtx,
        eligibleParticipants:
          potential.data?.calculatePotentialParticipants ?? null,
        eligibilityRules: mtx.eligibilityRules,
      };

      return result;
    };

    const updatedMatrices = await Promise.all(
      matricesPostRemoval.map(async (matrix) => await updateMatrix(matrix))
    );

    const validatedMatrices = validateMatrices(updatedMatrices);

    setMeritMatrices(validatedMatrices);
    setSelectedMatrix(0);
    setLoading(false);
  };

  const onUpdateMatrixGuide = ({
    newValue,
    currentValue,
    matrixGuideId,
    matrixId,
    perfRatingOptionId,
  }: {
    newValue: string;
    currentValue: string | number | null;
    matrixGuideId?: number | null | string;
    matrixId: number;
    perfRatingOptionId?: number | string;
  }) => {
    if (selectedMatrix == null) {
      // This case should never happen
      return;
    }

    const updatedMatrix = cloneDeep(selectedMatrix);

    const matrixToUpdate = [
      updatedMatrix.bonusCompany,
      updatedMatrix.bonusIndividual,
      updatedMatrix.bonusOther,
    ].find((matrix) => matrix.id === matrixId);

    if (matrixToUpdate == null || matrixToUpdate.matrixGuides == null) {
      // Should never happen
      return;
    }

    // If no matrix guide exists yet, need to create one
    if (matrixGuideId == null && perfRatingOptionId != null) {
      const perfRatingOption = updatedMatrix.bonusIndividual.matrixGuides
        ?.map((guide) => guide.matrixPerfRatingOption)
        .find((perfRatingOption) => perfRatingOption.id === perfRatingOptionId);

      // If we're in this state, there should only be one
      const matrixRange = matrixToUpdate.matrixGuides[0].matrixRange;

      if (perfRatingOption == null) return;

      matrixToUpdate.matrixGuides = [
        ...matrixToUpdate.matrixGuides,
        {
          id: `new-${matrixToUpdate.matrixGuides.length + 1}`,
          percent: roundNumber(Number(newValue)),
          matrixId,
          matrixPerfRatingOptionId: perfRatingOptionId,
          matrixPerfRatingOption: perfRatingOption,
          matrixRange,
          matrixRangeId: matrixRange.id,
        },
      ];
    }

    // If a matrix guide already exists, use the following
    if (matrixGuideId != null && newValue !== currentValue) {
      const guideIndex = matrixToUpdate.matrixGuides.findIndex(
        (guide) => guide.id === matrixGuideId
      );

      matrixToUpdate.matrixGuides[guideIndex] = {
        ...matrixToUpdate.matrixGuides[guideIndex],
        percent: roundNumber(Number(newValue) / 100),
      };
    }

    updatedMatrix.edited = true;
    setPageEdited(true);
    setMeritMatrices((prev) => {
      return prev.map((matrix) =>
        matrix.selected === true ? updatedMatrix : matrix
      );
    });
  };

  const onUpdateGuidanceRange = (
    rangeId: number | string,
    newRangeStart: number
  ) => {
    const updatedMatrix = cloneDeep(selectedMatrix);

    if (
      updatedMatrix?.bonusIndividual.matrixGuides == null ||
      updatedMatrix.bonusCompany.matrixGuides == null ||
      updatedMatrix.bonusOther.matrixGuides == null
    )
      return;

    updatedMatrix.bonusIndividual.matrixGuides.forEach((matrixGuide) => {
      if (matrixGuide.matrixRange.id === rangeId) {
        matrixGuide.matrixRange.rangeStart = Number(newRangeStart.toFixed(2));
      }
    });

    updatedMatrix.bonusCompany.matrixGuides.forEach((matrixGuide) => {
      if (matrixGuide.matrixRange.id === rangeId) {
        matrixGuide.matrixRange.rangeStart = Number(newRangeStart.toFixed(2));
      }
    });

    updatedMatrix.bonusOther.matrixGuides.forEach((matrixGuide) => {
      if (matrixGuide.matrixRange.id === rangeId) {
        matrixGuide.matrixRange.rangeStart = Number(newRangeStart.toFixed(2));
      }
    });

    updatedMatrix.edited = true;

    setPageEdited(true);
    setMeritMatrices((prev) => {
      return prev.map((matrix) =>
        matrix.selected === true ? updatedMatrix : matrix
      );
    });
  };

  return (
    <BonusGuidanceAndBudgetContext.Provider
      value={{
        meta: {
          loading,
          pageEdited,
          setPageEdited,
        },
        allEligible,
        matrices: {
          selectedMatrix,
          createMatrix,
          setSelectedMatrix,
          meritMatrices,
          updateName,
          onToggleDimension,
          onChangeDimensionSettings,
        },
        rules: {
          addRule,
          addEmailRule,
          updateRule,
          removeRule,
        },
        modals: {
          guidancePopulationSettings: {
            isOpen: modals.guidancePopulationSettings.isOpen,
            isFocused: modals.guidancePopulationSettings.isFocused,
            setOpen: (open: boolean) =>
              setModals((prev) => ({
                ...prev,
                guidancePopulationSettings: {
                  ...prev.guidancePopulationSettings,
                  isOpen: open,
                },
              })),
          },
          companyAttainment: {
            isOpen: modals.companyAttainmentModal.isOpen,
            isFocused: modals.companyAttainmentModal.isFocused,
            setOpen: (open: boolean) =>
              setModals((prev) => ({
                ...prev,
                companyAttainmentModal: {
                  ...prev.companyAttainmentModal,
                  isOpen: open,
                },
              })),
          },
        },
        shouldPrefillWithGuidance,
        onToggleShouldPrefillWithGuidance,
        organizationCompCyclePerfRating,
        updateOrganizationCompCyclePerfRating,
        onUpdateGuidanceRange,
        onUpdateMatrixGuide,
        persistChanges,
        persistDelete,
      }}
    >
      {children}
    </BonusGuidanceAndBudgetContext.Provider>
  );
}

BonusGuidanceAndBudgetProvider.query = gql`
  ${groupBonusGuidanceMatrices.fragments.matrix}
  query BonusGuidanceAndBudgetProviderQuery(
    $compCycleId: Int!
    $types: [MatrixTypeEnum!]!
  ) {
    compCycle2(id: $compCycleId) {
      id
      matrices(types: $types) {
        id
        ...groupBonusGuidanceMatrices_matrix
      }
    }
  }
`;

function validateMatrices(matrices: BonusGuidancePopulation[]) {
  return matrices.map((matrix) => {
    const otherParticipants = matrices
      .filter((m) => m !== matrix)
      .map((m) => m.eligibleParticipants ?? []);

    const result: BonusGuidancePopulation = {
      ...matrix,
      eligibilityValidations: {
        duplicates:
          matrix.eligibleParticipants?.filter((id) => {
            return otherParticipants.some((participants) =>
              participants.includes(id)
            );
          }) ?? [],
      },
    };

    return result;
  });
}
