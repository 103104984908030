import { gql } from "@apollo/client";
import { makeStyles, Tooltip } from "@material-ui/core";
import { useState } from "react";
import {
  BonusGuidanceAndBudget_compCycle as CompCycle,
  BonusGuidanceAndBudget_employee as Employee,
  MatrixTypeEnum,
  BonusGuidanceAndBudget_organization as Organization,
} from "src/__generated__/graphql";
import { AssembleTypography } from "src/components/AssembleTypography";
import { SaveButton } from "src/components/Form/SaveButton";
import UnsavedChangesWarning from "src/components/UnsavedChangesWarning";
import Section from "src/components/Workflow/Section";
import { useUpdateBonusGuidanceSettings } from "src/mutations/BonusGuidanceSettings";
import {
  useBuildEmptyMatrix,
  useUpdateMatrixEligibilityRules,
  useUpdateMatrixName,
} from "src/mutations/Matrix";
import { getUnassignedParticipants } from "../../MultipleMeritMatrices/utils";
import { ButtonBar } from "../Components/ButtonBar";
import { GuidanceDetailedView } from "../Components/GuidanceDetailedView";
import { GuidancePopulationList } from "../Components/GuidancePopulationList";
import { EligiblePeopleModal } from "../Components/Modals/EligiblePeopleModal";
import {
  getCompanyWeighting,
  getCustomWeighting,
  getIndividualWeighting,
} from "../utils";
import { BonusGuidanceButtonBar } from "./BonusGuidanceButtonBar";
import { CompanyAttainmentSettingsModal } from "./Modals/CompanyAttainmentSettingModal";
import { GuidancePopulationSettingsModal } from "./Modals/GuidancePopulationSettingsModal";
import { UpdateTargetBonusesModal } from "./Modals/UpdateTargetBonusesModal";
import { useBonusGuidanceAndBudget } from "./useBonusGuidanceAndBudget";

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1.25),
    paddingBottom: theme.spacing(0.5),
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
  buttonBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: theme.spacing(4),
  },
  saveButton: {
    width: "max-content",
    height: theme.spacing(5),
  },
}));

type Props = {
  organization: Organization;
  compCycle: CompCycle;
  employees: Employee[];
};

export function BonusGuidanceAndBudget({
  organization,
  compCycle,
  employees,
}: Props): JSX.Element {
  const classes = useStyles();
  const {
    meta,
    allEligible,
    matrices,
    matrices: { selectedMatrix },
    modals,
    persistChanges,
  } = useBonusGuidanceAndBudget();
  const [isEligiblePeopleModalOpen, setIsEligiblePeopleModalOpen] =
    useState(false);
  const [isUnassignedPeopleModalOpen, setIsUnassignedPeopleModalOpen] =
    useState(false);
  const [isEligiblePreSaveModalOpen, setIsEligiblePreSaveModalOpen] =
    useState(false);
  const [isUpdateTargetsModalOpen, setIsUpdateTargetsModalOpen] =
    useState(false);
  const createEmptyMatrices = useBuildEmptyMatrix(compCycle.id);
  const updateMatrixName = useUpdateMatrixName();
  const updateMatrixEligibilityRules = useUpdateMatrixEligibilityRules();
  const updateBonusGuidanceSettings = useUpdateBonusGuidanceSettings();

  const bonusIndividualId =
    selectedMatrix?.bonusIndividual.id ?? "bonus-individual-id";
  const bonusCompanyId = selectedMatrix?.bonusCompany.id ?? "bonus-company-id";
  const bonusOtherId = selectedMatrix?.bonusOther.id ?? "bonus-other-id";

  const key = `${bonusIndividualId}-${bonusCompanyId}-${bonusOtherId}`;

  // since we don't run validation on client-side population creation
  // (so we don't show an error banner as soon as the user creates a
  // population) we have this additional check to prevent saving
  // before rules have been added
  const totalAssigned = matrices.meritMatrices.reduce(
    (acc, matrix) =>
      acc + (matrix.eligibleParticipants?.length ?? allEligible.length),
    0
  );

  const hasValidationErrors = matrices.meritMatrices.some(
    (matrix) => matrix.eligibilityValidations.duplicates.length > 0
  );

  const individualWeighting = getIndividualWeighting(selectedMatrix);
  const companyWeighting = getCompanyWeighting(selectedMatrix);
  const customWeighting = getCustomWeighting(selectedMatrix);

  const totalWeighting =
    individualWeighting + companyWeighting + customWeighting;

  const disableSave =
    !meta.pageEdited ||
    hasValidationErrors ||
    totalAssigned > allEligible.length ||
    totalWeighting !== 100;

  const disabledCopy = disableSave
    ? !meta.pageEdited
      ? "No changes to save"
      : hasValidationErrors || totalAssigned > allEligible.length
        ? "One or more guidance populations contain the same person. Guidance populations need to have unique participants."
        : totalWeighting !== 100
          ? "Matrix weighting needs to add up to 100%"
          : undefined
    : undefined;

  const persist = () => {
    return persistChanges(
      () =>
        createEmptyMatrices([
          MatrixTypeEnum.BONUS_GUIDANCE_INDIVIDUAL_PERFORMANCE,
          MatrixTypeEnum.BONUS_GUIDANCE_COMPANY_PERFORMANCE,
          MatrixTypeEnum.BONUS_GUIDANCE_CUSTOM_PERFORMANCE,
        ]),
      updateMatrixName,
      updateMatrixEligibilityRules,
      updateBonusGuidanceSettings
    );
  };

  const handleSave = () => {
    if (totalAssigned < allEligible.length) {
      setIsEligiblePreSaveModalOpen(true);
      return Promise.resolve();
    }

    return persist();
  };

  return (
    <div>
      <UnsavedChangesWarning pageEdited={meta.pageEdited} />
      <EligiblePeopleModal
        featureType="bonus"
        type="eligible-pre-save"
        isOpen={isEligiblePreSaveModalOpen}
        compCycle={compCycle}
        handleClose={() => setIsEligiblePreSaveModalOpen(false)}
        employeeIds={allEligible.map((employee) => employee.subjectId)}
        handleSave={async () => {
          await persist();
          setIsEligiblePreSaveModalOpen(false);
        }}
      />

      <EligiblePeopleModal
        featureType="bonus"
        type="eligible"
        isOpen={isEligiblePeopleModalOpen}
        compCycle={compCycle}
        handleClose={() => setIsEligiblePeopleModalOpen(false)}
        employeeIds={allEligible.map((employee) => employee.subjectId)}
      />

      <EligiblePeopleModal
        featureType="bonus"
        type="unassigned"
        isOpen={isUnassignedPeopleModalOpen}
        compCycle={compCycle}
        handleClose={() => setIsUnassignedPeopleModalOpen(false)}
        employeeIds={getUnassignedParticipants(
          allEligible,
          matrices.meritMatrices
        )}
      />

      <GuidancePopulationSettingsModal
        isOpen={modals.guidancePopulationSettings.isOpen}
        handleClose={() => modals.guidancePopulationSettings.setOpen(false)}
        isFocused={modals.guidancePopulationSettings.isFocused}
      />

      <CompanyAttainmentSettingsModal
        isOpen={modals.companyAttainment.isOpen}
        handleClose={() => modals.companyAttainment.setOpen(false)}
        isFocused={modals.companyAttainment.isFocused}
      />

      <UpdateTargetBonusesModal
        isOpen={isUpdateTargetsModalOpen}
        handleClose={() => setIsUpdateTargetsModalOpen(false)}
        employees={employees}
      />

      <Section
        active
        header={
          <div className={classes.headerWrapper}>
            <div className={classes.header}>
              Bonus Guidance and Budget
              <ButtonBar
                featureType="bonus"
                handleEligiblePeopleModalOpen={() =>
                  setIsEligiblePeopleModalOpen(true)
                }
                handleUnassignedPeopleModalOpen={() =>
                  setIsUnassignedPeopleModalOpen(true)
                }
              />
            </div>
            <BonusGuidanceButtonBar
              handleOpenTargetBonusesModal={() =>
                setIsUpdateTargetsModalOpen(true)
              }
            />
          </div>
        }
        description={
          <AssembleTypography variant="productParagraphSmall">
            Automatically calculate actual bonus guidance with bonus plans for
            different populations across your organization.
          </AssembleTypography>
        }
      >
        <div className={classes.content}>
          <GuidancePopulationList featureType="bonus" />
          <GuidanceDetailedView
            key={key}
            organization={organization}
            compCycle={compCycle}
            featureType="bonus"
            employees={employees}
          />
          <div className={classes.buttonBar}>
            <div />
            <Tooltip title={disabledCopy ?? ""}>
              <span>
                <SaveButton
                  className={classes.saveButton}
                  hideEndIcon
                  disabled={disableSave}
                  onSave={handleSave}
                />
              </span>
            </Tooltip>
          </div>
        </div>
      </Section>
    </div>
  );
}

BonusGuidanceAndBudget.fragments = {
  organization: gql`
    ${GuidanceDetailedView.fragments.organization}
    fragment BonusGuidanceAndBudget_organization on Organization {
      id
      ...GuidanceDetailedView_organization
    }
  `,
  compCycle: gql`
    ${EligiblePeopleModal.fragments.compCycle}
    ${GuidanceDetailedView.fragments.compCycle}
    ${ButtonBar.fragments.compCycle}
    ${CompanyAttainmentSettingsModal.fragments.compCycle}
    fragment BonusGuidanceAndBudget_compCycle on CompCycle2 {
      id
      ...ButtonBar_compCycle
      ...EligiblePeopleModal_compCycle
      ...GuidanceDetailedView2_compCycle
      ...CompanyAttainmentSettingsModal_compCycle
    }
  `,
  employee: gql`
    ${UpdateTargetBonusesModal.fragments.employee}
    ${GuidanceDetailedView.fragments.employee}
    fragment BonusGuidanceAndBudget_employee on Employee {
      id
      ...UpdateTargetBonusesModal_employee
      ...GuidanceDetailedView_employee
    }
  `,
};
