import { makeStyles, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { CircleArrowLeftIcon } from "src/components/AssembleIcons/Brand/CircleArrowLeftIcon";
import { CircleArrowRightIcon } from "src/components/AssembleIcons/Brand/CircleArrowRightIcon";
import { PlusIcon } from "src/components/AssembleIcons/Brand/PlusIcon";
import { GRAY_1, PURPLE_2 } from "src/theme";
import { useGuidanceAndBudget } from "../useGuidanceAndBudget";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: theme.spacing(1.5),
  },
  scrollButton: {
    borderRadius: "50%",

    "&:hover": {
      boxShadow: "none",
    },
  },
  selected: {
    "& button": {
      border: `1px solid ${GRAY_1}`,
      borderRadius: 5,
    },
  },
  carousel: {
    display: "flex",
    gap: theme.spacing(1.5),
    overflow: "auto",
    scrollSnapType: "x mandatory",
    scrollbarWidth: "thin",
    "& > *": {
      scrollSnapAlign: "center",
    },
    "::-webkit-scrollbar": { display: "none" },
  },
  carouselElement: {
    // small padding to allow the box shadow to show
    padding: theme.spacing(0.15),
  },
}));

type Props = { featureType: "merit" | "bonus" };

export function GuidancePopulationList({ featureType }: Props): JSX.Element {
  const classes = useStyles();
  const [isScrollable, setIsScrollable] = useState(false);
  const carouselRef = useRef<HTMLDivElement>(null);
  const {
    matrices: { meritMatrices, setSelectedMatrix, createMatrix },
  } = useGuidanceAndBudget(featureType);

  const scrollAmount = 300;

  const scrollLeft = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: -scrollAmount, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const checkScrollable = () => {
    if (carouselRef.current) {
      // Check if content overflows
      setIsScrollable(
        carouselRef.current.scrollWidth > carouselRef.current.clientWidth
      );
    }
  };

  useEffect(() => {
    // Check scrollability on mount, window resize, and carousel content change
    checkScrollable();
    window.addEventListener("resize", checkScrollable);

    return () => {
      window.removeEventListener("resize", checkScrollable);
    };
  }, [meritMatrices.length]);

  return (
    <div className={classes.root}>
      <Tooltip title="Create new Guidance Population">
        <span>
          <AssembleButton
            size="small"
            variant="text"
            startIcon={<PlusIcon color={PURPLE_2} />}
            onClick={createMatrix}
          />
        </span>
      </Tooltip>
      {isScrollable && (
        <AssembleButton
          className={classes.scrollButton}
          size="small"
          variant="text"
          startIcon={<CircleArrowLeftIcon color={PURPLE_2} />}
          onClick={scrollLeft}
        >
          Left
        </AssembleButton>
      )}
      <div className={classes.carousel} ref={carouselRef}>
        {meritMatrices.map((matrix, index) => (
          <div
            key={index}
            className={clsx(classes.carouselElement, {
              [classes.selected]: matrix.selected,
            })}
          >
            <AssembleButton
              size="small"
              variant="outlined"
              label={matrix.name}
              onClick={() => setSelectedMatrix(index)}
            />
          </div>
        ))}
      </div>
      {isScrollable && (
        <AssembleButton
          className={classes.scrollButton}
          size="small"
          variant="text"
          startIcon={<CircleArrowRightIcon color={PURPLE_2} />}
          onClick={scrollRight}
        >
          Right
        </AssembleButton>
      )}
    </div>
  );
}
