import { IconButton } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { CircleArrowRightIcon } from "src/components/AssembleIcons/Brand/CircleArrowRightIcon";
import { ColumnSettingsIcon } from "src/components/AssembleIcons/Brand/ColumnSettingsIcon";
import { DragHandleIcon } from "src/components/AssembleIcons/Brand/DragHandleIcon";
import { EyeOffIcon } from "src/components/AssembleIcons/Brand/EyeOff";
import { EyeOnIcon } from "src/components/AssembleIcons/Brand/EyeOnIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import { GRAY_2, GRAY_4, GRAY_5, PURPLE_1 } from "src/theme";
import { reorder } from "src/utils";
import {
  ColumnIds,
  ColumnIdsToHeaders,
  useColumnOrder,
} from "../Contexts/ColumnOrderContext";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    padding: theme.spacing(4),
    minWidth: "400px",
  },
  marginDragHandle: {
    height: "1.5rem",
    width: "1.5rem",
    color: GRAY_4,
    marginRight: ".25rem",
    "&:focus-within": {
      color: PURPLE_1,
    },
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "1rem",
    width: "80%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  spacer: {
    width: "1.5rem",
  },
  hiddenDragHandle: {
    display: "none !important",
  },
  closeContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  closeButton: {
    cursor: "pointer",
  },
  leftContainer: {
    display: "flex",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    width: "87%",
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  showHideIcon: {
    cursor: "pointer",
  },
  disabledHideIcon: {
    cursor: "default",
  },
}));

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: React.CSSProperties = {}
): React.CSSProperties => ({
  border: isDragging ? `1px solid ${GRAY_4}` : undefined,
  boxSizing: isDragging ? "border-box" : undefined,
  boxShadow: isDragging ? "0px 5px 15px rgba(10, 36, 64, 0.13)" : undefined,
  ...draggableStyle,
});

export default function CompCycleColumnOrder() {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const {
    columns,
    setColumns,
    hiddenColumns,
    setHiddenColumns,
    staticColumns,
  } = useColumnOrder();

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return; // ignore if dropped outside the list

    const reorderedColumns = reorder(
      columns,
      result.source.index,
      result.destination.index
    );

    setColumns(reorderedColumns);
  };

  const toggleShowHide = (columnId: ColumnIds) => {
    const hiddenColumnsCopy = hiddenColumns.slice(0);
    const columnsCopy = columns.slice(0);
    if (hiddenColumnsCopy.includes(columnId)) {
      hiddenColumnsCopy.splice(hiddenColumnsCopy.indexOf(columnId), 1);
      columnsCopy.push(columnId);
    } else {
      hiddenColumnsCopy.push(columnId);
      columnsCopy.splice(columnsCopy.indexOf(columnId), 1);
    }
    setColumns(columnsCopy);
    setHiddenColumns(hiddenColumnsCopy);
  };

  return (
    <>
      <AssembleButton
        onClick={() => setDrawerOpen(true)}
        size="small"
        label={
          <span className={classes.button}>
            <ColumnSettingsIcon color={GRAY_2} />
          </span>
        }
        variant="outlined"
      />
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <div className={classes.container}>
          <span className={classes.closeContainer}>
            <IconButton onClick={() => setDrawerOpen(false)}>
              <CircleArrowRightIcon
                color={GRAY_4}
                inline
                width="24px"
                height="24px"
              />
            </IconButton>
          </span>
          <div className={classes.header}>
            <AssembleTypography variant="productTabHeader">
              ORDER
            </AssembleTypography>
            <AssembleTypography variant="productTabHeader">
              SHOW/HIDE
            </AssembleTypography>
          </div>
          {staticColumns.map((columnId) => (
            <div key={columnId}>
              <div className={classes.rowContainer}>
                <span className={classes.leftContainer}>
                  {/* Leaving the drag handle hidden for spacing */}
                  <div className={classes.marginDragHandle}>
                    <DragHandleIcon
                      className={clsx(
                        classes.marginDragHandle,
                        classes.hiddenDragHandle
                      )}
                    />
                  </div>
                  <AssembleTypography variant="body1">
                    {ColumnIdsToHeaders.get(columnId)}
                  </AssembleTypography>
                </span>
                <IconButton disabled>
                  <EyeOnIcon
                    color={GRAY_5}
                    width="24px"
                    height="24px"
                    className={clsx(
                      classes.showHideIcon,
                      classes.disabledHideIcon
                    )}
                  />
                </IconButton>
              </div>
            </div>
          ))}
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="columnReorderDrop">
              {(provided) => (
                <div ref={provided.innerRef}>
                  {columns.map((columnId, index) => {
                    return (
                      <>
                        <Draggable
                          key={columnId}
                          draggableId={columnId}
                          index={index}
                        >
                          {(dragProvided, snapshot) => (
                            <div
                              key={columnId}
                              ref={dragProvided.innerRef}
                              {...dragProvided.draggableProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                dragProvided.draggableProps.style
                              )}
                            >
                              <div className={classes.rowContainer}>
                                <span className={classes.leftContainer}>
                                  <div className={classes.marginDragHandle}>
                                    <div {...dragProvided.dragHandleProps}>
                                      <DragHandleIcon
                                        className={classes.marginDragHandle}
                                      />
                                    </div>
                                  </div>
                                  <AssembleTypography variant="body1">
                                    {ColumnIdsToHeaders.get(columnId)}
                                  </AssembleTypography>
                                </span>

                                <IconButton
                                  onClick={() => toggleShowHide(columnId)}
                                >
                                  <EyeOnIcon
                                    className={classes.showHideIcon}
                                    color={GRAY_2}
                                    width="24px"
                                    height="24px"
                                  />
                                </IconButton>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      </>
                    );
                  })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {hiddenColumns.map((columnId) => (
            <div key={columnId}>
              <div className={classes.rowContainer}>
                <span className={classes.leftContainer}>
                  <div className={classes.marginDragHandle} />
                  <AssembleTypography variant="body1">
                    {ColumnIdsToHeaders.get(columnId)}
                  </AssembleTypography>
                </span>

                <IconButton onClick={() => toggleShowHide(columnId)}>
                  <EyeOffIcon
                    className={classes.showHideIcon}
                    color={GRAY_2}
                    width="24px"
                    height="24px"
                  />
                </IconButton>
              </div>
            </div>
          ))}
        </div>
      </Drawer>
    </>
  );
}
