import { gql } from "@apollo/client";
import {
  ActualVariablePayRows_compCycle as CompCycle,
  CurrencyCode,
  MatrixTypeEnum,
  ActualVariablePayRows_participant as Participant,
  ActualVariablePayRows_position as Position,
  RecItemInput,
  RecItemType,
} from "../../../__generated__/graphql";
import { useCompRecData } from "../CompCycleSingleRecModal/CompRecommendationContext";
import { ActualTargetBonusGuidanceRow } from "./LineItemRows/ActualTargetBonusGuidance";
import { ActualTargetPayRow } from "./LineItemRows/ActualTargetPay";
import { HeaderSeparatorRow } from "./LineItemRows/HeaderSeparator";
import { OneTimeBonusRow } from "./LineItemRows/OneTimeBonus";
import { TotalActualBonus } from "./LineItemRows/TotalActualBonus";

type Props = {
  show: boolean;
  employee: Participant;
  promotedPosition: Position | null;
  compCycle: CompCycle;
  onChangeRecommendationItem: (
    newRecommendationItem: RecItemInput | null
  ) => unknown;
};

export function ActualVariablePayRows({
  show,
  employee,
  compCycle,
  promotedPosition,
  onChangeRecommendationItem,
}: Props): JSX.Element {
  const { usingBonusGuidance, bonusGuidance } = useCompRecData();
  const payCurrencyCode = employee.subject.activeEmployment
    ?.payCurrencyCode as CurrencyCode | null;
  const guidance = usingBonusGuidance ? bonusGuidance : null;
  const guidanceDisabled =
    guidance === null ||
    Object.values(guidance).every(
      (g) =>
        !g.matrix?.bonusGuidanceSettings ||
        g.matrix.bonusGuidanceSettings.isDimensionEnabled === false
    );
  const customGuidance = guidance
    ? guidance[MatrixTypeEnum.BONUS_GUIDANCE_CUSTOM_PERFORMANCE]
    : null;

  const indGuidance = guidance
    ? guidance[MatrixTypeEnum.BONUS_GUIDANCE_INDIVIDUAL_PERFORMANCE]
    : null;

  const compGuidance = guidance
    ? {
        ...guidance[MatrixTypeEnum.BONUS_GUIDANCE_COMPANY_PERFORMANCE],
        percent: Number(compCycle.organizationCompCyclePerfRating) / 100,
      }
    : null;

  return (
    <>
      <HeaderSeparatorRow text="Actual Bonus Pay" filtered={!show} first />
      {show && compCycle.allowActualRecurringBonus && (
        <>
          {usingBonusGuidance && !guidanceDisabled ? (
            <>
              {indGuidance?.matrix?.bonusGuidanceSettings
                ?.isDimensionEnabled === true && (
                <ActualTargetBonusGuidanceRow
                  participant={employee}
                  currency={payCurrencyCode}
                  label="Individual Performance"
                  onChangeRecommendationItem={onChangeRecommendationItem}
                  guidance={indGuidance}
                  recommendationType={RecItemType.ACTUAL_RECURRING_BONUS}
                />
              )}
              {compGuidance?.matrix?.bonusGuidanceSettings
                ?.isDimensionEnabled === true && (
                <ActualTargetBonusGuidanceRow
                  participant={employee}
                  currency={payCurrencyCode}
                  label="Company Attainment"
                  onChangeRecommendationItem={onChangeRecommendationItem}
                  guidance={compGuidance}
                  recommendationType={
                    RecItemType.ACTUAL_RECURRING_BONUS_COMPANY
                  }
                  disabled
                />
              )}
              {customGuidance?.matrix?.bonusGuidanceSettings
                ?.isDimensionEnabled === true && (
                <ActualTargetBonusGuidanceRow
                  participant={employee}
                  currency={payCurrencyCode}
                  label={
                    customGuidance.matrix.bonusGuidanceSettings
                      .customDimensionName ?? "Custom Score"
                  }
                  onChangeRecommendationItem={onChangeRecommendationItem}
                  guidance={customGuidance}
                  recommendationType={RecItemType.ACTUAL_RECURRING_BONUS_CUSTOM}
                />
              )}
              <TotalActualBonus
                employee={employee.subject}
                promotedPosition={promotedPosition}
              />
            </>
          ) : (
            <ActualTargetPayRow
              employee={employee.subject}
              currency={payCurrencyCode}
              label="Actual Recurring Bonus"
              onChangeRecommendationItem={onChangeRecommendationItem}
            />
          )}
        </>
      )}
      {show && compCycle.allowBonus && (
        <OneTimeBonusRow
          employee={employee.subject}
          currency={payCurrencyCode}
          onChangeRecommendationItem={onChangeRecommendationItem}
        />
      )}
    </>
  );
}

ActualVariablePayRows.fragments = {
  participant: gql`
    ${OneTimeBonusRow.fragments.employee}
    ${ActualTargetPayRow.fragments.employee}
    ${ActualTargetBonusGuidanceRow.fragments.participant}
    ${TotalActualBonus.fragments.employee}
    fragment ActualVariablePayRows_participant on CompCycleParticipant {
      ...ActualTargetBonusGuidanceRow_participant
      subject {
        ...OneTimeBonusRow_employee
        ...ActualTargetPayRow_employee
        ...TotalActualBonus_employee
        id
        activeEmployment {
          id
          payCurrencyCode
        }
      }
    }
  `,
  compCycle: gql`
    fragment ActualVariablePayRows_compCycle on CompCycle2 {
      id
      allowBonus
      allowActualRecurringBonus
      organizationCompCyclePerfRating
    }
  `,
  position: gql`
    ${TotalActualBonus.fragments.position}
    fragment ActualVariablePayRows_position on Position {
      id
      ...TotalActualBonus_position
    }
  `,
};
