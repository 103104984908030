import { gql } from "@apollo/client";
import { CurrencyCode } from "@asmbl/shared/constants";
import { Money } from "@asmbl/shared/money";
import { TableCell, TableRow } from "@material-ui/core";
import {
  CompComponent,
  RecItemInput,
  RecItemType,
  SalaryIncreaseRow_employee,
} from "../../../../__generated__/graphql";
import { useTrack } from "../../../../analytics";
import { CompSubComponent } from "../../../../models/Budget";
import { hasUnpublishedChanges } from "../../../../models/CompRecommendation";
import { SalaryInputCell } from "../../Cells/SalaryInputCell";
import { ApplyGuidanceCell } from "../../CompCycleSingleRecModal/ApplyGuidanceCell";
import { useCompRecData } from "../../CompCycleSingleRecModal/CompRecommendationContext";
import { CompCycleLineItemRowButton } from "../CompCycleLineItemRowButton";
import { useConnectorStyles, useRecItemRowStyles } from "./styles";

interface Props {
  employee: SalaryIncreaseRow_employee;
  label: string;
  basePay: { annualCashEquivalent: Money; hourlyCashEquivalent: Money } | null;
  payCurrencyCode: CurrencyCode | null;
  recommendationType: RecItemType;
  subComponent: CompSubComponent;
  isHourly: boolean;
  onChangeRecommendationItem: (
    newRecommendationItem: RecItemInput | null
  ) => unknown;
}

export function SalaryIncreaseRow({
  employee,
  label,
  basePay,
  payCurrencyCode,
  recommendationType,
  onChangeRecommendationItem,
  subComponent,
  isHourly,
}: Props): JSX.Element {
  const { Track } = useTrack({
    compensationComponent: CompComponent.SALARY,
    compensationSubComponent: subComponent,
    employeeId: employee.id,
  });
  const classes = useRecItemRowStyles();
  const connectorStyle = useConnectorStyles();
  const {
    meritGuidance: guidance,
    submittedRecommendation,
    revisedRecommendation,
  } = useCompRecData();

  const [submitted, revised] = [
    submittedRecommendation,
    revisedRecommendation,
  ].map((x) => {
    return x?.items.get(recommendationType);
  });

  const payIncrease = revised;

  const currentIncreaseIsGuidance =
    recommendationType === RecItemType.MERIT_INCREASE &&
    payIncrease?.note === "Applied from guidance suggestion";

  return (
    <Track>
      <TableRow aria-level={3} className={classes.root}>
        <TableCell role="gridcell" padding="none">
          <div className={connectorStyle.line} />
        </TableCell>
        <ApplyGuidanceCell
          label={label}
          basePay={basePay}
          submittedRecommendation={submittedRecommendation}
          revisedRecommendation={revisedRecommendation}
          onChangeRecommendationItem={onChangeRecommendationItem}
          guidance={
            recommendationType === RecItemType.MERIT_INCREASE ? guidance : null
          }
        />
        <SalaryInputCell
          value={payIncrease}
          isHourly={isHourly}
          basePay={basePay}
          hasUnpublishedChanges={hasUnpublishedChanges(submitted, revised)}
          onChange={(newSalary, unitType) => {
            onChangeRecommendationItem({
              recommendationType,
              recommendedCashValue: newSalary,
              unitType,
              note: currentIncreaseIsGuidance ? undefined : payIncrease?.note,
            });
          }}
          payCurrencyCode={payCurrencyCode}
        />
        <TableCell role="gridcell" className={classes.noteTextCell} colSpan={4}>
          <CompCycleLineItemRowButton
            disabled={
              payIncrease?.recommendedCashValue === undefined &&
              payIncrease?.recommendedPercentValue === undefined
            }
            employee={employee}
            note={payIncrease?.note ?? null}
            onClick={(note: string) =>
              onChangeRecommendationItem({
                ...payIncrease,
                recommendationType,
                note,
              })
            }
          />
        </TableCell>
        <TableCell role="gridcell" />
      </TableRow>
    </Track>
  );
}

SalaryIncreaseRow.fragments = {
  employee: gql`
    ${CompCycleLineItemRowButton.fragments.employee}
    fragment SalaryIncreaseRow_employee on Employee2 {
      ...CompCycleLineItemRowButton_employee
      id
      activeEmployment {
        id
        salary
        payPeriod
        payRate
        payCurrencyCode
      }
      activeCashCompensation(currencyCode: $currencyCode) {
        type
        annualCashEquivalent
        hourlyCashEquivalent
        unit
      }
    }
  `,
};
