import { Input, makeStyles, TableCell } from "@material-ui/core";
import clsx from "clsx";
import { useRef } from "react";
import { GRAY_1, GRAY_3 } from "src/theme";

type Props = {
  width: "narrow" | "wide";
  height?: "short" | "tall";
  onChange: (e: { target: { value: string } }) => void;
  defaultValue?: number | null;
  disabled?: boolean;
  className?: string;
};

const useStyles = makeStyles((theme) => ({
  editableCell: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: theme.spacing(1),
  },
  inputWithoutStepper: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    fontSize: 13,
    color: GRAY_1,
  },
  endAdornment: {
    color: GRAY_3,
    paddingLeft: theme.spacing(1),
  },
  narrow: {
    width: "25%",
  },
  wide: {
    width: "75%",
  },
  short: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
}));

export function MatrixCell({
  width,
  height = "tall",
  onChange,
  defaultValue,
  disabled = false,
  className,
}: Props) {
  const classes = useStyles();
  const inputRef = useRef(null);

  const handleCellFocus = () => {
    (inputRef.current as HTMLElement | null)?.focus();
  };

  return (
    <TableCell
      onClick={handleCellFocus}
      className={clsx(className, {
        [classes.narrow]: width === "narrow",
        [classes.wide]: width === "wide",
        [classes.short]: height === "short",
      })}
    >
      <Input
        type="number"
        disabled={disabled}
        defaultValue={defaultValue}
        className={classes.inputWithoutStepper}
        disableUnderline
        inputRef={inputRef}
        onChange={onChange}
        onFocus={(e) => e.target.select()}
        fullWidth
        inputProps={{
          style: { textAlign: "right" },
        }}
        endAdornment={<span className={classes.endAdornment}>%</span>}
      />
    </TableCell>
  );
}
