import { gql } from "@apollo/client";
import { isEmptyString } from "@asmbl/shared/utils";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import clsx from "clsx";
import { useState } from "react";
import { CustomDimensionValueType } from "src/__generated__/graphql";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { AssembleTypography } from "src/components/AssembleTypography";
import { InfoBanner } from "src/components/InfoBanner";
import { BLUE_3, GRAY_2, YELLOW, YELLOW_2 } from "src/theme";
import { useBonusGuidanceAndBudget } from "../useBonusGuidanceAndBudget";

const useStyles = makeStyles((theme) => ({
  dialogHeader: {
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogTitle: {
    display: "flex",
    flexDirection: "column",
  },
  dialogContent: {
    padding: theme.spacing(1),

    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
  },
  contentFocused: {
    background: `linear-gradient(148.88deg, ${YELLOW}1A 29.51%, ${YELLOW_2}1A 85.88%)`,
  },
  dialogActions: {
    paddingRight: 0,
    paddingBottom: theme.spacing(0.5),
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),
  },
  radioGroup: {
    paddingLeft: theme.spacing(1),
  },
  radioButton: {
    border: "none !important",
  },
  formControlLabel: {
    width: "fit-content",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(1.5),
  },
  textField: {
    width: "40%",
  },
  infoBanner: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  infoWarn: {
    background: `${BLUE_3}OF`,
  },
}));

type Props = {
  isOpen: boolean;
  isFocused: boolean;
  handleClose: () => void;
};

export function GuidancePopulationSettingsModal({
  isOpen,
  isFocused,
  handleClose,
}: Props): JSX.Element {
  const classes = useStyles();
  const {
    matrices: { selectedMatrix, onChangeDimensionSettings },
  } = useBonusGuidanceAndBudget();

  const customDimension = selectedMatrix?.bonusOther;

  const [dimensionName, setDimensionName] = useState(
    customDimension?.bonusGuidanceSettings?.customDimensionName ?? ""
  );

  const [dimensionType, setDimensionType] = useState<CustomDimensionValueType>(
    customDimension?.bonusGuidanceSettings?.customDimensionValueType ??
      CustomDimensionValueType.NONE
  );

  const options = [
    {
      label: "A single score, like a team's target goal",
      value: CustomDimensionValueType.SINGLE_SCORE,
    },
    {
      label: "A rating system, like manager or performance ratings",
      value: CustomDimensionValueType.RATING_SYSTEM,
    },
    {
      label: "No third dimension",
      value: CustomDimensionValueType.NONE,
    },
  ];

  const disabled =
    dimensionType !== CustomDimensionValueType.NONE &&
    isEmptyString(dimensionName);

  const handleSave = () => {
    const bgSettingsId = customDimension?.bonusGuidanceSettings?.id;
    if (bgSettingsId == null) return;

    onChangeDimensionSettings(bgSettingsId, {
      customDimensionName: dimensionName,
      customDimensionValueType: dimensionType,
    });

    handleClose();
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm">
      <div className={classes.dialogHeader}>
        <div className={classes.dialogTitle}>
          <AssembleTypography variant="h4">
            Guidance Population Settings
          </AssembleTypography>
          <AssembleTypography variant="productParagraphMedium">
            These are optional settings to help enhance how you calculate your
            bonus actuals.
          </AssembleTypography>
        </div>
      </div>
      <Divider />
      <DialogContent
        className={clsx(classes.dialogContent, {
          [classes.contentFocused]: isFocused,
        })}
      >
        <AssembleTypography variant="h5">Third Dimension</AssembleTypography>
        <RadioGroup
          className={classes.radioGroup}
          value={dimensionType}
          onChange={(e) =>
            setDimensionType(e.target.value as CustomDimensionValueType)
          }
        >
          {options.map(({ label, value }) => (
            <FormControlLabel
              key={value}
              className={classes.formControlLabel}
              value={value}
              control={
                <Radio size="small" classes={{ root: classes.radioButton }} />
              }
              label={
                <AssembleTypography variant="productParagraphMedium">
                  {label}
                </AssembleTypography>
              }
            />
          ))}
        </RadioGroup>
        <TextField
          disabled={dimensionType === CustomDimensionValueType.NONE}
          className={classes.textField}
          fullWidth={false}
          size="small"
          variant="outlined"
          name="dimensionName"
          label="Dimension Name"
          value={dimensionName}
          onChange={(e) => setDimensionName(e.target.value)}
        />

        {dimensionType === CustomDimensionValueType.RATING_SYSTEM && (
          <InfoBanner
            level="info"
            className={clsx(classes.infoBanner, classes.infoWarn)}
          >
            <AssembleTypography textColor={GRAY_2}>
              We need to map each person in this guidance population to a
              rating.
            </AssembleTypography>
            <AssembleButton
              variant="text"
              size="small"
              label="Upload Ratings"
            />
          </InfoBanner>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <AssembleButton
          variant="text"
          color="primary"
          label="Cancel"
          size="medium"
          onClick={handleClose}
        />
        <AssembleButton
          variant="contained"
          color="primary"
          size="medium"
          label="Save Settings"
          disabled={disabled}
          onClick={handleSave}
        />
      </DialogActions>
    </Dialog>
  );
}

GuidancePopulationSettingsModal.fragments = {
  bonusGuidanceSettings: gql`
    fragment GuidancePopulationSettingsModal_bonusGuidanceSettings on BonusGuidanceSettings {
      id
      compCycleId
      organizationId
      customDimensionName
      customDimensionValueType
    }
  `,
};
