import { gql } from "@apollo/client";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  makeStyles,
} from "@material-ui/core";
import { EligiblePeopleModal_compCycle as CompCycle } from "src/__generated__/graphql";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { AssembleTypography } from "src/components/AssembleTypography";
import { DismissableInfoBanner } from "src/components/InfoBanner";
import { GRAY_3 } from "src/theme";
import { useGuidanceAndBudget } from "../../useGuidanceAndBudget";

const useStyles = makeStyles((theme) => ({
  dialogHeader: {
    padding: theme.spacing(1),

    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
  },
  toggleWrapper: {
    width: "75%",
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  employeeElement: {
    padding: theme.spacing(0.25),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  dialogActions: {
    paddingRight: 0,
    paddingBottom: theme.spacing(0.5),
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),
  },
}));

type Props =
  | {
      featureType: "merit" | "bonus";
      type: "eligible" | "unassigned";
      isOpen: boolean;
      compCycle: CompCycle;
      employeeIds: number[];
      handleClose: () => void;
    }
  | {
      featureType: "merit" | "bonus";
      type: "guidance-population";
      isOpen: boolean;
      compCycle: CompCycle;
      employeeIds: number[];
      handleClose: () => void;
    }
  | {
      featureType: "merit" | "bonus";
      type: "eligible-pre-save";
      isOpen: boolean;
      compCycle: CompCycle;
      employeeIds: number[];
      handleClose: () => void;
      handleSave: () => void;
    };

export function EligiblePeopleModal({
  type,
  isOpen,
  compCycle,
  employeeIds,
  handleClose,
  featureType,
  ...props
}: Props): JSX.Element {
  const classes = useStyles();
  const {
    allEligible,
    matrices: { selectedMatrix, meritMatrices },
    meta: { loading },
  } = useGuidanceAndBudget(featureType);

  const getEmployee = (employeeId: number) =>
    allEligible.find((employee) => employee.subjectId === employeeId);

  const totalAssigned = meritMatrices.reduce(
    (acc, matrix) =>
      acc + (matrix.eligibleParticipants?.length ?? allEligible.length),
    0
  );

  const totalUnassigned = allEligible.length - totalAssigned;

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        style: {
          position: "absolute",
          top: "2%",
          left: "50%",
          transform: "translate(-50%, 0)",
          maxHeight: "90%",
        },
      }}
    >
      {type === "eligible-pre-save" && (
        <DismissableInfoBanner fullWidth level="warning">
          {totalUnassigned} aren't included in a guidance population
        </DismissableInfoBanner>
      )}
      <div className={classes.dialogHeader}>
        <div className={classes.dialogTitle}>
          <AssembleTypography variant="productSummaryValue">
            {type === "eligible" && "Eligible People"}
            {type === "eligible-pre-save" && "Eligible People"}
            {type === "unassigned" && "Unassigned People"}
            {type === "guidance-population" && "Guidance Population"}
          </AssembleTypography>
          <AssembleTypography variant="productSummaryValue">
            &nbsp;|&nbsp;
          </AssembleTypography>
          <AssembleTypography variant="productParagraphMedium">
            {type === "eligible" && compCycle.name}
            {type === "eligible-pre-save" && compCycle.name}
            {type === "unassigned" && compCycle.name}
            {type === "guidance-population" && selectedMatrix?.name}
          </AssembleTypography>
        </div>
        {type !== "eligible-pre-save" && (
          <AssembleButton
            variant="outlined"
            onClick={handleClose}
            size="small"
            label="Close"
          />
        )}
      </div>

      <Divider />

      <DialogContent className={classes.dialogContent}>
        <div>
          {employeeIds.map((employeeId) => (
            <div key={employeeId} className={classes.employeeElement}>
              <AssembleTypography variant="productParagraphMedium">
                {getEmployee(employeeId)?.subject.displayName} -
              </AssembleTypography>
              <AssembleTypography
                variant="productParagraphSmall"
                textColor={GRAY_3}
              >
                {getEmployee(employeeId)?.subject.activeEmployment?.jobTitle ??
                  "-"}
              </AssembleTypography>
            </div>
          ))}
        </div>
      </DialogContent>
      {type === "eligible-pre-save" && (
        <DialogActions className={classes.dialogActions}>
          <AssembleButton
            variant="outlined"
            onClick={handleClose}
            size="small"
            label="Close and Fix"
          />
          <AssembleButton
            variant="contained"
            onClick={"handleSave" in props ? props.handleSave : undefined}
            size="small"
            label={loading ? "Saving..." : "Save and Proceed"}
            disabled={loading}
          />
        </DialogActions>
      )}
    </Dialog>
  );
}

EligiblePeopleModal.fragments = {
  compCycle: gql`
    fragment EligiblePeopleModal_compCycle on CompCycle2 {
      id
      name
    }
  `,
};
