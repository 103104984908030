import { CurrencyCode } from "@asmbl/shared/constants";
import { add, zero } from "@asmbl/shared/money";
import { makeStyles } from "@material-ui/core";
import { AssembleTypography } from "src/components/AssembleTypography";
import { getSimpleCashLabel } from "src/models/Currency";
import { GRAY_6, GRAY_8 } from "src/theme";
import { useBonusGuidanceAndBudget } from "./useBonusGuidanceAndBudget";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(2.25, 4.75),

    backgroundColor: GRAY_8,
    border: `1px solid ${GRAY_6}`,
    borderTop: "none",
    borderRadius: "0px 0px 5px 5px",

    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(3),
  },
}));

export function ImpliedPayout(): JSX.Element {
  const classes = useStyles();

  const {
    matrices: { selectedMatrix },
  } = useBonusGuidanceAndBudget();

  const individualCost =
    selectedMatrix?.bonusIndividual.bonusGuidanceSettings
      ?.isDimensionEnabled === true &&
    selectedMatrix.bonusIndividual.estimatedCost != null
      ? selectedMatrix.bonusIndividual.estimatedCost
      : zero(CurrencyCode.USD);

  const companyCost =
    selectedMatrix?.bonusCompany.bonusGuidanceSettings?.isDimensionEnabled ===
      true && selectedMatrix.bonusCompany.estimatedCost != null
      ? selectedMatrix.bonusCompany.estimatedCost
      : zero(CurrencyCode.USD);

  const otherCost =
    selectedMatrix?.bonusOther.bonusGuidanceSettings?.isDimensionEnabled ===
      true && selectedMatrix.bonusOther.estimatedCost != null
      ? selectedMatrix.bonusOther.estimatedCost
      : zero(CurrencyCode.USD);

  return (
    <div className={classes.root}>
      <AssembleTypography variant="productParagraphMedium">
        Cost of Bonuses
      </AssembleTypography>
      <AssembleTypography variant="productParagraphMedium">
        {getSimpleCashLabel(add(individualCost, add(companyCost, otherCost)))}
      </AssembleTypography>
    </div>
  );
}
