import { money, Money } from "@asmbl/shared/money";
import { formatNumeral } from "@asmbl/shared/utils";
import { TableCell, Typography } from "@material-ui/core";
import { formatNumber } from "accounting";
import clsx from "clsx";
import { CompUnit, RecItemType } from "src/__generated__/graphql";
import { AssembleButton } from "src/components/AssembleButton/AssembleButton";
import { ExplanatoryTooltip } from "src/components/ExplanatoryTooltip";
import { BonusGuidanceOption } from "src/models/Guidance";
import { useRecItemRowStyles } from "../CompTable/LineItemRows/styles";
import { CompCycleLineItemRowProps } from "../CompTable/LineItemRows/types";

interface Props extends CompCycleLineItemRowProps {
  label: string;
  basePay: { annualCashEquivalent: Money; hourlyCashEquivalent: Money } | null;
  recommendationType:
    | RecItemType.ACTUAL_RECURRING_BONUS
    | RecItemType.ACTUAL_RECURRING_BONUS_COMPANY
    | RecItemType.ACTUAL_RECURRING_BONUS_CUSTOM;
  guidance?: BonusGuidanceOption | null;
  hideApply?: boolean;
}

export function ApplyBonusGuidanceCell({
  label,
  basePay,
  onChangeRecommendationItem,
  guidance,
  recommendationType,
  hideApply = false,
}: Props): JSX.Element {
  const classes = useRecItemRowStyles();

  const applyGuidance = () => {
    if (!guidance || "message" in guidance || basePay == null) return;
    onChangeRecommendationItem({
      note: "Applied from guidance suggestion",
      recommendationType,
      recommendedCashValue: money(
        guidance.pay,
        basePay.annualCashEquivalent.currency
      ),
      unitType: CompUnit.CASH,
    });
  };

  const hasGuidance = guidance != null;
  const hasGuidancePercentage = hasGuidance && "percent" in guidance;

  return (
    <TableCell
      role="gridcell"
      colSpan={5}
      padding="none"
      className={classes.textCell}
    >
      <Typography
        className={clsx(classes.rowLabel, {
          [classes.inline]: true,
        })}
      >
        {label}{" "}
      </Typography>
      <Typography className={classes.extraRowText} component="span">
        {guidance && "weighting" in guidance
          ? `(Weighting: ${formatNumber(guidance.weighting, 0)}%)`
          : ""}
      </Typography>
      {hasGuidance && !hideApply ? (
        <Typography
          className={clsx(
            classes.rowLabel,
            classes.guidanceLabel,
            classes.inline
          )}
        >
          Guidance:{" "}
          {hasGuidancePercentage
            ? formatNumeral(guidance.percent, {
                style: "percent",
                maximumFractionDigits: 4,
              })
            : "N/A"}
          <ExplanatoryTooltip
            body={"message" in guidance ? guidance.message : ""}
            disabled={hasGuidancePercentage}
          >
            <span>
              <AssembleButton
                className={clsx(classes.applyElement, {
                  [classes.noGuidance]: !hasGuidancePercentage,
                })}
                size="small"
                variant="text"
                label={hasGuidancePercentage ? "Apply" : "Why not?"}
                onClick={applyGuidance}
                disabled={!hasGuidancePercentage}
              />
            </span>
          </ExplanatoryTooltip>
        </Typography>
      ) : null}
    </TableCell>
  );
}
