import { createContext, ReactNode, useContext, useState } from "react";
import { BulkActionsBar_participant as Participant } from "src/__generated__/graphql";

type BulkActionsParticipant = Participant & {
  isSelected: boolean;
  // track the index of the participant in the original array
  // so that context updates are in sync with most recent data
  index: number;
};
type BulkActionsContextValue = {
  lastSelectedRowID: string | null;
  selectedCount: number;
  selectedParticipants: Map<number, BulkActionsParticipant>;
  setSelectedParticipants: (
    participantIds: number[],
    lastSelectedRowID: string
  ) => void;
  getSelectedParticipants: () => BulkActionsParticipant[];
  clearSelectedParticipants: () => void;
  showBudget: boolean;
  setShowBudget: (show: boolean) => void;
  selectOrClearAll: () => void;
};

export const BulkActionsContext = createContext<BulkActionsContextValue>({
  lastSelectedRowID: null,
  selectedParticipants: new Map(),
  setSelectedParticipants: () => new Map(),
  clearSelectedParticipants: () => new Map(),
  selectedCount: 0,
  showBudget: true,
  setShowBudget: () => true,
  selectOrClearAll: () => true,
  getSelectedParticipants: () => [],
});

export const BulkActionsProvider = ({
  participants,
  children,
}: {
  participants: Participant[];
  children: ReactNode;
}): JSX.Element => {
  const [selected, setSelected] = useState<number>(0);
  const [showBudget, setShowBudget] = useState(true);
  const [selectedParticipants, setSelectedParticipants] = useState<
    Map<number, BulkActionsParticipant>
  >(
    new Map(
      participants.map((participant, index) => [
        participant.subjectId,
        { ...participant, isSelected: false, index },
      ])
    )
  );

  // to enable cmd key selection in the condensed table
  const [lastSelectedRowID, setLastSelectedRowID] = useState<string | null>(
    null
  );

  const handleSelectedParticipants = (
    participantIds: number[],
    lastSelectedRowID: string
  ) => {
    // single row selection
    if (participantIds.length === 1) {
      const currParticipant = selectedParticipants.get(participantIds[0]);
      if (currParticipant) {
        selectedParticipants.set(participantIds[0], {
          ...participants[currParticipant.index],
          isSelected: !currParticipant.isSelected,
          index: currParticipant.index,
        });
      }
      // treat multi-select as selected only
    } else {
      participantIds.forEach((participantId) => {
        const currParticipant = selectedParticipants.get(participantId);
        if (currParticipant) {
          selectedParticipants.set(participantId, {
            ...participants[currParticipant.index],
            isSelected: true,
            index: currParticipant.index,
          });
        }
      });
    }
    const selected = Array.from(selectedParticipants.values()).filter(
      (participant) => participant.isSelected
    ).length;
    setSelected(selected);
    setShowBudget(selected === 0);
    setLastSelectedRowID(lastSelectedRowID);
  };

  const handleClearSelectedParticipants = () => {
    setSelectedParticipants(
      new Map(
        participants.map((participant, index) => [
          participant.subjectId,
          { ...participant, isSelected: false, index },
        ])
      )
    );
    setSelected(0);
    setShowBudget(true);
    setLastSelectedRowID(null);
  };

  const handleSelectOrClearAll = () => {
    if (selectedParticipants.size === selected) {
      handleClearSelectedParticipants();
    } else {
      setSelectedParticipants(
        new Map(
          participants.map((part, index) => [
            part.subjectId,
            { ...part, isSelected: true, index },
          ])
        )
      );
      setSelected(selectedParticipants.size);
      const lastRowIndex = selectedParticipants.size - 1;
      setLastSelectedRowID(lastRowIndex.toString());
    }
  };

  const handleGetParticipants = () => {
    return Array.from(selectedParticipants.values()).filter(
      (participant) => participant.isSelected
    );
  };

  return (
    <BulkActionsContext.Provider
      value={{
        lastSelectedRowID,
        showBudget,
        setShowBudget,
        selectedCount: selected,
        selectedParticipants,
        setSelectedParticipants: handleSelectedParticipants,
        clearSelectedParticipants: handleClearSelectedParticipants,
        selectOrClearAll: handleSelectOrClearAll,
        getSelectedParticipants: handleGetParticipants,
      }}
    >
      {children}
    </BulkActionsContext.Provider>
  );
};

export function useBulkActionsData() {
  return useContext(BulkActionsContext);
}
