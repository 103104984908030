import { gql, useQuery } from "@apollo/client";
import { CompCycleNew } from "src/components/CompCycle/CompCycleWizard/CompCycleNew";
import { PhaseConfigurationProvider } from "src/components/PhaseConfiguration/usePhaseConfiguration";
import { CreateCompCycleQuery } from "../../__generated__/graphql";
import { useTrack } from "../../analytics";
import { ErrorView } from "../../components/ErrorView";
import { LoadingSpinner } from "../../components/LoadingSpinner";

export function CreateCompCycleBoundary(): JSX.Element {
  const { Track } = useTrack({ subArea: "New Comp Cycle" });

  const { data, loading } = useQuery<CreateCompCycleQuery>(
    CreateCompCycleBoundary.query
  );

  if (!data || !data.valuation) {
    return loading ? <LoadingSpinner /> : <ErrorView />;
  }

  return (
    <Track>
      <PhaseConfigurationProvider>
        <CompCycleNew valuation={data.valuation} />
      </PhaseConfigurationProvider>
    </Track>
  );
}

CreateCompCycleBoundary.query = gql`
  query CreateCompCycleQuery {
    valuation {
      id
      valuation
      fdso
    }
  }
`;
