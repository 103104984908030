import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import {
  CompCycleSettingsCloseCompCycle_compCycle as CompCycle,
  Noun,
} from "../../../__generated__/graphql";
import CsvDownloadIcon from "../../../assets/svgs/illustrations/download-csv.svg?react";
import { useCompleteCompCycle } from "../../../mutations/CompCycle";
import { GRAY_4, GRAY_6, WHITE } from "../../../theme";
import { AssembleTypography } from "../../AssembleTypography";
import { useAuth } from "../../Auth/AuthContext";
import { CompCycleCsvExportWrapper } from "../../CsvExport/CompCycleExportWrapper";
import Section from "../../Workflow/Section";
import { CompleteCompCycleButton } from "../Buttons/CompleteCompCycleButton";
import {
  CompleteReviewDialog,
  useCompleteReviewDialogState,
} from "../Dialogs/CompleteReviewDialog";
import { CompleteReviewDialogOpen } from "../Dialogs/CompleteReviewDialogOpen";

const useStyles = makeStyles((theme) => ({
  content: {
    left: "50%",
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(2),
    position: "absolute",
    transform: "translate(-50%)",
    width: theme.spacing(67),
  },
  contentInner: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(4),
  },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(2),
  },
  card: {
    background: WHITE,
    border: `1px solid ${GRAY_6}`,
    borderRadius: "5px",
    padding: theme.spacing(5),
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    rowGap: theme.spacing(2),
  },
  buttonBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    columnGap: theme.spacing(2),
    position: "relative",
  },
  cardButton: {
    left: "50%",
    position: "absolute",
    top: "-52px",
    translate: "-50% 0",
  },
}));

type Props = { compCycle: CompCycle };

export function CompCycleSettingsClose({ compCycle }: Props): JSX.Element {
  const classes = useStyles();
  const { permissions } = useAuth();
  const canCompleteCompCycle = permissions.canEditGlobal(Noun.CompCycle);
  const completeCompCycle = useCompleteCompCycle(compCycle.id);
  const [dialogState, setDialogState] = useCompleteReviewDialogState(
    compCycle.endedAt
  );

  return (
    <div className={classes.content}>
      {dialogState !== "open" && (
        <CompleteReviewDialog
          compCycle={compCycle}
          dialogState={dialogState}
          setDialogState={setDialogState}
        />
      )}
      {dialogState === "open" && (
        <CompleteReviewDialogOpen
          setDialogState={setDialogState}
          onSubmit={completeCompCycle}
          reviewCount={compCycle.awaitingReviewCount}
        />
      )}
      <Section
        active={true}
        header="Confirm and close your cycle."
        description={
          <>
            Once you're sure everything is good-to-go with your requests and
            recommendations, feel free to close the cycle out.
          </>
        }
      >
        <div className={classes.contentInner}>
          <div className={classes.cardContainer}>
            <div className={classes.card}>
              <div className={classes.cardContent}>
                <CsvDownloadIcon />
                <AssembleTypography
                  variant="productMicrocopy"
                  textColor={GRAY_4}
                >
                  You can download a copy of this cycle at any time.
                </AssembleTypography>
              </div>
            </div>
          </div>
          <div className={classes.buttonBar}>
            <CompCycleCsvExportWrapper
              compCycleId={compCycle.id}
              variant="button"
              compCycleName={compCycle.name}
              buttonProps={{
                size: "medium",
                variant: "outlined",
                label: "Download CSV",
                startIcon: undefined,
              }}
            />
            {canCompleteCompCycle && (
              <CompleteCompCycleButton
                compCycle={compCycle}
                setDialogState={setDialogState}
              />
            )}
          </div>
        </div>
      </Section>
    </div>
  );
}

CompCycleSettingsClose.fragments = {
  compCycle: gql`
    fragment CompCycleSettingsCloseCompCycle_compCycle on CompCycle {
      id
      name
      endedAt
      awaitingReviewCount
    }
  `,
};
