import { gql } from "@apollo/client";
import { getSalaryCashComp, isHourlyComp } from "@asmbl/shared/compensation";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { Money } from "@asmbl/shared/money";
import { formatNumeral } from "@asmbl/shared/utils";
import uniqBy from "lodash/uniqBy";
import {
  CompUnit,
  MatrixTypeEnum,
  CondensedTableSalaryMeritDifferenceCell2_participant as Participant,
  RecItemType,
} from "src/__generated__/graphql";
import { useCompStructure } from "src/components/CompStructureContext";
import { useFeatureFlags } from "src/components/FeatureContext";
import { getMeritGuidance } from "src/models/Guidance";
import { RED } from "src/theme";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { getMeritSalaryIncrease } from "../../../../models/CompRecommendation";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { useTableData } from "../Contexts/TableDataContext2";
import { DefaultCell } from "./DefaultCell";

type Props = {
  row: { original: Participant };
};

export function CondensedTableSalaryMeritDifferenceCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { compStructure } = useCompStructure();
  const { isEnabled } = useFeatureFlags();
  const showHourly =
    (isEnabled(FeatureFlag.HourlyEmployeesInCompCycles) &&
      compStructure?.allowHourlyEmployees) === true;
  const { matrices } = useTableData();

  const meritMatrices = (matrices ?? []).filter(
    (matrix) => matrix.type === MatrixTypeEnum.MERIT
  );

  const meritMatrix =
    meritMatrices.length > 1
      ? meritMatrices.find((matrix) => {
          return (matrix.eligibleParticipants as number[]).includes(
            employee.subjectId
          );
        })
      : meritMatrices[0];

  const matrixGuides =
    meritMatrix?.matrixGuides.flatMap((matrixGuide) => ({
      ...matrixGuide,
      perfRatingOptionId: matrixGuide.matrixPerfRatingOption.id,
      matrixRangeId: matrixGuide.matrixRange.id,
    })) ?? [];

  const perfRatingOptions = uniqBy(
    matrixGuides.flatMap((matrixGuide) => matrixGuide.matrixPerfRatingOption),
    "name"
  );

  const matrixRanges = uniqBy(
    matrixGuides.flatMap((matrixGuide) => matrixGuide.matrixRange),
    "rangeStart"
  );

  const guidanceValue = getMeritGuidance(
    {
      ...employee.subject,
      perfRating: employee.perfRating,
    },
    matrixGuides,
    matrixRanges,
    perfRatingOptions
  );

  const guidance = typeof guidanceValue === "number" ? guidanceValue : null;

  const pay = getSalaryCashComp(employee.subject.activeCashCompensation);

  if (!pay) return <DefaultCell />;

  const recItems = employee.compRecommendation?.latestSubmittedItems ?? [];
  const meritItem = recItems.find(
    (item) => item.recommendationType === RecItemType.MERIT_INCREASE
  );
  const isHourly = showHourly && isHourlyComp(meritItem?.unitType);

  const percent =
    meritItem?.unitType === CompUnit.PERCENT_OF_SALARY
      ? meritItem.recommendedPercentValue
      : calculatePercent(
          isHourly ? pay.hourlyCashEquivalent : pay.annualCashEquivalent,
          getMeritSalaryIncrease(
            recItems,
            pay,
            pay.annualCashEquivalent.currency,
            isHourly
          ).value
        );

  const difference = guidance != null ? (percent ?? 0) - guidance * 100 : null;
  return (
    <div>
      {guidance != null && difference !== null ? (
        <AssembleTruncatedTypography
          textColor={difference === 0 ? "inherit" : RED}
          align="right"
        >
          {difference >= 0 ? "+" : ""}
          {formatNumeral(difference / 100, {
            style: "percent",
            maximumFractionDigits: 2,
          })}
        </AssembleTruncatedTypography>
      ) : (
        <DefaultCell />
      )}
    </div>
  );
}

CondensedTableSalaryMeritDifferenceCell2.fragments = {
  participant: gql`
    fragment CondensedTableSalaryMeritDifferenceCell2_participant on CompCycleParticipant {
      subject {
        id
        activeEmployment {
          id
          positionId
        }
        activeCashCompensation(currencyCode: $currencyCode) {
          type
          annualCashEquivalent
          hourlyCashEquivalent
          unit
          employeeId
        }
        adjustedCashBands(currencyCode: $currencyCode) {
          id
          name
          bandPoints {
            id
            bandName
            name
            value {
              ... on CashValue {
                annualRate
                hourlyRate
                currencyCode
              }
            }
          }
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        latestSubmittedItems {
          id
          recommendationType
          recommendedPercentValue
          recommendedCashValue(currencyCode: $currencyCode)
          recommendedPercentValue
          unitType
        }
      }
      perfRating
      subjectId
    }
  `,
};

CondensedTableSalaryMeritDifferenceCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.SALARY_MERIT_GUIDANCE_DIFF
);
CondensedTableSalaryMeritDifferenceCell2.id =
  ColumnIds.SALARY_MERIT_GUIDANCE_DIFF;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableSalaryMeritDifferenceCell2,
  Header: CondensedTableSalaryMeritDifferenceCell2.Header,
  id: CondensedTableSalaryMeritDifferenceCell2.id,
  defaultCanSort: false,
};

CondensedTableSalaryMeritDifferenceCell2.column = column;

function calculatePercent(
  baseSalary?: Money | null,
  absolute?: number
): number {
  if (baseSalary == null || absolute == null) return 0;
  return baseSalary.value !== 0
    ? Math.round((absolute / baseSalary.value) * 10000) / 100
    : 0;
}
