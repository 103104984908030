import { useQuery } from "@apollo/client";
import {
  Box,
  Chip,
  LinearProgress,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { Autocomplete, AutocompleteGetTagProps } from "@material-ui/lab";
import { useNavigate } from "react-router-dom";
import { ChevronDownIcon } from "src/components/AssembleIcons/Brand/ChevronDownIcon";
import { AssemblePopper } from "src/components/AssemblePopper";
import { WHITE } from "src/theme";
import {
  GetDepartments,
  Position as PositionSchema,
} from "../../__generated__/graphql";
import { ComputedDepartment } from "../../models/ComputedDepartment";
import { DEPARTMENTS_QUERY } from "../../queries";
import { DepartmentListDepartmentCreator } from "./DepartmentsListDepartmentCreator";
import { DepartmentsListTable } from "./DepartmentsListTable";

type Position = Pick<PositionSchema, "id" | "name">;

const useStyles = makeStyles(() => ({
  organizationHeader: {
    height: "48px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  search: {
    background: WHITE,
    width: "420px",
    position: "relative",
    margin: "auto",
    left: "-100px",
  },
  popUpIndicator: {
    height: "100%",
    transition: "transform cubic-bezier(0.4, 0, 0.2, 1) 275ms",
  },
  aboveTable: {
    display: "flex",
    alignItems: "start",
  },
  deptNumber: {
    width: "200px",
  },
  clamped: {
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 2,
    overflow: "hidden",
  },
}));

export function DepartmentsList(): JSX.Element {
  const classes = useStyles();
  const navigate = useNavigate();

  const { data, loading, error } = useQuery<GetDepartments>(DEPARTMENTS_QUERY);

  if (!data) {
    if (loading) {
      return <LinearProgress />;
    }
    if (error) {
      return <p>{error.message}</p>;
    }
    return <p>Not found</p>;
  }

  const { departments, organization } = data;
  const computedDepartments = departments.map(
    (department) => new ComputedDepartment(department)
  );

  const positions: Position[] = departments.flatMap((department) =>
    department.ladders.flatMap((ladder) =>
      ladder.positions.map((position) => {
        return {
          id: position.id,
          name: `${ladder.name} - ${position.name}`,
        };
      })
    )
  );

  return (
    <Box px={5} py={4}>
      <Box className={classes.organizationHeader}>
        <Typography variant="h3">{organization.name}</Typography>
        <DepartmentListDepartmentCreator />
      </Box>
      <Box m={2} />
      <Box className={classes.aboveTable}>
        <Typography variant="subtitle1" className={classes.deptNumber}>
          {departments.length} departments
        </Typography>
        <Autocomplete
          fullWidth
          PopperComponent={AssemblePopper}
          className={classes.search}
          classes={{
            popupIndicator: classes.popUpIndicator,
          }}
          size="small"
          id="compare-search"
          options={positions}
          getOptionLabel={(department) => department.name}
          getOptionSelected={(prev, next) => prev.id === next.id}
          disableCloseOnSelect
          filterSelectedOptions
          onChange={(_event, value) => {
            if (value) {
              navigate(`/positions/${value.id}`);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Search positions"
              fullWidth
            />
          )}
          renderOption={(position: Position) => (
            <Box display="flex" flexDirection="row" alignItems="center">
              <Box />
              <span className={classes.clamped}>{position.name}</span>
            </Box>
          )}
          renderTags={(
            tagValue: Position[],
            getTagProps: AutocompleteGetTagProps
          ) =>
            tagValue.map((position, index) => {
              return (
                <Chip
                  key={position.id}
                  label={position.name}
                  {...getTagProps({ index })}
                  style={{
                    height: "1.5rem",
                    transition: "color 0s, background-color 0s",
                  }}
                />
              );
            })
          }
          popupIcon={<ChevronDownIcon inherit />}
        />
      </Box>
      <Box m={2} />
      <DepartmentsListTable departments={computedDepartments} />
    </Box>
  );
}
