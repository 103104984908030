import { gql } from "@apollo/client";
import { CondensedTableLocationGroupCell2_participant as Participant } from "../../../../__generated__/graphql";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { LARGE_COL_WIDTH } from "./dimensions";

type Props = {
  row: { original: Participant };
};

export function CondensedTableLocationGroupCell2({
  row: { original: employee },
}: Props): JSX.Element {
  return (
    <AssembleTruncatedTypography lines={2}>
      {employee.subject.location?.name ?? "-"}
    </AssembleTruncatedTypography>
  );
}

CondensedTableLocationGroupCell2.fragments = {
  participant: gql`
    fragment CondensedTableLocationGroupCell2_participant on CompCycleParticipant {
      subject {
        location {
          id
          name
          marketId
        }
      }
    }
  `,
};

CondensedTableLocationGroupCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.LOCATION_GROUP
);
CondensedTableLocationGroupCell2.id = ColumnIds.LOCATION_GROUP;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableLocationGroupCell2,
  Header: CondensedTableLocationGroupCell2.Header,
  id: CondensedTableLocationGroupCell2.id,
  width: LARGE_COL_WIDTH,
};
CondensedTableLocationGroupCell2.column = column;
