import { FetchResult, gql, useMutation } from "@apollo/client";
import { useCallback } from "react";
import {
  BuildEmptyMatrix,
  BuildEmptyMatrixVariables,
  CalculatePotentialParticipants,
  CalculatePotentialParticipantsVariables,
  DeleteMatrix,
  DeleteMatrixVariables,
  MatrixTypeEnum,
  PotentialParticipantsInput,
  SaveMatrix,
  SaveMatrixVariables,
  UpdateMatrixEligibilityRules,
  UpdateMatrixEligibilityRulesInput,
  UpdateMatrixEligibilityRulesVariables,
  UpdateMatrixInput,
  UpdateMatrixName,
  UpdateMatrixNameInput,
  UpdateMatrixNameVariables,
} from "src/__generated__/graphql";

const NEW_MATRIX_OPTION = gql`
  fragment NewMatrix_matrixPerfRatingOption on MatrixPerfRatingOption {
    id
    name
    rank
    organizationId
  }
`;

const NEW_MATRIX_RANGE = gql`
  fragment NewMatrix_matrixRange on MatrixRange {
    id
    rangeStart
    organizationId
  }
`;

const NEW_MATRIX_GUIDE = gql`
  ${NEW_MATRIX_OPTION}
  ${NEW_MATRIX_RANGE}
  fragment NewMatrix_matrixGuide on MatrixGuide {
    id
    percent
    matrixRange {
      id
      ...NewMatrix_matrixRange
    }
    matrixPerfRatingOption {
      id
      ...NewMatrix_matrixPerfRatingOption
    }
    matrixId
    matrixPerfRatingOptionId
    matrixRangeId
    organizationId
  }
`;

const NEW_MATRIX = gql`
  ${NEW_MATRIX_GUIDE}
  fragment NewMatrix_matrix on Matrix {
    id
    type
    name
    eligibilityRules
    eligibleParticipants
    estimatedCost
    siblingMatrices {
      id
      type
    }
    matrixGuides {
      id
      ...NewMatrix_matrixGuide
    }

    bonusGuidanceSettings {
      id
      matrixId
      isDimensionEnabled
      customDimensionName
      customDimensionValueType
    }
  }
`;

const BUILD_EMPTY_MATRIX = gql`
  ${NEW_MATRIX}
  mutation BuildEmptyMatrix($data: BuildEmptyMatrixInput!) {
    buildEmptyMatrix(data: $data) {
      id
      ...NewMatrix_matrix
    }
  }
`;

export function useBuildEmptyMatrix(
  compCycleId: number
): (types: MatrixTypeEnum[]) => Promise<FetchResult<BuildEmptyMatrix>> {
  const [buildEmptyMatrix] = useMutation<
    BuildEmptyMatrix,
    BuildEmptyMatrixVariables
  >(BUILD_EMPTY_MATRIX);

  return useCallback(
    (types: MatrixTypeEnum[]) =>
      buildEmptyMatrix({ variables: { data: { compCycleId, types } } }),
    [buildEmptyMatrix, compCycleId]
  );
}

const UPDATE_MATRIX_NAME = gql`
  mutation UpdateMatrixName($data: UpdateMatrixNameInput!) {
    updateMatrixName(data: $data) {
      id
      name
    }
  }
`;

export function useUpdateMatrixName(): (
  data: UpdateMatrixNameInput
) => Promise<FetchResult<UpdateMatrixName>> {
  const [updateMatrixName] = useMutation<
    UpdateMatrixName,
    UpdateMatrixNameVariables
  >(UPDATE_MATRIX_NAME);

  return useCallback(
    (data) => updateMatrixName({ variables: { data } }),
    [updateMatrixName]
  );
}

const UPDATE_MATRIX_ELIGIBILITY_RULES = gql`
  mutation UpdateMatrixEligibilityRules(
    $data: UpdateMatrixEligibilityRulesInput!
  ) {
    updateMatrixEligibilityRules(data: $data)
  }
`;

export function useUpdateMatrixEligibilityRules(): (
  data: UpdateMatrixEligibilityRulesInput
) => Promise<FetchResult<UpdateMatrixEligibilityRules>> {
  const [updateMatrixEligibilityRules] = useMutation<
    UpdateMatrixEligibilityRules,
    UpdateMatrixEligibilityRulesVariables
  >(UPDATE_MATRIX_ELIGIBILITY_RULES);

  return useCallback(
    (data) => updateMatrixEligibilityRules({ variables: { data } }),
    [updateMatrixEligibilityRules]
  );
}

const DELETE_MATRIX = gql`
  mutation DeleteMatrix($matrixId: Int!) {
    deleteMatrix(matrixId: $matrixId)
  }
`;

export function useDeleteMatrix(): (
  matrixId: number
) => Promise<FetchResult<{ deleteMatrix: boolean }>> {
  const [deleteMatrix] = useMutation<DeleteMatrix, DeleteMatrixVariables>(
    DELETE_MATRIX
  );

  return useCallback(
    (matrixId) => deleteMatrix({ variables: { matrixId } }),
    [deleteMatrix]
  );
}

const CALCULATE_POTENTIAL_PARTICIPANTS = gql`
  mutation CalculatePotentialParticipants($data: PotentialParticipantsInput!) {
    calculatePotentialParticipants(data: $data)
  }
`;

export function useCalculatePotentialParticipants(): {
  execute: (
    data: PotentialParticipantsInput
  ) => Promise<FetchResult<CalculatePotentialParticipants>>;
  loading: boolean;
} {
  const [calculatePotentialParticipants, { loading }] = useMutation<
    CalculatePotentialParticipants,
    CalculatePotentialParticipantsVariables
  >(CALCULATE_POTENTIAL_PARTICIPANTS);

  const execute = useCallback(
    (data: PotentialParticipantsInput) =>
      calculatePotentialParticipants({
        variables: {
          data,
        },
      }),
    [calculatePotentialParticipants]
  );

  return { execute, loading };
}

const SAVE_MATRIX = gql`
  mutation SaveMatrix($data: UpdateMatrixInput!) {
    updateMatrix(data: $data) {
      id
    }
  }
`;

export function useSaveMatrix(): (
  data: UpdateMatrixInput
) => Promise<FetchResult<SaveMatrix>> {
  const [saveMatrix] = useMutation<SaveMatrix, SaveMatrixVariables>(
    SAVE_MATRIX
  );

  return useCallback(
    (data: UpdateMatrixInput) => saveMatrix({ variables: { data } }),
    [saveMatrix]
  );
}
