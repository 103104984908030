import { gql } from "@apollo/client";
import { roundNumber } from "@asmbl/shared/utils";
import {
  makeStyles,
  Switch,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import clsx from "clsx";
import { useSnackbar } from "notistack";
import {
  MatrixCard_matrixPerfRatingOption,
  MatrixTypeEnum,
} from "src/__generated__/graphql";
import { InfoIcon } from "src/components/AssembleIcons/Brand/InfoIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import { MatrixCell } from "src/components/CompCycle/GuidanceAndBudget/BonusGuidanceAndBudget/MatrixCell";
import { GRAY_1, GRAY_3, GRAY_4, GRAY_6, GRAY_8, PURPLE_1 } from "src/theme";
import {
  PerfRatingOptionDraft,
  UseMatrixMatrix,
} from "../../MultipleMeritMatrices/useMultipleMatrices";
import {
  getCompanyWeighting,
  getCustomWeighting,
  getIndividualWeighting,
} from "../utils";
import { useBonusGuidanceAndBudget } from "./useBonusGuidanceAndBudget";

const useStyles = makeStyles((theme) => ({
  column: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  columnHeader: {
    width: "100%",

    padding: theme.spacing(1.5),
    border: `1px solid ${GRAY_6}`,

    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  columnHeaderTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: theme.spacing(1),
  },
  companyAttainmentTable: {
    height: "100%",
  },
  table: {
    borderSpacing: 0,
  },
  wide: {
    width: "75%",
  },
  shortCell: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  weightingRow: {
    height: "32px !important",
  },
  individualPerfTable: {
    height: "100%",
    borderRight: "none",
  },
  noRightBorder: {
    borderRight: `none !important`,
  },
  tableCell: {
    borderCollapse: "collapse",
    border: `1px solid ${GRAY_6}`,
    borderTop: `none !important`,
  },

  companyAttainmentRowContainer: {
    height: "100%",
  },
  companyAttainmentCellContainer: {
    height: "inherit",
  },
  companyAttainmentCell: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2.5),
  },
  perfRatingOptionNameCell: {
    backgroundColor: GRAY_8,
  },
  companyAttainmentCopyCell: {
    textAlign: "center",
  },
}));

type Props = {
  type: MatrixTypeEnum;
  title: string;
  description: string;
  perfRatingOptions?: (
    | PerfRatingOptionDraft
    | MatrixCard_matrixPerfRatingOption
  )[];
  matrix?: UseMatrixMatrix;
};

export function MatrixColumn({
  type,
  title,
  description,
  perfRatingOptions,
  matrix,
}: Props): JSX.Element {
  const {
    onUpdateGuidanceRange,
    onUpdateMatrixGuide,
    matrices: { selectedMatrix, onToggleDimension },
    modals: {
      companyAttainment: { setOpen: setCompanyAttainmentOpen },
    },
    organizationCompCyclePerfRating,
  } = useBonusGuidanceAndBudget();

  const { enqueueSnackbar } = useSnackbar();

  const updateWeighting = (rangeId: string | number, newValue: number) => {
    const individualWeighting =
      matrix?.id === selectedMatrix?.bonusIndividual.id
        ? newValue
        : getIndividualWeighting(selectedMatrix);

    const companyWeighting =
      matrix?.id === selectedMatrix?.bonusCompany.id
        ? newValue
        : getCompanyWeighting(selectedMatrix);

    const otherWeighting =
      matrix?.id === selectedMatrix?.bonusOther.id
        ? newValue
        : getCustomWeighting(selectedMatrix);

    const total = companyWeighting + individualWeighting + otherWeighting;

    if (total > 100) {
      enqueueSnackbar("Total weighting cannot exceed 100%", {
        variant: "error",
      });
    }

    onUpdateGuidanceRange(rangeId, newValue);
  };

  const classes = useStyles();
  const matrixRange = (matrix?.matrixGuides ?? [])[0]?.matrixRange;

  const handleChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const bgSettingsId = matrix?.bonusGuidanceSettings?.id;

    if (checked && type === MatrixTypeEnum.BONUS_GUIDANCE_COMPANY_PERFORMANCE) {
      setCompanyAttainmentOpen(true);
    }

    if (bgSettingsId == null) return;

    onToggleDimension(bgSettingsId, checked);
  };

  return (
    <div className={classes.column}>
      <div
        className={clsx(classes.columnHeader, {
          [classes.noRightBorder]:
            type === MatrixTypeEnum.BONUS_GUIDANCE_INDIVIDUAL_PERFORMANCE,
        })}
      >
        <div className={clsx(classes.columnHeaderTitle)}>
          <AssembleTypography variant="productParagraphLarge">
            {title}
          </AssembleTypography>
          <Tooltip title={description}>
            <span>
              <InfoIcon color={PURPLE_1} width="16px" height="16px" />
            </span>
          </Tooltip>
        </div>
        <Switch
          checked={matrix?.bonusGuidanceSettings?.isDimensionEnabled}
          onChange={handleChange}
        />
      </div>

      <table
        className={clsx(classes.table, {
          [classes.companyAttainmentTable]:
            type === MatrixTypeEnum.BONUS_GUIDANCE_COMPANY_PERFORMANCE,
          [classes.individualPerfTable]:
            type === MatrixTypeEnum.BONUS_GUIDANCE_INDIVIDUAL_PERFORMANCE,
        })}
      >
        <TableBody>
          <TableRow className={classes.weightingRow}>
            <TableCell
              className={clsx(
                classes.tableCell,
                classes.wide,
                classes.shortCell,
                classes.noRightBorder
              )}
            >
              <AssembleTypography
                variant="productEyebrowSmall"
                textColor={GRAY_3}
              >
                WEIGHTING
              </AssembleTypography>
            </TableCell>
            <MatrixCell
              className={clsx(classes.tableCell, {
                [classes.noRightBorder]:
                  type === MatrixTypeEnum.BONUS_GUIDANCE_INDIVIDUAL_PERFORMANCE,
              })}
              width="narrow"
              height="short"
              defaultValue={matrixRange.rangeStart}
              disabled={!matrix?.bonusGuidanceSettings?.isDimensionEnabled}
              onChange={(e) => {
                updateWeighting(
                  // There should only be one range
                  matrixRange.id,
                  roundNumber(Number(e.target.value))
                );
              }}
            />
          </TableRow>
          {type === MatrixTypeEnum.BONUS_GUIDANCE_COMPANY_PERFORMANCE && (
            <TableRow className={classes.companyAttainmentRowContainer}>
              <TableCell
                className={clsx(
                  classes.tableCell,
                  classes.wide,
                  classes.companyAttainmentCellContainer
                )}
                colSpan={2}
              >
                <div className={classes.companyAttainmentCell}>
                  {organizationCompCyclePerfRating != null && (
                    <>
                      <AssembleTypography
                        variant="productParagraphSmall"
                        textColor={GRAY_1}
                      >
                        Company Attainment
                      </AssembleTypography>
                      <AssembleTypography variant="h5" textColor={GRAY_4}>
                        {organizationCompCyclePerfRating}%
                      </AssembleTypography>
                    </>
                  )}
                  <div className={classes.companyAttainmentCopyCell}>
                    <AssembleTypography
                      variant="productMicrocopy"
                      textColor={GRAY_4}
                    >
                      {organizationCompCyclePerfRating == null ? (
                        <em>
                          Set your company's performance target percentage in
                          the guidance settings.
                        </em>
                      ) : (
                        <em>
                          Edit your company's attainment percentage by clicking
                          the button at the top of the bonus guidance page
                        </em>
                      )}
                    </AssembleTypography>
                  </div>
                </div>
              </TableCell>
            </TableRow>
          )}

          {perfRatingOptions?.map((perfRatingOption) => {
            const existingMatrixGuide = matrix?.matrixGuides?.find(
              (matrixGuide) =>
                matrixGuide.matrixPerfRatingOptionId === perfRatingOption.id
            );

            return (
              <TableRow key={`${title}-option-${perfRatingOption.id}-row`}>
                <TableCell
                  className={clsx(
                    classes.tableCell,
                    classes.wide,
                    classes.perfRatingOptionNameCell,
                    {
                      [classes.noRightBorder]:
                        type ===
                        MatrixTypeEnum.BONUS_GUIDANCE_INDIVIDUAL_PERFORMANCE,
                    }
                  )}
                >
                  <AssembleTypography
                    variant="productParagraphSmall"
                    textColor={GRAY_1}
                  >
                    {perfRatingOption.name}
                  </AssembleTypography>
                </TableCell>
                <MatrixCell
                  className={clsx(classes.tableCell, {
                    [classes.noRightBorder]:
                      type ===
                      MatrixTypeEnum.BONUS_GUIDANCE_INDIVIDUAL_PERFORMANCE,
                  })}
                  width="narrow"
                  defaultValue={
                    existingMatrixGuide?.percent == null
                      ? undefined
                      : Number((existingMatrixGuide.percent * 100).toFixed(0))
                  }
                  disabled={!matrix?.bonusGuidanceSettings?.isDimensionEnabled}
                  onChange={(e) => {
                    if (matrix?.id == null) return;

                    if (existingMatrixGuide != null) {
                      onUpdateMatrixGuide({
                        newValue: Number.parseInt(e.target.value).toFixed(0),
                        currentValue: existingMatrixGuide.percent,
                        matrixGuideId: existingMatrixGuide.id,
                        matrixId: matrix.id,
                      });
                    } else {
                      onUpdateMatrixGuide({
                        newValue: Number.parseInt(e.target.value).toFixed(0),
                        currentValue: 0,
                        perfRatingOptionId: perfRatingOption.id,
                        matrixId: matrix.id,
                      });
                    }
                  }}
                />
              </TableRow>
            );
          })}
        </TableBody>
      </table>
    </div>
  );
}

MatrixColumn.fragments = {
  matrixGuide: gql`
    fragment MatrixColumn_matrixGuide on MatrixGuide {
      id
      percent
      matrixRange {
        id
        rangeStart
        organizationId
      }
      matrixPerfRatingOption {
        id
        name
        rank
        organizationId
      }
      matrixId
      matrixPerfRatingOptionId
      matrixRangeId
      organizationId
    }
  `,
};
