import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import { ManualUpload_employee as Employee } from "src/__generated__/graphql";
import { ImportIcon } from "src/components/AssembleIcons/Brand/ImportIcon";
import { AssembleTypography } from "src/components/AssembleTypography";
import { ActualVariableCompImportButton } from "src/components/Settings/ActualVariableComp/ActualVariableCompImportButton";
import { GRAY_3, PURPLE_1 } from "src/theme";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),

    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2),
  },
}));

type Props = { employees: Employee[] };

export function ManualUpload({ employees }: Props): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AssembleTypography variant="productParagraphSmall" textColor={GRAY_3}>
        <em>If you don't want to configure automatic rules, you can</em>
      </AssembleTypography>

      <ActualVariableCompImportButton
        employees={employees}
        buttonOverrideProps={{
          variant: "text",
          size: "medium",
          label: "Upload actual compensation guidance for each employee",
          endIcon: <ImportIcon color={PURPLE_1} />,
        }}
      />
    </div>
  );
}

ManualUpload.fragments = {
  employee: gql`
    ${ActualVariableCompImportButton.fragments.employee}
    fragment ManualUpload_employee on Employee {
      id
      ...ActualVariableCompImportButton_employee
    }
  `,
};
