import { gql } from "@apollo/client";
import { makeStyles, Switch } from "@material-ui/core";
import { GRAY_5, WHITE } from "src/theme";
import { useBonusGuidanceAndBudget } from "./useBonusGuidanceAndBudget";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: theme.spacing(2),
  },
  prefillContainer: {
    width: "50%",
    padding: theme.spacing(2),

    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",

    backgroundColor: WHITE,
    border: `solid 1px ${GRAY_5}`,
    borderRadius: 5,
  },
}));

export function PrefillWithGuidance(): JSX.Element {
  const classes = useStyles();
  const { shouldPrefillWithGuidance, onToggleShouldPrefillWithGuidance } =
    useBonusGuidanceAndBudget();

  const handleSwitch = () => {
    void onToggleShouldPrefillWithGuidance(!shouldPrefillWithGuidance);
  };

  return (
    <div className={classes.root} onClick={handleSwitch}>
      <div className={classes.prefillContainer}>
        Pre-fill bonus adjustments with provided guidance
        <Switch checked={shouldPrefillWithGuidance} />
      </div>
    </div>
  );
}

PrefillWithGuidance.fragments = {
  compCycle: gql`
    fragment PrefillWithGuidance_compCycle on CompCycle2 {
      id
      bonusGuidanceRecommendationsPrefill
    }
  `,
};
