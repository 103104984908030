import { gql } from "@apollo/client";
import {
  getSalaryCashComp,
  isHourlyComp,
  isHourlyType,
} from "@asmbl/shared/compensation";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { add, calculateCompaRatio } from "@asmbl/shared/money";
import { formatNumeral } from "@asmbl/shared/utils";
import { makeStyles, TableCell, TableRow, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useCompStructure } from "src/components/CompStructureContext";
import { useFeatureFlags } from "src/components/FeatureContext";
import { getSalaryBand } from "src/models/Band";
import {
  NewSalaryRow_employee,
  NewSalaryRow_position,
} from "../../../../__generated__/graphql";
import { ADJUSTED_CASH_BAND_FIELDS } from "../../../../fragments";
import { isBandPointDefined } from "../../../../models/BandPoint";
import { getDraftSalaryIncrease } from "../../../../models/CompRecommendation";
import { getSimpleCashLabel } from "../../../../models/Currency";
import { GRAY_1, GRAY_4, GRAY_6 } from "../../../../theme";
import { CondensedBandVisualization } from "../../../CompSlider/CondensedBandVisualization";
import { NullCompSlider } from "../../../CompSlider/NullCompSlider";
import { useCurrencies } from "../../../CurrenciesContext";
import { useCompRecData } from "../../CompCycleSingleRecModal/CompRecommendationContext";
import { useConnectorStyles } from "./styles";

interface Props {
  employee: NewSalaryRow_employee;
  promotedPosition: NewSalaryRow_position | null;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTableCell-body": {
      border: "none",
      paddingBlock: theme.spacing(0.3),
    },
  },
  newPlacementContainer: {
    display: "flex",
    alignItems: "center",
  },
  compSliderBox: {
    flex: 1,
  },
  textCell: {
    borderTop: `1px solid ${GRAY_6} !important`,
  },
  salaryAlign: {
    display: "inline-flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  rowLabel: {
    fontWeight: 700,
    fontSize: "13px",
    color: GRAY_1,
  },
  compaRatio: {
    color: GRAY_4,
    whiteSpace: "nowrap",
  },
  salaryText: {
    fontWeight: 700,
    fontSize: "0.8125rem",
    textAlign: "right",
  },
  secondarySalaryText: {
    fontWeight: 400,
    color: GRAY_4,
  },
}));

export function NewSalaryRow({
  employee,
  promotedPosition,
}: Props): JSX.Element {
  const classes = useStyles();
  const connectorStyle = useConnectorStyles();
  const { defaultCurrencyCode } = useCurrencies();
  const { compStructure } = useCompStructure();
  const { isEnabled } = useFeatureFlags();
  const { revisedRecommendation } = useCompRecData();
  const workingHoursPerYear = compStructure?.workingHoursPerYear;
  const salaryComp = getSalaryCashComp(employee.activeCashCompensation);

  const { hourlyCashEquivalent, annualCashEquivalent, unitType } =
    getDraftSalaryIncrease(
      revisedRecommendation,
      salaryComp?.annualCashEquivalent.currency ?? defaultCurrencyCode,
      employee.activeEmployment?.payPeriod,
      salaryComp,
      workingHoursPerYear ?? 0
    );

  const hourlyEnabled =
    compStructure?.allowHourlyEmployees === true &&
    isEnabled(FeatureFlag.HourlyEmployeesInCompCycles);
  const isHourly =
    isHourlyType(promotedPosition?.type) || isHourlyComp(unitType);

  const showHourly = hourlyEnabled && isHourly;

  const newPay =
    salaryComp != null
      ? add(salaryComp.annualCashEquivalent, annualCashEquivalent)
      : salaryComp;
  const newHourlyPay =
    salaryComp != null
      ? add(salaryComp.hourlyCashEquivalent, hourlyCashEquivalent)
      : salaryComp;

  const adjustedSalaryBand = promotedPosition
    ? getSalaryBand(promotedPosition.adjustedCashBands)
    : getSalaryBand(employee.adjustedCashBands);

  const newCompaRatio =
    newPay &&
    adjustedSalaryBand &&
    calculateCompaRatio(
      adjustedSalaryBand.bandPoints.map((bp) => bp.annualCashEquivalent),
      newPay
    );

  return (
    <TableRow aria-level={3} className={classes.root}>
      <TableCell role="gridcell" padding="none">
        <div className={connectorStyle.line} />
      </TableCell>
      <TableCell
        role="gridcell"
        colSpan={7}
        padding="none"
        className={classes.textCell}
      >
        <Typography className={classes.rowLabel}>
          {showHourly ? "New Pay" : "New Salary"}
        </Typography>
      </TableCell>
      <TableCell role="gridcell" className={classes.textCell}>
        {showHourly ? (
          <div className={classes.salaryAlign}>
            <Typography
              className={clsx(classes.salaryText, classes.secondarySalaryText)}
            >
              ({newPay && getSimpleCashLabel(newPay)}/yr)
            </Typography>
            <Typography className={classes.salaryText}>
              {newHourlyPay && getSimpleCashLabel(newHourlyPay, false, true)}
            </Typography>
          </div>
        ) : (
          <Typography className={classes.salaryText}>
            {newPay && getSimpleCashLabel(newPay)}
          </Typography>
        )}
      </TableCell>
      <TableCell role="gridcell" className={classes.textCell}>
        <div className={classes.newPlacementContainer}>
          <div className={classes.compSliderBox}>
            {newPay &&
            adjustedSalaryBand &&
            adjustedSalaryBand.bandPoints.some(isBandPointDefined) ? (
              <CondensedBandVisualization
                value={(showHourly ? newHourlyPay : newPay) ?? newPay}
                bandPoints={adjustedSalaryBand.bandPoints}
                position={
                  promotedPosition ??
                  employee.activeEmployment?.position ??
                  null
                }
                size="wide"
                outOfRangeStyle="band"
                showHourly={showHourly}
              />
            ) : (
              <NullCompSlider variant="condensedWide" />
            )}
          </div>
        </div>
      </TableCell>
      <TableCell role="gridcell" className={classes.textCell}>
        <div className={classes.compaRatio}>
          {newCompaRatio != null
            ? `New C/R ${formatNumeral(newCompaRatio, {
                style: "percent",
                maximumFractionDigits: 0,
              })}`
            : ""}
        </div>
      </TableCell>
      <TableCell role="gridcell" className={classes.textCell} colSpan={2} />
      <TableCell role="gridcell" />
    </TableRow>
  );
}

NewSalaryRow.fragments = {
  employee: gql`
    ${ADJUSTED_CASH_BAND_FIELDS}
    fragment NewSalaryRow_employee on Employee2 {
      id
      activeCashCompensation(currencyCode: $currencyCode) {
        employeeId
        type
        activeAt
        annualCashEquivalent
        hourlyCashEquivalent
        unit
      }
      activeEmployment {
        id
        payPeriod
        position {
          id
          name
          level
          type
          ladder {
            id
            name
            department {
              id
              name
            }
          }
        }
      }
      adjustedCashBands {
        id
        ...AdjustedCashBandFields
      }
    }
  `,
  position: gql`
    ${ADJUSTED_CASH_BAND_FIELDS}
    fragment NewSalaryRow_position on Position {
      id
      name
      level
      type
      ladder {
        id
        name
        department {
          id
          name
        }
      }
      adjustedCashBands(
        currencyCode: $currencyCode
        marketId: $marketId
        locationGroupId: $locationGroupId
      ) {
        id
        ...AdjustedCashBandFields
      }
    }
  `,
};
