import { gql } from "@apollo/client";
import { formatNumeral } from "@asmbl/shared/utils";
import uniqBy from "lodash/uniqBy";
import {
  MatrixTypeEnum,
  CondensedTableSalaryMeritGuidanceCell2_participant as Participant,
} from "src/__generated__/graphql";
import { getMeritGuidance } from "src/models/Guidance";
import { AssembleTruncatedTypography } from "../../../../components/AssembleTruncatedTypography";
import { ColumnComponent2 } from "../CondensedTable/CondensedTableInner";
import { ColumnIds, ColumnIdsToHeaders } from "../Contexts/ColumnOrderContext";
import { useTableData } from "../Contexts/TableDataContext2";
import { DefaultCell } from "./DefaultCell";

type Props = {
  row: { original: Participant };
};

export function CondensedTableSalaryMeritGuidanceCell2({
  row: { original: employee },
}: Props): JSX.Element {
  const { matrices } = useTableData();

  const meritMatrices = (matrices ?? []).filter(
    (matrix) => matrix.type === MatrixTypeEnum.MERIT
  );

  const meritMatrix =
    meritMatrices.length > 1
      ? meritMatrices.find((matrix) => {
          return (matrix.eligibleParticipants as number[]).includes(
            employee.subjectId
          );
        })
      : meritMatrices[0];

  const matrixGuides =
    meritMatrix?.matrixGuides.flatMap((matrixGuide) => ({
      ...matrixGuide,
      perfRatingOptionId: matrixGuide.matrixPerfRatingOption.id,
      matrixRangeId: matrixGuide.matrixRange.id,
    })) ?? [];

  const perfRatingOptions = uniqBy(
    matrixGuides.flatMap((matrixGuide) => matrixGuide.matrixPerfRatingOption),
    "name"
  );

  const matrixRanges = uniqBy(
    matrixGuides.flatMap((matrixGuide) => matrixGuide.matrixRange),
    "rangeStart"
  );

  const guidanceValue = getMeritGuidance(
    {
      ...employee.subject,
      perfRating: employee.perfRating,
    },
    matrixGuides,
    matrixRanges,
    perfRatingOptions
  );

  const guidance = typeof guidanceValue === "number" ? guidanceValue : null;

  return (
    <div>
      {guidance != null ? (
        <AssembleTruncatedTypography align="right">
          {formatNumeral(guidance, {
            style: "percent",
            maximumFractionDigits: 2,
          })}
        </AssembleTruncatedTypography>
      ) : (
        <DefaultCell />
      )}
    </div>
  );
}

CondensedTableSalaryMeritGuidanceCell2.fragments = {
  participant: gql`
    fragment CondensedTableSalaryMeritGuidanceCell2_participant on CompCycleParticipant {
      subject {
        activeEmployment {
          id
          positionId
        }
        activeCashCompensation(currencyCode: $currencyCode) {
          type
          annualCashEquivalent
          hourlyCashEquivalent
          unit
          employeeId
        }
        adjustedCashBands(currencyCode: $currencyCode) {
          id
          name
          bandPoints {
            id
            bandName
            name
            value {
              ... on CashValue {
                annualRate
                hourlyRate
                currencyCode
              }
            }
          }
        }
      }
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        allSubmittedItems {
          id
        }
        latestSubmittedItems {
          id
          recommendationType
          recommendedCashValue(currencyCode: $currencyCode)
          unitType
        }
      }

      perfRating
      subjectId
    }
  `,
};

CondensedTableSalaryMeritGuidanceCell2.Header = ColumnIdsToHeaders.get(
  ColumnIds.SALARY_MERIT_GUIDANCE
);
CondensedTableSalaryMeritGuidanceCell2.id = ColumnIds.SALARY_MERIT_GUIDANCE;

const column: ColumnComponent2["column"] = {
  Cell: CondensedTableSalaryMeritGuidanceCell2,
  Header: CondensedTableSalaryMeritGuidanceCell2.Header,
  id: CondensedTableSalaryMeritGuidanceCell2.id,
  defaultCanSort: false,
};

CondensedTableSalaryMeritGuidanceCell2.column = column;
