import { isHourlyType } from "@asmbl/shared/compensation";
import { CashBandName, CurrencyCode } from "@asmbl/shared/constants";
import { formatNumeral } from "@asmbl/shared/utils";
import { TextField, Typography, makeStyles } from "@material-ui/core";
import NumberFormat, {
  NumberFormatValues,
  SourceInfo,
} from "react-number-format";
import { PositionType } from "src/__generated__/graphql";
import { currencySymbol } from "../../../models/Currency";
import { GRAY_1, GRAY_4, GRAY_5, PURPLE_2 } from "../../../theme";
import { BandName } from "../types";

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: "4px",
    border: `1px solid ${GRAY_5}`,
  },
  inputAdornment: {
    "&:focus-within, &.Mui-focused": {
      "& $symbolAdornment": {
        color: PURPLE_2,
      },
    },
  },
  symbolAdornment: {
    color: GRAY_1,
  },
  endAdornment: {
    color: GRAY_4,
  },
}));

type OfferInputPercentageOfSalaryProps = {
  handlePercent: (
    { floatValue }: NumberFormatValues,
    { source }: SourceInfo
  ) => void;
  percentInput: number | undefined;
  bandName: BandName;
};

export function OfferInputPercentageOfSalary({
  handlePercent,
  percentInput,
  bandName,
}: OfferInputPercentageOfSalaryProps): JSX.Element {
  const classes = useStyles();

  return (
    <NumberFormat
      data-cy={`${bandName}-input`}
      customInput={TextField}
      variant="outlined"
      onValueChange={handlePercent}
      value={percentInput}
      placeholder="Enter percentage"
      InputProps={{
        classes: { root: classes.root },
        className: classes.inputAdornment,
        endAdornment: (
          <Typography className={classes.symbolAdornment}>&nbsp;%</Typography>
        ),
      }}
      thousandSeparator
    />
  );
}

type OfferInputCashValueProps = {
  handleAbsolute: (
    { floatValue }: NumberFormatValues,
    { source }: SourceInfo
  ) => void;
  absoluteInput: number | undefined;
  currency: CurrencyCode;
  bandName: BandName;
  positionType: PositionType | undefined;
};

export function OfferInputCashValue({
  handleAbsolute,
  absoluteInput,
  currency,
  bandName,
  positionType,
}: OfferInputCashValueProps): JSX.Element {
  const classes = useStyles();

  return (
    <NumberFormat
      data-cy={`${bandName}-input`}
      customInput={TextField}
      variant="outlined"
      onValueChange={handleAbsolute}
      value={
        absoluteInput === undefined ? undefined : formatNumeral(absoluteInput)
      }
      placeholder="0"
      fullWidth
      InputProps={{
        classes: { root: classes.root },
        className: classes.inputAdornment,
        startAdornment: (
          <Typography className={classes.symbolAdornment}>
            {currencySymbol(currency)}&nbsp;
          </Typography>
        ),
        endAdornment: Boolean(
          bandName === CashBandName.SALARY && isHourlyType(positionType)
        ) && <Typography className={classes.endAdornment}>/hr</Typography>,
      }}
      thousandSeparator
      decimalScale={2}
    />
  );
}

type OfferInputUnitValueProps = {
  handleUnit: (
    { floatValue }: NumberFormatValues,
    { source }: SourceInfo
  ) => void;
  unitInput: number | undefined;
  error: boolean;
  bandName: BandName;
};

export function OfferInputUnitValue({
  handleUnit,
  unitInput,
  error,
  bandName,
}: OfferInputUnitValueProps): JSX.Element {
  const classes = useStyles();

  return (
    <NumberFormat
      data-cy={`${bandName}-input`}
      customInput={TextField}
      variant="outlined"
      onValueChange={handleUnit}
      value={unitInput === undefined ? undefined : formatNumeral(unitInput)}
      placeholder="  of units"
      error={error}
      fullWidth
      InputProps={{
        classes: { root: classes.root },
        className: classes.inputAdornment,
        startAdornment: (
          <Typography className={classes.symbolAdornment}>#&nbsp;</Typography>
        ),
      }}
      thousandSeparator
    />
  );
}
