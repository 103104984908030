import { gql } from "@apollo/client";
import { add, Money, subtract, zero } from "@asmbl/shared/money";
import { mapMaybe } from "@asmbl/shared/utils";
import { makeStyles, TableCell, TableRow, Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  TargetSummarizedRow_compCycleBudget as CompCycleBudget,
  TargetSummarizedRow_compCycleData as CompCycleData,
  TargetSummarizedRow_manager as Manager,
  RecItemType,
  TargetSummarizedRow_reports as Report,
} from "../../../../__generated__/graphql";
import { CompCycleDisplay } from "../../../../components/CompCycle/types";
import { useCurrencies } from "../../../../components/CurrenciesContext";
import { formatBudget } from "../../../../models/Budget";
import {
  calculateRequestsFromReports,
  formatRequest,
  formatVariance,
} from "../../../../models/CompRecommendation";
import { DV_RED, GRAY_4, GRAY_8 } from "../../../../theme";
import { IndentationCells } from "../../Budgets/Table/Connectors";
import { SummarizedNameCell } from "./SummarizedNameCell";

type Props = {
  manager: Manager | null;
  reports: Report[];
  rootBudget: CompCycleBudget | null;
  organizationName: string;
  varianceDisplay: CompCycleDisplay;
  compCycleData: CompCycleData;
  indentation: IndentationCells;
};

type BudgetKey = "targetCommission" | "targetRecurringBonus";

const useStyles = makeStyles(() => ({
  root: {
    height: "1.5rem",

    "& tr": {
      height: "1.5rem",
    },

    "& td": {
      height: "24px",
      paddingTop: 0,
      paddingBottom: 0,
      backgroundColor: GRAY_8,
    },
  },
  text: {
    color: GRAY_4,
    fontSize: "0.8125rem",
    lineHeight: "1.259375",
    fontWeight: 400,
    letterSpacing: "-0.25px",
  },
  alertText: { color: DV_RED },
}));

function getTopLevelBudget(
  rootBudget: CompCycleBudget | null,
  manager: Manager | null,
  budgetKey: BudgetKey
): Money | null {
  // if this budget is an organization level budget
  if (manager === null && rootBudget && rootBudget[budgetKey]) {
    return rootBudget[budgetKey] as Money;
  }

  // if this budget is attached to an employee
  if (manager !== null && manager.compCycleBudget?.[budgetKey]) {
    return manager.compCycleBudget[budgetKey] as Money;
  }

  return null;
}

function getReportsBudget(
  reports: Report[],
  budgetKey: BudgetKey
): Money | null {
  const mappedBudget = mapMaybe(
    reports,
    (employee) => employee.compCycleBudget?.[budgetKey]
  );

  if (mappedBudget.length === 0) return null;

  return mappedBudget.reduce(add, zero(mappedBudget[0].currency));
}

export function TargetSummarizedRow({
  manager,
  reports,
  rootBudget,
  organizationName,
  varianceDisplay,
  compCycleData,
  indentation,
}: Props): JSX.Element {
  const classes = useStyles();
  const { defaultCurrencyCode } = useCurrencies();

  const commission = {
    budgetData: {
      top: getTopLevelBudget(rootBudget, manager, "targetCommission"),
      current: getReportsBudget(reports, "targetCommission"),
    },
    requests: calculateRequestsFromReports(
      reports,
      RecItemType.TARGET_COMMISSION
    ),
  };

  const recurringBonus = {
    budgetData: {
      top: getTopLevelBudget(rootBudget, manager, "targetRecurringBonus"),
      current: getReportsBudget(reports, "targetRecurringBonus"),
    },
    requests: calculateRequestsFromReports(
      reports,
      RecItemType.TARGET_RECURRING_BONUS
    ),
  };

  return (
    <TableRow className={classes.root}>
      {/* Name & title cell + direct & indirect cells */}
      <SummarizedNameCell
        subjectName={manager ? manager.displayName : organizationName}
        indentation={indentation}
      />

      {/* Direct & indirect cells */}
      <TableCell colSpan={1} />
      <TableCell colSpan={1} />
      {[
        ...(compCycleData.allowTargetCommission ? [commission] : []),
        ...(compCycleData.allowTargetRecurringBonus ? [recurringBonus] : []),
      ].map(({ requests, budgetData }) => {
        const budget = budgetData.top
          ? subtract(
              budgetData.top,
              budgetData.current ?? zero(defaultCurrencyCode)
            )
          : null;
        const variance = budget && requests ? subtract(requests, budget) : null;

        return (
          <>
            {/* budget */}
            <TableCell align="right">
              <Typography className={classes.text}>
                {formatBudget(budget)}
              </Typography>
            </TableCell>

            {/* request */}
            <TableCell align="right">
              <Typography className={classes.text}>
                {formatRequest(requests)}
              </Typography>
            </TableCell>

            <TableCell align="right">
              <Typography
                className={clsx(classes.text, {
                  [classes.alertText]: variance && variance.value > 0,
                })}
              >
                {formatVariance(varianceDisplay, budget, variance)}
              </Typography>
            </TableCell>
          </>
        );
      })}
    </TableRow>
  );
}

TargetSummarizedRow.fragments = {
  compCycleBudget: gql`
    fragment TargetSummarizedRow_compCycleBudget on CompCycleBudget {
      compCycleId
      employeeId
      targetCommission
      targetRecurringBonus
    }
  `,
  manager: gql`
    fragment TargetSummarizedRow_manager on Employee {
      id
      displayName

      compCycleBudget(compCycleId: $compCycleId) {
        compCycleId
        employeeId
        targetCommission
        targetRecurringBonus
      }
    }
  `,
  reports: gql`
    fragment TargetSummarizedRow_reports on Employee {
      id
      displayName

      compRecommendation(compCycleId: $compCycleId) {
        subjectId
        compCycleId
        reviewStatus
        latestSubmittedItems {
          id
          submittedAt
          recommendationType
          annualCashEquivalent(currencyCode: $currencyCode)
        }
      }

      compCycleBudget(compCycleId: $compCycleId) {
        compCycleId
        employeeId
        targetCommission
        targetRecurringBonus
      }
    }
  `,
  compCycleData: gql`
    fragment TargetSummarizedRow_compCycleData on CompCycle {
      id
      allowTargetCommission
      allowTargetRecurringBonus
    }
  `,
};
