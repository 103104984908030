import { gql } from "@apollo/client";
import { formatCurrency } from "@asmbl/shared/money";
import { makeStyles, TableCell, TableRow, Typography } from "@material-ui/core";
import { formatNumber } from "accounting";
import { BonusGuidanceOption } from "src/models/Guidance";
import {
  CashCompType,
  CompComponent,
  CurrencyCode,
  ActualTargetBonusGuidanceRow_participant as Participant,
  RecItemInput,
  RecItemType,
} from "../../../../__generated__/graphql";
import { useTrack } from "../../../../analytics";
import { hasUnpublishedChanges } from "../../../../models/CompRecommendation";
import { GRAY_6 } from "../../../../theme";
import { SalaryInputCell } from "../../Cells/SalaryInputCell";
import { ApplyBonusGuidanceCell } from "../../CompCycleSingleRecModal/ApplyBonusGuidanceCell";
import { useCompRecData } from "../../CompCycleSingleRecModal/CompRecommendationContext";
import { CompCycleLineItemRowButton } from "../CompCycleLineItemRowButton";
import { useConnectorStyles, useRecItemRowStyles } from "./styles";

const useStyles = makeStyles(() => ({
  labelContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
  sliderCell: {
    borderTop: `1px solid ${GRAY_6} !important`,
  },
  noteContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

interface Props {
  participant: Participant;
  label: string;
  currency: CurrencyCode | null;
  guidance: BonusGuidanceOption | null;
  recommendationType:
    | RecItemType.ACTUAL_RECURRING_BONUS
    | RecItemType.ACTUAL_RECURRING_BONUS_COMPANY
    | RecItemType.ACTUAL_RECURRING_BONUS_CUSTOM;
  disabled?: boolean;
  onChangeRecommendationItem: (
    newRecommendationItem: RecItemInput | null
  ) => unknown;
}

const component = CompComponent.ACTUAL_RECURRING_BONUS;
const cashCompType = CashCompType.RECURRING_BONUS;

export function ActualTargetBonusGuidanceRow({
  participant,
  currency,
  label,
  onChangeRecommendationItem,
  guidance,
  recommendationType,
  disabled = false,
}: Props): JSX.Element {
  const { Track } = useTrack({
    compensationComponent: component,
    employeeId: participant.subject.id,
  });
  const { submittedRecommendation, revisedRecommendation } = useCompRecData();
  const recItemStyle = useRecItemRowStyles();
  const connectorStyle = useConnectorStyles();
  const classes = useStyles();

  const targetCashComp = participant.subject.activeCashCompensation?.find(
    (c) => c.type === cashCompType
  );

  const [submitted, revised] = [
    submittedRecommendation,
    revisedRecommendation,
  ].map((x) => x?.items.get(recommendationType));

  const currentIncreaseIsGuidance =
    revised?.note === "Applied from guidance suggestion";

  const increase = revised;

  return (
    <Track>
      <TableRow aria-level={3} className={recItemStyle.root}>
        <TableCell role="gridcell" padding="none">
          <div className={connectorStyle.line} />
        </TableCell>
        {guidance !== null && targetCashComp ? (
          <ApplyBonusGuidanceCell
            label={label}
            basePay={targetCashComp}
            submittedRecommendation={submittedRecommendation}
            revisedRecommendation={revisedRecommendation}
            guidance={guidance}
            onChangeRecommendationItem={onChangeRecommendationItem}
            recommendationType={recommendationType}
            hideApply={
              recommendationType === RecItemType.ACTUAL_RECURRING_BONUS_COMPANY
            }
          />
        ) : (
          <TableCell
            role="gridcell"
            colSpan={5}
            padding="none"
            className={recItemStyle.textCell}
          >
            <div className={classes.labelContainer}>
              <Typography className={recItemStyle.rowLabel}>{label}</Typography>
              <Typography
                className={recItemStyle.extraRowText}
                component="span"
              >
                {targetCashComp !== undefined
                  ? `Current target: ${formatCurrency(
                      targetCashComp.annualCashEquivalent,
                      {
                        maximumFractionDigits: 0,
                      }
                    )} / ${formatNumber(targetCashComp.percentOfSalary, 2)}%`
                  : ""}
              </Typography>
            </div>
          </TableCell>
        )}
        <SalaryInputCell
          value={increase}
          basePay={targetCashComp ?? null}
          onChange={(newSalary, unitType) => {
            onChangeRecommendationItem({
              recommendationType,
              recommendedCashValue: newSalary,
              unitType,
              note: currentIncreaseIsGuidance ? undefined : revised?.note,
            });
          }}
          payCurrencyCode={currency}
          hasUnpublishedChanges={hasUnpublishedChanges(submitted, revised)}
          disabled={disabled}
          disableTooltip={disabled}
          isHourly={false}
          hideDogEar={
            recommendationType === RecItemType.ACTUAL_RECURRING_BONUS_COMPANY
          }
        />
        <TableCell
          role="gridcell"
          className={recItemStyle.noteTextCell}
          colSpan={4}
        >
          <div className={classes.noteContainer}>
            <CompCycleLineItemRowButton
              disabled={
                disabled ||
                (increase?.recommendedCashValue === undefined &&
                  increase?.recommendedPercentValue === undefined)
              }
              employee={participant.subject}
              note={increase?.note ?? null}
              onClick={(note: string) =>
                onChangeRecommendationItem({
                  ...increase,
                  recommendationType,
                  note,
                })
              }
            />
          </div>
        </TableCell>
        <TableCell role="gridcell" />
      </TableRow>
    </Track>
  );
}

ActualTargetBonusGuidanceRow.fragments = {
  participant: gql`
    ${CompCycleLineItemRowButton.fragments.employee}
    fragment ActualTargetBonusGuidanceRow_participant on CompCycleParticipant {
      subjectId
      compCycleId
      bonusIndividualPerfRating
      bonusCustomPerfRating
      subject {
        ...CompCycleLineItemRowButton_employee
        id
        activeCashCompensation(currencyCode: $currencyCode) {
          employeeId
          activeAt
          type
          annualCashEquivalent
          hourlyCashEquivalent
          percentOfSalary
          unit
        }
      }
    }
  `,
};
