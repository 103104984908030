import { CurrencyCode } from "@asmbl/shared/constants";
import { Currency } from "@asmbl/shared/currency";
import { Comparator, ComparatorWithOrder } from "@asmbl/shared/sort";
import { cx } from "@emotion/css";
import { TableCell, TableRow, Tooltip, makeStyles } from "@material-ui/core";
import { ComponentType } from "react";
import { CellProps, Column, Row } from "react-table";
import { VirtualItem } from "react-virtual";
import {
  CondensedTable_participant as Participant,
  CondensedTable_position as Position,
} from "src/__generated__/graphql";

import { AssembleTypography } from "src/components/AssembleTypography";
import { useAuth } from "src/components/Auth/AuthContext";
import {
  GRAY_1,
  GRAY_11,
  GRAY_4,
  GRAY_6,
  GRAY_7,
  GRAY_9,
  PURPLE_1,
  WHITE,
} from "src/theme";
import { CondensedTableActionsCell2 as CondensedTableActionsCell } from "../Cells/CondensedTableActionsCell2";
import { CondensedTableActivityCell2 as CondensedTableActivityCell } from "../Cells/CondensedTableActivityCell2";
import { CondensedTableLastActivityCell } from "../Cells/CondensedTableLastActivityCell";
import { CondensedTableNameCell2 as CondensedTableNameCell } from "../Cells/CondensedTableNameCell2";
import {
  LARGE_COL_WIDTH,
  SMALL_COL_WIDTH,
  XLG_COL_WIDTH,
} from "../Cells/dimensions";
import { useBulkActionsData } from "../Contexts/BulkActionsContext";
import { useTableData } from "../Contexts/TableDataContext2";

export type ColumnComponent2 = ComponentType<CellProps<Participant>> & {
  id: string;
  column: Column<Participant>;
  sortable?: boolean;
  ordering: (data: {
    currencies: Map<CurrencyCode, Currency>;
    defaultCurrencyCode: CurrencyCode;
    defaultCurrency: Currency;
    availablePositions: Position[];
    workingHoursPerYear: number | undefined;
  }) => Comparator<Participant> | ComparatorWithOrder<Participant>;
};

const useStyles = makeStyles((theme) => ({
  stickyCell: {
    position: "sticky",
    zIndex: 2,
    background: WHITE,
  },
  row: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: 48,
    "&:hover": {
      "& $tableBodyCell": {
        backgroundColor: `${GRAY_9} !important`,
        borderBottom: `1px solid ${PURPLE_1}`,
      },
      border: `1px solid ${PURPLE_1}`,
    },
    userSelect: "none" /* Standard syntax */,
    webkitUserSelect: "none" /* Safari */,
    msUserSelect: "none" /* IE 10 and IE 11 */,
    borderTop: "1px solid transparent",
    borderBottom: "1px solid transparent",
  },
  nameCellHovering: {
    "&:hover": {
      "& #name-icon": {
        border: `1px solid ${GRAY_6}`,
        borderRadius: "50%",
      },
      "& #user-avatar": {
        color: WHITE,
        border: `2px solid ${PURPLE_1}`,
        borderRadius: "50%",
        background: WHITE,
        height: "18px",
        width: "18px",
      },
      "& #user-avatar > img": {
        opacity: 0,
      },
    },
  },
  tableBodyCell: {
    boxSizing: "border-box",
    boxShadow: `inset -1px 0px 0px ${GRAY_6}`,
    color: GRAY_1,
    fontWeight: 450,
    fontSize: "13px",
    padding: theme.spacing(0, 1.5),
    lineHeight: "130%",
    alignItems: "center",
    height: "48px",
    letterSpacing: "-0.5px",
  },
  nameCell: {
    left: 0,
  },
  actionCell: {
    position: "sticky",
    left: XLG_COL_WIDTH, // account for the name column
  },
  activityCell: {
    position: "sticky",
    // left: account for the name + action columns
    left: XLG_COL_WIDTH + LARGE_COL_WIDTH,
  },
  viewOnlyActivityCell: {
    position: "sticky",
    // left: account for the name columns
    left: XLG_COL_WIDTH,
  },
  lastActivityCell: {
    position: "sticky",
    // left: account for the name action & activity columns
    left: XLG_COL_WIDTH + LARGE_COL_WIDTH + SMALL_COL_WIDTH,
    borderRight: `1px solid ${GRAY_6}`,
    boxShadow: "1px 0px 0px 0px rgba(10, 36, 64, 0.1)",
  },
  viewOnlyLastActivityCell: {
    position: "sticky",
    // left: account for the name + activity columns
    left: XLG_COL_WIDTH + SMALL_COL_WIDTH,
    boxShadow: "1px 0px 0px 0px rgba(10, 36, 64, 0.1)",
    borderRight: `1px solid ${GRAY_6}`,
  },
  lastDefaultColumn: {
    boxShadow: "none",
  },
  rowActedOn: {
    color: GRAY_11,
  },
  rowSelected: {
    background: GRAY_7,
  },
  tooltip: {
    height: "30px",
    zIndex: 0,
  },
  tooltipTextContainer: {
    display: "flex",
    gap: theme.spacing(0.5),
    alignText: "center",
  },
  tooltipCmdText: {
    border: `1px solid ${GRAY_4}`,
    borderRadius: "4px",
    padding: theme.spacing(0, 0.5),
  },
}));

type Props = {
  virtualRow: VirtualItem;
  rows: Row<Participant>[];
  prepareRow: (row: Row<Participant>) => void;
};

export function CondensedTableRow({
  virtualRow,
  rows,
  prepareRow,
}: Props): JSX.Element {
  const classes = useStyles();
  const { userId } = useAuth();
  const { selectedParticipants, setSelectedParticipants, lastSelectedRowID } =
    useBulkActionsData();
  const { isViewOnly } = useTableData();

  const onRowClick = (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    rowId: string,
    subjectId: number,
    rows: Row<Participant>[]
  ) => {
    if (!isViewOnly) {
      // hot key for multiple employee selection
      if (event.shiftKey) {
        const empIDRange = getRowRange(
          rows,
          Number(rowId),
          Number(lastSelectedRowID ?? 0)
        );
        setSelectedParticipants(empIDRange, rowId);
      } else {
        // single employee selection
        setSelectedParticipants([subjectId], rowId);
      }
    }
  };

  const row = rows[virtualRow.index];
  prepareRow(row);

  const rowActedOn =
    row.original.lastActivity != null &&
    row.original.lastActivity.author.id === userId;

  const participantSelected =
    selectedParticipants.get(row.original.subject.id)?.isSelected ?? false;

  return (
    <Tooltip
      className={classes.tooltip}
      placement="top-start"
      title={
        !isViewOnly && participantSelected ? (
          <div className={classes.tooltipTextContainer}>
            <AssembleTypography variant="productTooltip">
              Use
            </AssembleTypography>
            <AssembleTypography
              variant="productTooltip"
              textColor={GRAY_4}
              className={classes.tooltipCmdText}
            >
              Shift
            </AssembleTypography>
            <AssembleTypography variant="productTooltip">
              to select multiple people
            </AssembleTypography>
          </div>
        ) : (
          ""
        )
      }
    >
      <TableRow
        {...row.getRowProps()}
        key={row.getRowProps().key}
        className={cx(classes.row, {
          [classes.nameCellHovering]: !isViewOnly,
        })}
        onClick={(e) => onRowClick(e, row.id, row.original.subject.id, rows)}
        style={{
          transform: `translateY(${virtualRow.start - 1}px)`,
        }}
      >
        {row.cells.map((cell, index) => {
          return (
            <TableCell
              {...cell.getCellProps({
                style: {
                  maxWidth: cell.column.width,
                  width: cell.column.width,
                  minWidth: cell.column.width,
                },
              })}
              key={cell.getCellProps().key}
              className={cx(classes.tableBodyCell, {
                [classes.stickyCell]:
                  cell.column.id === CondensedTableNameCell.id ||
                  cell.column.id === CondensedTableActionsCell.id ||
                  cell.column.id === CondensedTableActivityCell.id ||
                  cell.column.id === CondensedTableLastActivityCell.id,

                [classes.nameCell]:
                  cell.column.id === CondensedTableNameCell.id,
                [classes.actionCell]:
                  cell.column.id === CondensedTableActionsCell.id,
                [classes.activityCell]:
                  cell.column.id === CondensedTableActivityCell.id &&
                  !isViewOnly,
                [classes.viewOnlyActivityCell]:
                  cell.column.id === CondensedTableActivityCell.id &&
                  isViewOnly,
                [classes.lastActivityCell]:
                  cell.column.id === CondensedTableLastActivityCell.id &&
                  !isViewOnly,
                [classes.viewOnlyLastActivityCell]:
                  cell.column.id === CondensedTableLastActivityCell.id &&
                  isViewOnly,

                [classes.lastDefaultColumn]: index === row.cells.length,
                [classes.rowActedOn]: rowActedOn,
                [classes.rowSelected]: participantSelected,
              })}
            >
              {cell.render("Cell")}
            </TableCell>
          );
        })}
      </TableRow>
    </Tooltip>
  );
}

const getRowRange = (
  rows: Row<Participant>[],
  currentID: number,
  selectedID: number
): number[] => {
  const rangeStart = selectedID > currentID ? currentID : selectedID;
  const rangeEnd = rangeStart === currentID ? selectedID : currentID;
  return rows
    .slice(rangeStart, rangeEnd + 1)
    .map((row) => row.original.subject.id);
};
