import { useMultipleMeritMatrices } from "../MultipleMeritMatrices/useMultipleMatrices";
import { useBonusGuidanceAndBudget } from "./BonusGuidanceAndBudget/useBonusGuidanceAndBudget";

/**
 * this is an abstraction for the components that functionally act the same for
 * both merit and the bonus guidance and budgets features
 * this allows the components to pull from `useGuidanceAndBudget` and
 * not have to worry about which context to use since we cannot conditionally
 * useHooks in our components
 * @param featureType the feature context we want to use
 * @returns
 */
export function useGuidanceAndBudget(featureType: "merit" | "bonus") {
  const meritContext = useMultipleMeritMatrices();
  const bonusContext = useBonusGuidanceAndBudget();

  return featureType === "merit" ? meritContext : bonusContext;
}
