import { gql } from "@apollo/client";
import { getSalaryCashComp } from "@asmbl/shared/compensation";
import { formatCurrency } from "@asmbl/shared/money";
import { makeStyles, TableCell, TableRow, Typography } from "@material-ui/core";
import { CashBandName } from "src/constants";
import {
  CashCompType,
  NewSalaryRow_employee,
  NewSalaryRow_position,
} from "../../../../__generated__/graphql";
import { ADJUSTED_CASH_BAND_FIELDS } from "../../../../fragments";
import { isBandPointDefined } from "../../../../models/BandPoint";
import { getTotalActualRecurringBonus } from "../../../../models/CompRecommendation";
import { getSimpleCashLabel } from "../../../../models/Currency";
import { GRAY_1, GRAY_4, GRAY_6 } from "../../../../theme";
import { CondensedBandVisualization } from "../../../CompSlider/CondensedBandVisualization";
import { NullCompSlider } from "../../../CompSlider/NullCompSlider";
import { useCurrencies } from "../../../CurrenciesContext";
import { useCompRecData } from "../../CompCycleSingleRecModal/CompRecommendationContext";
import { useConnectorStyles, useRecItemRowStyles } from "./styles";
interface Props {
  employee: NewSalaryRow_employee;
  promotedPosition: NewSalaryRow_position | null;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTableCell-body": {
      border: "none",
      paddingBlock: theme.spacing(0.3),
    },
  },
  newPlacementContainer: {
    display: "flex",
    alignItems: "center",
  },
  compSliderBox: {
    flex: 1,
  },
  textCell: {
    borderTop: `1px solid ${GRAY_6} !important`,
  },
  salaryAlign: {
    display: "inline-flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  rowLabel: {
    fontWeight: 700,
    fontSize: "13px",
    color: GRAY_1,
    display: "inline",
  },
  compaRatio: {
    color: GRAY_4,
    whiteSpace: "nowrap",
  },
  salaryText: {
    fontWeight: 700,
    fontSize: "0.8125rem",
    textAlign: "right",
  },
  secondarySalaryText: {
    fontWeight: 400,
    color: GRAY_4,
  },
}));

export function TotalActualBonus({
  employee,
  promotedPosition,
}: Props): JSX.Element {
  const classes = useStyles();
  const connectorStyle = useConnectorStyles();
  const recItemStyles = useRecItemRowStyles();
  const { defaultCurrencyCode } = useCurrencies();
  const { revisedRecommendation } = useCompRecData();
  const salaryComp = getSalaryCashComp(employee.activeCashCompensation);
  const items = revisedRecommendation?.items
    ? Array.from(revisedRecommendation.items.values())
    : [];
  const newRecurringBonus = getTotalActualRecurringBonus(
    items,
    salaryComp,
    defaultCurrencyCode
  );
  const targetCashComp = employee.activeCashCompensation?.find(
    (c) => c.type === CashCompType.RECURRING_BONUS
  );
  const adjustedRecurringBand = promotedPosition
    ? promotedPosition.adjustedCashBands?.find(
        (band) => band.name === CashBandName.RECURRING_BONUS
      )
    : employee.adjustedCashBands?.find(
        (band) => band.name === CashBandName.RECURRING_BONUS
      );

  return (
    <TableRow aria-level={3} className={classes.root}>
      <TableCell role="gridcell" padding="none">
        <div className={connectorStyle.line} />
      </TableCell>
      <TableCell
        role="gridcell"
        colSpan={6}
        padding="none"
        className={classes.textCell}
      >
        <>
          <Typography className={classes.rowLabel}>
            Total Actual Bonus{" "}
          </Typography>
          <Typography className={recItemStyles.extraRowText} component="span">
            {targetCashComp !== undefined
              ? ` (Current Target Bonus = ${formatCurrency(
                  targetCashComp.annualCashEquivalent,
                  {
                    maximumFractionDigits: 0,
                  }
                )})`
              : ""}
          </Typography>
        </>
      </TableCell>
      <TableCell role="gridcell" className={classes.textCell}>
        <Typography className={classes.salaryText}>
          {getSimpleCashLabel(newRecurringBonus)}
        </Typography>
      </TableCell>
      <TableCell role="gridcell" className={classes.textCell}>
        <Typography className={classes.salaryText}>
          {targetCashComp
            ? (
                newRecurringBonus.value /
                targetCashComp.annualCashEquivalent.value
              ).toLocaleString("en-US", {
                style: "percent",
                maximumFractionDigits: 2,
              })
            : "0%"}
        </Typography>
      </TableCell>
      <TableCell role="gridcell" className={classes.textCell}>
        <div className={classes.newPlacementContainer}>
          <div className={classes.compSliderBox}>
            {adjustedRecurringBand &&
            adjustedRecurringBand.bandPoints.some(isBandPointDefined) ? (
              <CondensedBandVisualization
                value={newRecurringBonus}
                bandPoints={adjustedRecurringBand.bandPoints}
                position={
                  promotedPosition ??
                  employee.activeEmployment?.position ??
                  null
                }
                size="wide"
                outOfRangeStyle="band"
                showHourly={false}
              />
            ) : (
              <NullCompSlider variant="condensedWide" />
            )}
          </div>
        </div>
      </TableCell>
      <TableCell role="gridcell" className={classes.textCell} />
      <TableCell role="gridcell" className={classes.textCell} colSpan={2} />
      <TableCell role="gridcell" />
    </TableRow>
  );
}

TotalActualBonus.fragments = {
  employee: gql`
    ${ADJUSTED_CASH_BAND_FIELDS}
    fragment TotalActualBonus_employee on Employee2 {
      id
      activeCashCompensation(currencyCode: $currencyCode) {
        employeeId
        type
        activeAt
        annualCashEquivalent
        hourlyCashEquivalent
        percentOfSalary
        unit
      }
      activeEmployment {
        id
        payPeriod
        position {
          id
          name
          level
          type
          ladder {
            id
            name
            department {
              id
              name
            }
          }
        }
      }
      adjustedCashBands {
        id
        ...AdjustedCashBandFields
      }
    }
  `,
  position: gql`
    ${ADJUSTED_CASH_BAND_FIELDS}
    fragment TotalActualBonus_position on Position {
      id
      name
      level
      type
      ladder {
        id
        name
        department {
          id
          name
        }
      }
      adjustedCashBands(
        currencyCode: $currencyCode
        marketId: $marketId
        locationGroupId: $locationGroupId
      ) {
        id
        ...AdjustedCashBandFields
      }
    }
  `,
};
