import { gql, useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { AuthenticationSsoLoadingBoundaryQuery } from "src/__generated__/graphql";
import { LoadingSpinner } from "src/components/LoadingSpinner";
import { useURLSearchParams } from "src/models/URLSearchParams";
import { AuthenticationSso } from "./AuthenticationSso";

export function AuthenticationSsoLoadingBoundary(): JSX.Element {
  const urlSearchParams = useURLSearchParams();
  const token = urlSearchParams.get("token") ?? null;
  const decodedToken = token != null ? decodeURIComponent(token) : null;
  const tokenProvided = token != null;

  const [getData, { data, loading, error }] =
    useLazyQuery<AuthenticationSsoLoadingBoundaryQuery>(
      AuthenticationSsoLoadingBoundary.query,
      {
        variables: { token: decodedToken },
        fetchPolicy: "no-cache",
      }
    );

  // You might be wondering, why are we using a lazyQuery here & calling it
  // immediately instead of just using a regular query. Well, it seems like
  // in some cases the regular query was loading in faster than the
  // `ApolloApp` (on the main App) which was then causing a race condition
  // when then causes an infinite loading loop on the sign up page. By using
  // the lazyQuery we can avoid that issue
  useEffect(() => {
    if (tokenProvided) {
      void getData();
    }
  }, [getData, tokenProvided]);

  if (tokenProvided) {
    if (data === undefined) {
      return loading ? (
        <LoadingSpinner />
      ) : (
        <div>{error?.message ?? "An error has occurred"}</div>
      );
    }
  }

  return <AuthenticationSso userInvitation={data?.userInvitationFromToken} />;
}

AuthenticationSsoLoadingBoundary.query = gql`
  ${AuthenticationSso.fragments.userInvitation}
  query AuthenticationSsoLoadingBoundaryQuery($token: String!) {
    userInvitationFromToken(token: $token) {
      id
      ...AuthenticationSso_userInvitation
    }
  }
`;
