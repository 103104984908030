import { isEmptyString, mapify } from "@asmbl/shared/utils";
import { ImmutableURLSearchParams } from "./URLSearchParams";

export enum FilterParam {
  BAND_PLACEMENT = "band-placement",
  DEPARTMENT = "department",
  LADDER = "ladder",
  LEVEL = "level",
  LOCATION = "location",
  MANAGER = "manager",
  PAY_TYPE = "pay-type",
  PERFORMANCE_RATING = "performance-rating",
  POSITION = "position",
  REC_ITEM = "rec-item",
  REPORTS = "reports",
  TAB = "tab",
  POSITION_TYPE = "position-type",
  GUIDANCE_FILTER = "guidance-filter",
}

export type ReportsParam = "all" | "direct" | "indirect";
export type NumericParam = "all" | "null" | number;

export function getNumericParam(
  urlSearchParams: URLSearchParams | ImmutableURLSearchParams,
  param: FilterParam
): NumericParam {
  const rawParam = urlSearchParams.get(param) ?? "NaN";
  const intParam = parseInt(rawParam);

  return isNaN(intParam) ? "all" : intParam;
}

export function getNumericParams(
  urlSearchParams: ImmutableURLSearchParams,
  param: FilterParam
): Set<NumericParam> {
  const rawParam = urlSearchParams.get(param) ?? "NaN";

  if (rawParam === "null" || rawParam === "all") {
    return new Set<NumericParam>([rawParam]);
  }

  const params = new Set(rawParam.split(",").map((num) => parseInt(num)));

  // if there is a value that is not a number, we change the set to "all"
  return params.has(NaN) ? new Set<NumericParam>(["all"]) : params;
}

export function getNumericListParam(
  url: ImmutableURLSearchParams,
  param: FilterParam
): number[] | undefined {
  const nums = url.get(param) ?? undefined;
  const numsArr =
    nums === undefined
      ? undefined
      : nums
          .split(",")
          .filter((str) => !isEmptyString(str))
          .map((x) => parseInt(x))
          .filter((num) => !isNaN(num));

  if (numsArr === undefined || numsArr.length === 0) return undefined;
  return numsArr;
}
export function getNumericOrNullListParam(
  url: ImmutableURLSearchParams,
  param: FilterParam
): (number | null)[] | undefined {
  const nums = url.get(param) ?? undefined;
  const numsArr =
    nums === undefined
      ? undefined
      : nums
          .split(",")
          .filter((str) => !isEmptyString(str))
          .map((x) => (x === "null" ? null : parseInt(x)))
          .filter((num) => (num === null ? true : !isNaN(num)));

  if (numsArr === undefined || numsArr.length === 0) return undefined;
  return numsArr;
}

export function getStringParams(
  urlSearchParams: ImmutableURLSearchParams,
  param: FilterParam
): string[] | null {
  const rawParam = urlSearchParams.get(param);
  return rawParam != null ? Array.from(new Set(rawParam.split(","))) : null;
}

export function buildOrgTree<
  T extends { id: number; manager: { id: number } | null },
>(reports: T[]): Map<number, T[]> {
  const map = new Map<number, T[]>();
  reports.forEach((report) => {
    if (report.manager === null) return;

    if (!map.has(report.manager.id)) {
      map.set(report.manager.id, []);
    }

    // We don't want a manager to report to themselves, otherwise we could run
    // into an infinite loop when traversing the tree.
    if (report.manager.id !== report.id) {
      map.get(report.manager.id)?.push(report);
    }
  });
  return map;
}

export function getDirectReports<Type>(
  orgTree: Map<number, Type[]>,
  managerId: number | null
): Type[] {
  if (managerId === null) {
    return [];
  }
  return orgTree.get(managerId) ?? [];
}

export function getDirectReportsForManyManagers<Type extends { id: number }>(
  orgTree: Map<number, Type[]>,
  managerIds: (number | null)[]
): Type[] {
  const all = managerIds.flatMap((id) => getDirectReports(orgTree, id));
  return distinct(all);
}

export function getAllReports<Type extends { id: number }>(
  orgTree: Map<number, Type[]>,
  managerId: number | null
): Type[] {
  if (managerId === null) {
    return [];
  }
  const directReports = getDirectReports(orgTree, managerId);
  const all = [
    ...directReports,
    ...directReports.flatMap((report) => getAllReports(orgTree, report.id)),
  ];

  return distinct(all);
}

export function getAllReportsForManyManagers<Type extends { id: number }>(
  orgTree: Map<number, Type[]>,
  managerIds: (number | null)[]
): Type[] {
  const all = managerIds.flatMap((id) => getAllReports(orgTree, id));
  return distinct(all);
}

export function getIndirectReports<Type extends { id: number }>(
  orgTree: Map<number, Type[]>,
  managerId: number | null
): Type[] {
  if (managerId === null) {
    return [];
  }
  const directReports = getDirectReports(orgTree, managerId);
  const all = directReports.flatMap((r) => getAllReports(orgTree, r.id));
  return distinct(all);
}

export function getIndirectReportsForManyManagers<Type extends { id: number }>(
  orgTree: Map<number, Type[]>,
  managerIds: (number | null)[]
): Type[] {
  const all = managerIds.flatMap((id) => getIndirectReports(orgTree, id));
  return distinct(all);
}

/**
 * Takes a list of objects and returns a unique list by ID
 */
function distinct<T extends { id: number }>(employees: T[]): T[] {
  const uniqueById = mapify(employees, "id");
  return [...uniqueById.values()];
}
