import { gql, useQuery } from "@apollo/client";
import { CircularProgress, makeStyles } from "@material-ui/core";
import {
  BonusGuidanceAndBudgetLoadingBoundaryQuery,
  BonusGuidanceAndBudgetLoadingBoundaryQueryVariables,
  MatrixTypeEnum,
} from "src/__generated__/graphql";
import { ErrorView } from "src/components/ErrorView";
import { groupBonusGuidanceMatrices } from "../utils";
import { BonusGuidanceAndBudget } from "./BonusGuidanceAndBudget";
import { BonusGuidanceAndBudgetProvider } from "./useBonusGuidanceAndBudget";

const useStyles = makeStyles(() => ({
  centered: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
}));

type Props = { compCycleId: number };

export function BonusGuidanceAndBudgetLoadingBoundary({
  compCycleId,
}: Props): JSX.Element {
  const classes = useStyles();
  const { data, loading } = useQuery<
    BonusGuidanceAndBudgetLoadingBoundaryQuery,
    BonusGuidanceAndBudgetLoadingBoundaryQueryVariables
  >(BonusGuidanceAndBudgetLoadingBoundary.query, {
    variables: {
      compCycleId,
      types: [
        MatrixTypeEnum.BONUS_GUIDANCE_INDIVIDUAL_PERFORMANCE,
        MatrixTypeEnum.BONUS_GUIDANCE_COMPANY_PERFORMANCE,
        MatrixTypeEnum.BONUS_GUIDANCE_CUSTOM_PERFORMANCE,
      ],
    },
  });

  if (data == null || data.compCycle2 == null) {
    return loading ? (
      <div className={classes.centered}>
        <CircularProgress />
      </div>
    ) : (
      <ErrorView text="Something went wrong. Please refresh the page to try again" />
    );
  }

  return (
    <BonusGuidanceAndBudgetProvider
      compCycleId={compCycleId}
      initValue={{
        allEligible: data.compCycle2.participants.participants,
        shouldPrefillWithGuidance:
          data.compCycle2.bonusGuidanceRecommendationsPrefill,
        organizationCompCyclePerfRating:
          data.compCycle2.organizationCompCyclePerfRating,
        meritMatrices:
          data.compCycle2.matrices.length === 0
            ? undefined
            : groupBonusGuidanceMatrices(data.compCycle2.matrices),
      }}
    >
      <BonusGuidanceAndBudget
        compCycle={data.compCycle2}
        organization={data.organization}
        employees={data.employees}
      />
    </BonusGuidanceAndBudgetProvider>
  );
}

BonusGuidanceAndBudgetLoadingBoundary.query = gql`
  ${BonusGuidanceAndBudget.fragments.compCycle}
  ${BonusGuidanceAndBudget.fragments.organization}
  ${BonusGuidanceAndBudget.fragments.employee}
  ${groupBonusGuidanceMatrices.fragments.matrix}
  query BonusGuidanceAndBudgetLoadingBoundaryQuery(
    $compCycleId: Int!
    $types: [MatrixTypeEnum!]!
  ) {
    organization {
      id
      ...BonusGuidanceAndBudget_organization
    }
    compCycle2(id: $compCycleId) {
      id
      matrices(types: $types) {
        id
        ...groupBonusGuidanceMatrices_matrix
      }
      ...BonusGuidanceAndBudget_compCycle
    }
    employees {
      id
      ...BonusGuidanceAndBudget_employee
    }
  }
`;
