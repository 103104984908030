import { gql, useMutation, useQuery } from "@apollo/client";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { partition } from "@asmbl/shared/utils";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  CondensedTableView_compCycle,
  GetTableOrder,
  GetTableOrderVariables,
  NexusTableNameType,
  UpsertTableOrder,
  UpsertTableOrderVariables,
} from "src/__generated__/graphql";
import { useFeatureFlags } from "src/components/FeatureContext";

export enum ColumnIds {
  NAME = "name-column",
  ACTIONS = "actions-column",
  ACTIVITY = "activity-column",
  LAST_ACTIVITY = "last-activity-column",
  NEW_POSITION = "new-position-column",
  NEW_POSITION_IF_CHANGED = "new-position-if-changed-column",
  PROMOTION = "promotion-column",
  CURRENT_BAND = "current-band-column",
  NEW_BAND = "new-band-column",
  CURRENT_SALARY_COMPA_RATIO = "current-salary-compa-ratio-column",
  NEW_SALARY_COMPA_RATIO = "new-salary-compa-ratio-column",
  CURRENT_TCC_COMPA_RATIO = "current-tcc-compa-ratio-column",
  NEW_TCC_COMPA_RATIO = "new-tcc-compa-ratio-column",
  CURR_SALARY = "curr-salary-column",
  SALARY_DOLLAR_CHANGE = "salary-dollar-change-column",
  SALARY_PERCENT_CHANGE = "salary-percent-change-column",
  NEW_SALARY = "new-salary-column",
  LAST_SALARY_CHANGE_DATE = "last-salary-change-date-column",
  LAST_SALARY_CHANGE_AMOUNT = "last-salary-change-amount-column",
  CURRENT_DEPARTMENT = "current-department-column",
  NEW_DEPARTMENT = "new-department-column",
  CURRENT_LADDER = "current-ladder-column",
  NEW_LADDER = "new-ladder-column",
  CURRENT_POSITION = "current-position-column",
  CURRENT_LEVEL = "current-level-column",
  NEW_LEVEL = "new-level-column",
  MANAGER = "manager-column",
  PERF_RATING = "perf-rating-column",
  TENURE = "tenure-column",
  LOCATION_GROUP = "location-group-column",
  SALARY_MARKET = "salary-market-column",
  SALARY_MERIT = "salary-merit-column",
  SALARY_MERIT_PERCENT = "salary-merit-percent-column",
  SALARY_MERIT_GUIDANCE = "salary-merit-guidance-column",
  SALARY_MERIT_GUIDANCE_DIFF = "salary-merit-guidance-difference-column",
  BONUS_GUIDANCE = "bonus-guidance-column",
  BONUS_GUIDANCE_DIFF = "bonus-guidance-difference-column",
  SALARY_PROMO = "salary-promo-column",
  CURRENT_TARGET_CASH = "current-target-cash-column",
  NEW_TARGET_CASH = "new-target-cash-column",
  ACTUAL_RECURRING_COMPANY_BONUS = "actual-recurring-company-bonus-column",
  ACTUAL_RECURRING_BONUS = "actual-recurring-bonus-column",
  ACTUAL_RECURRING_BONUS_PERCENT = "actual-recurring-bonus-percent-column",
  BONUS = "bonus-column",
  BONUS_PERCENT = "bonus-percent-change-column",
  EQUITY_VALUE = "new-equity-value-column",
  EQUITY_UNITS = "new-equity-units-column",
  EQUITY_BAND_POINT = "equity-band-point-column",
  CURRENT_EQUITY_UNITS = "current-equity-units-column",
  CURRENT_EQUITY_VALUE = "current-equity-value-column",
  CURRENT_TARGET_COMMISSION = "current-target-commission-column",
  CURRENT_TARGET_COMMISSION_PERCENT = "current-target-commission-percent-column",
  CURRENT_TARGET_RECURRING_BONUS = "current-target-recurring-bonus-column",
  CURRENT_TARGET_RECURRING_BONUS_PERCENT = "current-target-recurring-bonus-percent-column",
  NEW_TARGET_COMMISSION = "new-target-commission-column",
  NEW_TARGET_COMMISSION_PERCENT = "new-target-commission-percent-column",
  NEW_TARGET_RECURRING_BONUS = "new-target-recurring-bonus-column",
  NEW_TARGET_RECURRING_BONUS_PERCENT = "new-target-recurring-bonus-percent-column",
}

export const ColumnIdsToHeaders: Map<string, string> = new Map([
  [ColumnIds.NAME, "Name"],
  [ColumnIds.ACTIONS, "Actions"],
  [ColumnIds.ACTIVITY, "Log"],
  [ColumnIds.LAST_ACTIVITY, "Last"],
  [ColumnIds.NEW_POSITION_IF_CHANGED, "New Position (If Changed)"],
  [ColumnIds.PROMOTION, "Position Change"],
  [ColumnIds.CURRENT_BAND, "Current Band Position"],
  [ColumnIds.NEW_BAND, "New Band Position"],
  [ColumnIds.CURRENT_SALARY_COMPA_RATIO, "Current Salary Compa-Ratio"],
  [ColumnIds.NEW_SALARY_COMPA_RATIO, "New Salary Compa-Ratio"],
  [ColumnIds.CURRENT_TCC_COMPA_RATIO, "Current TCC Compa-Ratio"],
  [ColumnIds.NEW_TCC_COMPA_RATIO, "New TCC Compa-Ratio"],
  [ColumnIds.CURR_SALARY, "Current Salary"],
  [ColumnIds.SALARY_DOLLAR_CHANGE, "Salary Change ($)"],
  [ColumnIds.SALARY_PERCENT_CHANGE, "Salary Change (%)"],
  [ColumnIds.NEW_SALARY, "New Salary"],
  [ColumnIds.LAST_SALARY_CHANGE_DATE, "Last Salary Change Date"],
  [ColumnIds.LAST_SALARY_CHANGE_AMOUNT, "Last Salary Change Amount"],
  [ColumnIds.CURRENT_DEPARTMENT, "Department Pre-Cycle"],
  [ColumnIds.NEW_DEPARTMENT, "Department Post-Cycle"],
  [ColumnIds.CURRENT_LADDER, "Ladder Pre-Cycle"],
  [ColumnIds.NEW_LADDER, "Ladder Post-Cycle"],
  [ColumnIds.CURRENT_POSITION, "Position Pre-Cycle"],
  [ColumnIds.NEW_POSITION, "Position Post-Cycle"],
  [ColumnIds.CURRENT_LEVEL, "Level Pre-Cycle"],
  [ColumnIds.NEW_LEVEL, "Level Post-Cycle"],
  [ColumnIds.MANAGER, "Manager"],
  [ColumnIds.PERF_RATING, "Performance Rating"],
  [ColumnIds.TENURE, "Tenure"],
  [ColumnIds.LOCATION_GROUP, "Location Group"],
  [ColumnIds.SALARY_MARKET, "Market Adjustment ($)"],
  [ColumnIds.SALARY_MERIT, "Merit Adjustment ($)"],
  [ColumnIds.SALARY_MERIT_PERCENT, "Merit Adjustment (%)"],
  [ColumnIds.SALARY_MERIT_GUIDANCE, "Merit Guidance"],
  [ColumnIds.SALARY_MERIT_GUIDANCE_DIFF, "Merit Guidance Diff."],
  [ColumnIds.BONUS_GUIDANCE, "Bonus Guidance"],
  [ColumnIds.BONUS_GUIDANCE_DIFF, "Bonus Guidance Diff."],
  [ColumnIds.SALARY_PROMO, "Promotion Adjustment ($)"],
  [ColumnIds.CURRENT_TARGET_CASH, "Curr. Target Cash Compensation"],
  [ColumnIds.NEW_TARGET_CASH, "New Target Cash Compensation"],
  [ColumnIds.ACTUAL_RECURRING_BONUS, "Actual Recurring Bonus"],
  [ColumnIds.ACTUAL_RECURRING_COMPANY_BONUS, "Actual Recurring Company Bonus"],
  [
    ColumnIds.ACTUAL_RECURRING_BONUS_PERCENT,
    "Actual Recurring Bonus (% Target)",
  ],
  [ColumnIds.BONUS, "One-Time Bonus"],
  [ColumnIds.BONUS_PERCENT, "One-Time Bonus (% New Salary)"],
  [ColumnIds.EQUITY_VALUE, "New Equity ($)"],
  [ColumnIds.EQUITY_UNITS, "New Equity (#)"],
  [ColumnIds.EQUITY_BAND_POINT, "New Equity BP"],
  [ColumnIds.CURRENT_EQUITY_UNITS, "Current Equity (#)"],
  [ColumnIds.CURRENT_EQUITY_VALUE, "Current Equity ($)"],
  [ColumnIds.CURRENT_TARGET_COMMISSION, "Curr. Target Commission ($)"],
  [ColumnIds.CURRENT_TARGET_COMMISSION_PERCENT, "Curr. Target Commission (%)"],
  [
    ColumnIds.CURRENT_TARGET_RECURRING_BONUS,
    "Curr. Target Recurring Bonus ($)",
  ],
  [
    ColumnIds.CURRENT_TARGET_RECURRING_BONUS_PERCENT,
    "Curr. Target Recurring Bonus (%)",
  ],
  [ColumnIds.NEW_TARGET_COMMISSION, "New Target Commission ($)"],
  [ColumnIds.NEW_TARGET_COMMISSION_PERCENT, "New Target Commission (%)"],
  [ColumnIds.NEW_TARGET_RECURRING_BONUS, "New Target Recurring Bonus ($)"],
  [
    ColumnIds.NEW_TARGET_RECURRING_BONUS_PERCENT,
    "New Target Recurring Bonus (%)",
  ],
]);

const staticColumns = [
  ColumnIds.NAME,
  ColumnIds.ACTIONS,
  ColumnIds.ACTIVITY,
  ColumnIds.LAST_ACTIVITY,
];

const viewOnlyStaticColumns = [
  ColumnIds.NAME,
  ColumnIds.ACTIVITY,
  ColumnIds.LAST_ACTIVITY,
];

const defaultColumnOrder = [
  ColumnIds.NEW_DEPARTMENT,
  ColumnIds.MANAGER,
  ColumnIds.TENURE,
  ColumnIds.PROMOTION,
  ColumnIds.CURRENT_POSITION,
  ColumnIds.NEW_POSITION_IF_CHANGED,
  ColumnIds.PERF_RATING,
  ColumnIds.CURR_SALARY,
  ColumnIds.SALARY_MERIT_PERCENT,
  ColumnIds.SALARY_PROMO,
  ColumnIds.SALARY_PERCENT_CHANGE,
  ColumnIds.BONUS,
  ColumnIds.EQUITY_VALUE,
];

const defaultDisplayedColumns = [
  ColumnIds.PERF_RATING,
  ColumnIds.CURRENT_POSITION,
  ColumnIds.CURR_SALARY,
  ColumnIds.TENURE,
  ColumnIds.MANAGER,
  ColumnIds.NEW_DEPARTMENT,
];

const defaultHiddenColumns = [
  ColumnIds.LOCATION_GROUP,
  ColumnIds.CURRENT_DEPARTMENT,
  ColumnIds.CURRENT_LADDER,
  ColumnIds.NEW_LADDER,
  ColumnIds.CURRENT_LEVEL,
  ColumnIds.NEW_LEVEL,
  ColumnIds.NEW_SALARY,
  ColumnIds.CURRENT_BAND,
  ColumnIds.NEW_BAND,
  ColumnIds.LAST_SALARY_CHANGE_AMOUNT,
  ColumnIds.LAST_SALARY_CHANGE_DATE,
];

const salaryDisplayedColumns = [ColumnIds.SALARY_PERCENT_CHANGE];

const salaryDefaultHiddenColumns = [
  ColumnIds.SALARY_DOLLAR_CHANGE,
  ColumnIds.CURRENT_SALARY_COMPA_RATIO,
  ColumnIds.NEW_SALARY_COMPA_RATIO,
  ColumnIds.CURRENT_TCC_COMPA_RATIO,
  ColumnIds.NEW_TCC_COMPA_RATIO,
];

const salaryMarketColumns = [ColumnIds.SALARY_MARKET];

const salaryMeritDisplayedColumns = [ColumnIds.SALARY_MERIT_PERCENT];

const salaryMeritDefaultHiddenColumns = [
  ColumnIds.SALARY_MERIT,
  ColumnIds.SALARY_MERIT_GUIDANCE,
  ColumnIds.SALARY_MERIT_GUIDANCE_DIFF,
];

const bonusGuidanceDefaultHiddenColumns = [
  ColumnIds.BONUS_GUIDANCE,
  ColumnIds.BONUS_GUIDANCE_DIFF,
];

const promotionDisplayedColumns = [
  ColumnIds.PROMOTION,
  ColumnIds.NEW_POSITION_IF_CHANGED,
  ColumnIds.SALARY_PROMO,
];

const promotionDefaultHiddenColumns = [ColumnIds.NEW_POSITION];

const targetCashColumns = [
  ColumnIds.CURRENT_TARGET_CASH,
  ColumnIds.NEW_TARGET_CASH,
];

const targetCommissionColumns = [
  ColumnIds.CURRENT_TARGET_COMMISSION,
  ColumnIds.CURRENT_TARGET_COMMISSION_PERCENT,
  ColumnIds.NEW_TARGET_COMMISSION,
  ColumnIds.NEW_TARGET_COMMISSION_PERCENT,
];

const targetRecurringBonusColumns = [
  ColumnIds.CURRENT_TARGET_RECURRING_BONUS,
  ColumnIds.CURRENT_TARGET_RECURRING_BONUS_PERCENT,
  ColumnIds.NEW_TARGET_RECURRING_BONUS,
  ColumnIds.NEW_TARGET_RECURRING_BONUS_PERCENT,
];

const actualRecurringBonusColumns = [
  ColumnIds.ACTUAL_RECURRING_COMPANY_BONUS,
  ColumnIds.ACTUAL_RECURRING_BONUS,
  ColumnIds.ACTUAL_RECURRING_BONUS_PERCENT,
];

const bonusDisplayedColumns = [ColumnIds.BONUS];

const bonusHiddenColumns = [ColumnIds.BONUS_PERCENT];

const equityDisplayedColumns = [ColumnIds.EQUITY_VALUE];

const equityHiddenColumns = [
  ColumnIds.EQUITY_UNITS,
  ColumnIds.EQUITY_BAND_POINT,
  ColumnIds.CURRENT_EQUITY_UNITS,
  ColumnIds.CURRENT_EQUITY_VALUE,
];

type ColumnOrderType = {
  columns: ColumnIds[];
  staticColumns: ColumnIds[];
  setColumns: (columns: ColumnIds[]) => unknown;
  hiddenColumns: ColumnIds[];
  setHiddenColumns: (column: ColumnIds[]) => unknown;
};

export const ColumnOrderContext = createContext<ColumnOrderType>({
  columns: Object.values(ColumnIds),
  staticColumns: staticColumns,
  setColumns: () => {
    // empty default
  },
  hiddenColumns: [],
  setHiddenColumns: () => {
    // empty default
  },
});

export const ColumnOrderProvider = ({
  children,
  cycleSettings,
  userIsViewOnly,
}: {
  children: ReactNode;
  cycleSettings: CondensedTableView_compCycle;
  userIsViewOnly: boolean;
}): JSX.Element => {
  const { data } = useQuery<GetTableOrder, GetTableOrderVariables>(
    ColumnOrderProvider.query,
    {
      variables: {
        tableName: NexusTableNameType.COMP_CYCLE_PLAN,
      },
    }
  );

  const { isEnabled } = useFeatureFlags();
  const hideLastCompChange = isEnabled(FeatureFlag.HideLastCompChange);
  const { displayedColumns, hiddenColumns: displayedHiddenColumns } = useMemo(
    () => getDisplayedColumnsFromSettings(cycleSettings, hideLastCompChange),
    [cycleSettings, hideLastCompChange]
  );

  const [columns, setColumns] = useState<ColumnIds[]>(displayedColumns);
  const [hiddenColumns, setHiddenColumns] = useState<ColumnIds[]>(
    displayedHiddenColumns
  );

  const [upsertTableOrder] = useMutation<
    UpsertTableOrder,
    UpsertTableOrderVariables
  >(UPSERT_TABLE_ORDER, {
    update(cache, { data }) {
      cache.modify({
        id: "ROOT_QUERY",
        fields: {
          tableOrder() {
            if (data == null) return;

            cache.writeFragment({
              data: data.upsertTableOrder,
              fragment: gql`
                fragment tableOrder on TableOrder {
                  id
                  columnOrder
                  hiddenColumns
                }
              `,
            });
          },
        },
      });
    },
    optimisticResponse: {
      upsertTableOrder: {
        __typename: "TableOrder",
        id: data?.tableOrder?.id ?? 0,
        columnOrder: columns,
        hiddenColumns: hiddenColumns,
      },
    },
  });

  const handleSetColumns = async (newColumnOrder: ColumnIds[]) => {
    setColumns(newColumnOrder);
    await upsertTableOrder({
      variables: {
        data: {
          id: data?.tableOrder?.id,
          hiddenColumns: hiddenColumns,
          columnOrder: newColumnOrder,
          tableName: NexusTableNameType.COMP_CYCLE_PLAN,
        },
      },
    });
  };

  const handleSetHiddenColumns = async (newHiddenColumns: ColumnIds[]) => {
    setHiddenColumns(newHiddenColumns);
    await upsertTableOrder({
      variables: {
        data: {
          id: data?.tableOrder?.id,
          hiddenColumns: newHiddenColumns,
          columnOrder: columns,
          tableName: NexusTableNameType.COMP_CYCLE_PLAN,
        },
      },
    });
  };

  useEffect(() => {
    if (data?.tableOrder?.columnOrder) {
      const columns = [...displayedColumns, ...defaultHiddenColumns];
      const missingColumns = displayedColumns.filter(
        (columnId) =>
          !(data.tableOrder?.columnOrder.includes(columnId) ?? false)
      );

      // account for any columns may that have been added
      const tableOrderWithNewColumns = new Set<ColumnIds>([
        ...(data.tableOrder.columnOrder as ColumnIds[]),
        ...missingColumns,
      ]);

      // make sure we're only displaying columns that are in the settings
      const tableOrderWithCompSettingsColumns = Array.from(
        tableOrderWithNewColumns
      ).filter(
        (columnId) =>
          columns.includes(columnId) && !staticColumns.includes(columnId)
      );

      // find any hidden columns that are in the viewable table order
      const tableOrderCurrentlyDefaultHiddenColumns = Array.from(
        defaultHiddenColumns
      ).filter(
        (columnId) => !tableOrderWithCompSettingsColumns.includes(columnId)
      );

      // determine user's hidden columns
      const hiddenColumnsWithCompSettingsColumns =
        data.tableOrder.hiddenColumns.filter(
          (columnId) =>
            columns.includes(columnId as ColumnIds) &&
            tableOrderCurrentlyDefaultHiddenColumns.includes(
              columnId as ColumnIds
            )
        );

      const newHiddenColumns = new Set([
        ...hiddenColumnsWithCompSettingsColumns,
      ]);

      const tableOrderColumns = new Set(tableOrderWithCompSettingsColumns);
      setColumns(Array.from(tableOrderColumns));
      setHiddenColumns(Array.from(newHiddenColumns) as ColumnIds[]);
    }
  }, [data, displayedColumns, displayedHiddenColumns]);

  return (
    <ColumnOrderContext.Provider
      value={{
        columns: columns,
        staticColumns: userIsViewOnly ? viewOnlyStaticColumns : staticColumns,
        setColumns: handleSetColumns,
        hiddenColumns,
        setHiddenColumns: handleSetHiddenColumns,
      }}
    >
      {children}
    </ColumnOrderContext.Provider>
  );
};

export const useColumnOrder = (): ColumnOrderType => {
  return useContext(ColumnOrderContext);
};

ColumnOrderProvider.query = gql`
  query GetTableOrder($tableName: NexusTableNameType!) {
    tableOrder(tableName: $tableName) {
      id
      columnOrder
      hiddenColumns
    }
  }
`;

export const UPSERT_TABLE_ORDER = gql`
  mutation UpsertTableOrder($data: TableOrderInput!) {
    upsertTableOrder(data: $data) {
      id
      columnOrder
      hiddenColumns
    }
  }
`;

const getDisplayedColumnsFromSettings = (
  cycleSettings: CondensedTableView_compCycle,
  hideLastCompChange: boolean
): { displayedColumns: ColumnIds[]; hiddenColumns: ColumnIds[] } => {
  const {
    allowSalary,
    allowSalaryMerit,
    allowSalaryMarket,
    allowSalaryPromotion,
    allowTargetCommission,
    allowTargetRecurringBonus,
    allowActualRecurringBonus,
    allowEquity,
    allowBonus,
  } = cycleSettings;

  const displayedColumns = hideLastCompChange
    ? [...defaultDisplayedColumns].splice(defaultDisplayedColumns.length - 2, 2)
    : defaultDisplayedColumns;
  const hiddenColumns = defaultHiddenColumns;

  if (allowSalaryPromotion) {
    displayedColumns.push(...promotionDisplayedColumns);
    hiddenColumns.push(...promotionDefaultHiddenColumns);
  }
  if (
    allowSalary ||
    allowSalaryMerit ||
    allowSalaryMarket ||
    allowSalaryPromotion
  ) {
    displayedColumns.push(...salaryDisplayedColumns);
    hiddenColumns.push(...salaryDefaultHiddenColumns);
  }
  if (allowSalaryMerit) {
    displayedColumns.push(...salaryMeritDisplayedColumns);
    hiddenColumns.push(...salaryMeritDefaultHiddenColumns);
  }
  if (allowSalaryMarket) {
    displayedColumns.push(...salaryMarketColumns);
  }
  if (allowEquity) {
    displayedColumns.push(...equityDisplayedColumns);
    hiddenColumns.push(...equityHiddenColumns);
  }
  if (allowTargetCommission || allowTargetRecurringBonus) {
    displayedColumns.push(...targetCashColumns);
  } else {
    hiddenColumns.push(...targetCashColumns);
  }
  if (allowTargetCommission) {
    displayedColumns.push(...targetCommissionColumns);
  }
  if (allowTargetRecurringBonus) {
    displayedColumns.push(...targetRecurringBonusColumns);
  }
  if (allowActualRecurringBonus) {
    displayedColumns.push(...actualRecurringBonusColumns);
    hiddenColumns.push(...bonusGuidanceDefaultHiddenColumns);
  }
  if (allowBonus) {
    displayedColumns.push(...bonusDisplayedColumns);
    hiddenColumns.push(...bonusHiddenColumns);
  }

  const [orderedDisplayedColumns, additionalDisplayedColumns] = partition(
    displayedColumns,
    (column) => defaultColumnOrder.includes(column)
  );

  return {
    displayedColumns: [
      ...orderedDisplayedColumns,
      ...additionalDisplayedColumns,
    ],
    hiddenColumns: Array.from(new Set(hiddenColumns)),
  };
};
