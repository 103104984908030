import { gql } from "@apollo/client";
import {
  CurrencyCode,
  EquityRows_employee,
  EquityRows_valuation,
  RecItemInput,
} from "../../../__generated__/graphql";
import { BudgetType } from "../../../models/Budget";
import { useCompRecData } from "../CompCycleSingleRecModal/CompRecommendationContext";
import { EquityGrantRow } from "./LineItemRows/EquityGrant";
import { HeaderSeparatorRow } from "./LineItemRows/HeaderSeparator";

type Props = {
  show: boolean;
  employee: EquityRows_employee;
  currentValuation: EquityRows_valuation;
  onChangeRecommendationItem: (
    newRecommendationItem: RecItemInput | null
  ) => unknown;
};

export function EquityRows({
  show,
  employee,
  currentValuation,
  onChangeRecommendationItem,
}: Props): JSX.Element {
  const { submittedRecommendation, revisedRecommendation } = useCompRecData();
  const currency = (employee.activeEmployment?.payCurrencyCode ??
    null) as CurrencyCode;

  return (
    <>
      <HeaderSeparatorRow text={BudgetType.EQUITY} filtered={!show} />
      {show && (
        <EquityGrantRow
          employee={employee}
          submittedRecommendation={submittedRecommendation}
          revisedRecommendation={revisedRecommendation}
          currency={currency}
          valuation={currentValuation}
          onChangeRecommendationItem={onChangeRecommendationItem}
        />
      )}
    </>
  );
}

EquityRows.fragments = {
  employee: gql`
    ${EquityGrantRow.fragments.employee}
    fragment EquityRows_employee on Employee2 {
      ...EquityGrantRow_employee
      id
      activeEmployment {
        id
        payCurrencyCode
      }
    }
  `,
  valuation: gql`
    ${EquityGrantRow.fragments.valuation}
    fragment EquityRows_valuation on Valuation {
      ...EquityGrantRow_valuation
      id
    }
  `,
};
