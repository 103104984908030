import { gql } from "@apollo/client";
import { EquityBandName } from "@asmbl/shared/constants";
import { FeatureFlag } from "@asmbl/shared/feature-flags";
import { BulkActionsBar } from "src/components/CompCycle/AppBar/BulkActionsBar";
import { useFeatureFlags } from "src/components/FeatureContext";
import { UserViewContextProvider } from "src/contexts/UserViewContext";
import {
  CondensedTable_compCycleBudget as Budget,
  CondensedTable_compCycle as CompCycle,
  CondensedTable_matrix,
  EquityGrantMethod,
  CondensedTable_participant as Participant,
  CondensedTable_position as Position,
  CondensedTable_user as User,
  CondensedTable_valuation as Valuation,
} from "../../../../__generated__/graphql";
import { CycleBudgetAppBar } from "../../../../components/CompCycle/AppBar/CycleBudgetAppBar";
import { useCompStructure } from "../../../../components/CompStructureContext";
import { NoTableData } from "../../../../components/EmptyState/EmptyState";
import { CondensedTableActionsCell2 as ActionsCell } from "../Cells/CondensedTableActionsCell2";
import { CondensedTableActivityCell2 as ActivityCell } from "../Cells/CondensedTableActivityCell2";
import { CondensedTableActualRecurringBonusCell2 as ActualRecurringBonusCell } from "../Cells/CondensedTableActualRecurringBonusCell2";
import { CondensedTableActualRecurringBonusPercentCell2 as ActualRecurringBonusPercentCell } from "../Cells/CondensedTableActualRecurringBonusPercentCell2";
import { CondensedTableBonusCell2 as BonusCell } from "../Cells/CondensedTableBonusCell2";
import { CondensedTableBonusGuidanceCell } from "../Cells/CondensedTableBonusGuidanceCell";
import { CondensedTableBonusGuidanceDifferenceCell } from "../Cells/CondensedTableBonusGuidanceDifferenceCell";
import { CondensedTableBonusPercentCell2 as BonusPercentCell } from "../Cells/CondensedTableBonusPercentCell2";
import { CondensedTableCurrentDepartmentCell2 as CurrentDepartmentCell } from "../Cells/CondensedTableCurrentDepartmentCell2";
import { CondensedTableCurrentEquityUnitsCell2 as CurrentEquityUnitsCell } from "../Cells/CondensedTableCurrentEquityUnitsCell2";
import { CondensedTableCurrentEquityValueCell2 as CurrentEquityValueCell } from "../Cells/CondensedTableCurrentEquityValueCell2";
import { CondensedTableCurrentLadderCell2 as CurrentLadderCell } from "../Cells/CondensedTableCurrentLadderCell2";
import { CondensedTableCurrentLevelCell2 as CurrentLevelCell } from "../Cells/CondensedTableCurrentLevelCell2";
import { CondensedTableCurrentPositionCell2 as CurrentPositionCell } from "../Cells/CondensedTableCurrentPositionCell2";
import { CondensedTableCurrentSalaryCompaRatioCell2 as CurrentSalaryCompaRatioCell } from "../Cells/CondensedTableCurrentSalaryCompaRatioCell2";
import { CondensedTableCurrentTCCCompaRatioCell as CurrentTCCCompaRatioCell } from "../Cells/CondensedTableCurrentTCCCompaRatioCell";
import { CondensedTableCurrentTargetCashCell2 as CurrentTargetCashCell } from "../Cells/CondensedTableCurrentTargetCashCell2";
import { CondensedTableCurrentTargetCommissionCell2 as CurrentTargetCommissionCell } from "../Cells/CondensedTableCurrentTargetCommissionCell2";
import { CondensedTableCurrentTargetCommissionPercentCell2 as CurrentTargetCommissionPercentCell } from "../Cells/CondensedTableCurrentTargetCommissionPercentCell2";
import { CondensedTableCurrentTargetRecurringBonusCell2 as CurrentTargetRecurringBonusCell } from "../Cells/CondensedTableCurrentTargetRecurringBonusCell2";
import { CondensedTableCurrentTargetRecurringBonusPercentCell2 as CurrentTargetRecurringBonusPercentCell } from "../Cells/CondensedTableCurrentTargetRecurringBonusPercentCell2";
import { CondensedTableEquityBandCell2 as EquityBandCell } from "../Cells/CondensedTableEquityBandCell2";
import { CondensedTableEquityUnitsCell2 as EquityUnitsCell } from "../Cells/CondensedTableEquityUnitsCell2";
import { CondensedTableEquityValueCell2 as EquityValueCell } from "../Cells/CondensedTableEquityValueCell2";
import { CondensedTableLastActivityCell as LastActivityCell } from "../Cells/CondensedTableLastActivityCell";
import { CondensedTableLastSalaryChangeAmountCell2 as LastSalaryChangeAmountCell } from "../Cells/CondensedTableLastSalaryChangeAmountCell2";
import { CondensedTableLastSalaryChangeDateCell2 as LastSalaryChangeDateCell } from "../Cells/CondensedTableLastSalaryChangeDateCell2";
import { CondensedTableLocationGroupCell2 as LocationGroupCell } from "../Cells/CondensedTableLocationGroupCell2";
import { CondensedTableManagerCell2 as ManagerCell } from "../Cells/CondensedTableManagerCell2";
import { CondensedTableNameCell2 as NameCell } from "../Cells/CondensedTableNameCell2";
import { CondensedTableNewDepartmentCell2 as NewDepartmentCell } from "../Cells/CondensedTableNewDepartmentCell2";
import { CondensedTableNewLadderCell2 as NewLadderCell } from "../Cells/CondensedTableNewLadderCell2";
import { CondensedTableNewLevelCell2 as NewLevelCell } from "../Cells/CondensedTableNewLevelCell2";
import { CondensedTableNewPayCell as NewPayCell } from "../Cells/CondensedTableNewPayCell";
import { CondensedTableNewPositionCell2 as NewPositionCell } from "../Cells/CondensedTableNewPositionCell2";
import { CondensedTableNewPositionIfChangedCell2 as NewPositionIfChangedCell } from "../Cells/CondensedTableNewPositionIfChangedCell2";
import { CondensedTableNewSalaryBandCell2 as NewSalaryBandCell } from "../Cells/CondensedTableNewSalaryBandCell2";
import { CondensedTableNewSalaryCompaRatioCell2 as NewSalaryCompaRatioCell } from "../Cells/CondensedTableNewSalaryCompaRatioCell2";
import { CondensedTableNewTCCCompaRatioCell as NewTCCCompaRatioCell } from "../Cells/CondensedTableNewTCCCompaRatioCell";
import { CondensedTableNewTargetCashCell2 as NewTargetCashCell } from "../Cells/CondensedTableNewTargetCashCell2";
import { CondensedTableNewTargetCommissionCell2 as NewTargetCommissionCell } from "../Cells/CondensedTableNewTargetCommissionCell2";
import { CondensedTableNewTargetCommissionPercentCell2 as NewTargetCommissionPercentCell } from "../Cells/CondensedTableNewTargetCommissionPercentCell2";
import { CondensedTableNewTargetRecurringBonusCell2 as NewTargetRecurringBonusCell } from "../Cells/CondensedTableNewTargetRecurringBonusCell2";
import { CondensedTableNewTargetRecurringBonusPercentCell2 as NewTargetRecurringBonusPercentCell } from "../Cells/CondensedTableNewTargetRecurringBonusPercentCell2";
import { CondensedTablePerfRatingCell2 as PerfRatingCell } from "../Cells/CondensedTablePerfRatingCell2";
import { CondensedTablePrevPayCell as PrevPayCell } from "../Cells/CondensedTablePrevSalaryCell2";
import { CondensedTablePromotionCell2 as PromotionCell } from "../Cells/CondensedTablePromotionCell2";
import { CondensedTableSalaryBandCell2 as SalaryBandCell } from "../Cells/CondensedTableSalaryBandCell2";
import { CondensedTablePayIncreaseCell as PayIncreaseCell } from "../Cells/CondensedTableSalaryIncreaseCell2";
import { CondensedTableSalaryMarketCell2 as SalaryMarketCell } from "../Cells/CondensedTableSalaryMarketCell2";
import { CondensedTableSalaryMeritCell2 as SalaryMeritCell } from "../Cells/CondensedTableSalaryMeritCell2";
import { CondensedTableSalaryMeritDifferenceCell2 as SalaryMeritDiffCell } from "../Cells/CondensedTableSalaryMeritDifferenceCell2";
import { CondensedTableSalaryMeritGuidanceCell2 as SalaryMeritGuidanceCell } from "../Cells/CondensedTableSalaryMeritGuidanceCell2";
import { CondensedTableSalaryMeritPercentCell2 as SalaryMeritPercentCell } from "../Cells/CondensedTableSalaryMeritPercentCell2";
import { CondensedTableSalaryPercentChangeCell2 as SalaryPercentChangeCell } from "../Cells/CondensedTableSalaryPercentChangeCell2";
import { CondensedTableSalaryPromoCell2 as SalaryPromoCell } from "../Cells/CondensedTableSalaryPromoCell2";
import { CondensedTableTenureCell2 as TenureCell } from "../Cells/CondensedTableTenureCell2";
import { BulkActionsProvider } from "../Contexts/BulkActionsContext";
import { TableDataContextProvider } from "../Contexts/TableDataContext2";
import { CurrencyCodeWithAll } from "./CondensedTableCurrencyPicker";
import { CondensedTableInner } from "./CondensedTableInner";

type Props = {
  selectedCurrency: CurrencyCodeWithAll;
  employees: Participant[];
  availablePositions: Position[];
  isActiveCycle: boolean;
  budget: Budget | null;
  valuation: Valuation;
  user: User;
  compCycle: CompCycle;
  matrices: CondensedTable_matrix[];
  isViewOnly: boolean;
};

export function CondensedTable({
  selectedCurrency,
  employees,
  availablePositions,
  isActiveCycle,
  budget,
  valuation,
  user,
  compCycle,
  matrices,
  isViewOnly,
}: Props): JSX.Element {
  const { compStructure } = useCompStructure();
  const { isEnabled } = useFeatureFlags();

  const hideLastCompChange = isEnabled(FeatureFlag.HideLastCompChange);
  if (employees.length === 0) return <NoTableData />;
  const {
    allowSalary: salary,
    allowSalaryPromotion: promotion,
    allowSalaryMarket: market,
    allowSalaryMerit: merit,
    allowBonus: bonus,
    allowEquity: equity,
    allowTargetCommission: targetCommission,
    allowTargetRecurringBonus: targetRecurringBonus,
    allowActualRecurringBonus: actualRecurringBonus,
  } = compCycle;

  return (
    <UserViewContextProvider>
      <TableDataContextProvider
        value={{
          compCycleId: compCycle.id,
          availablePositions,
          isActiveCycle,
          selectedCurrency,
          indirectReportIds: user.employee?.indirectReportIds ?? [],
          currentPhase: compCycle.currentPhase,
          compCyclePhases: compCycle.phases,
          recommendationsPrefill: compCycle.recommendationsPreFill,
          matrices,
          valuation,
          budget,
          employees,
          compCycle,
          isViewOnly,
        }}
      >
        <BulkActionsProvider participants={employees}>
          <CondensedTableInner
            columns={[
              NameCell,
              ...(isViewOnly ? [] : [ActionsCell]),
              ActivityCell,
              LastActivityCell,
              CurrentPositionCell,
              SalaryBandCell,
              NewSalaryBandCell,
              CurrentSalaryCompaRatioCell,
              CurrentTCCCompaRatioCell,
              NewSalaryCompaRatioCell,
              NewTCCCompaRatioCell,
              ...(salary || market || merit || promotion
                ? [
                    PrevPayCell,
                    PayIncreaseCell,
                    SalaryPercentChangeCell,
                    NewPayCell,
                  ]
                : []),
              ...(hideLastCompChange
                ? []
                : [LastSalaryChangeDateCell, LastSalaryChangeAmountCell]),
              CurrentDepartmentCell,
              NewDepartmentCell,
              CurrentLadderCell,
              NewLadderCell,
              CurrentLevelCell,
              NewLevelCell,
              ManagerCell,
              PerfRatingCell,
              TenureCell,
              LocationGroupCell,
              ...(market ? [SalaryMarketCell] : []),
              ...(merit
                ? [
                    SalaryMeritCell,
                    SalaryMeritPercentCell,
                    SalaryMeritGuidanceCell,
                    SalaryMeritDiffCell,
                  ]
                : []),
              ...(promotion
                ? [
                    PromotionCell,
                    SalaryPromoCell,
                    NewPositionCell,
                    NewPositionIfChangedCell,
                  ]
                : []),
              ...(salary || targetCommission || targetRecurringBonus
                ? [CurrentTargetCashCell, NewTargetCashCell]
                : []),
              ...(targetCommission
                ? [
                    CurrentTargetCommissionCell,
                    CurrentTargetCommissionPercentCell,
                    NewTargetCommissionCell,
                    NewTargetCommissionPercentCell,
                  ]
                : []),
              ...(targetRecurringBonus
                ? [
                    CurrentTargetRecurringBonusCell,
                    CurrentTargetRecurringBonusPercentCell,
                    NewTargetRecurringBonusCell,
                    NewTargetRecurringBonusPercentCell,
                  ]
                : []),
              ...(actualRecurringBonus
                ? [
                    ActualRecurringBonusCell,
                    ActualRecurringBonusPercentCell,
                    CondensedTableBonusGuidanceCell,
                    CondensedTableBonusGuidanceDifferenceCell,
                  ]
                : []),
              ...(bonus ? [BonusCell, BonusPercentCell] : []),
              ...(equity
                ? [
                    CurrentEquityValueCell,
                    EquityValueCell,
                    ...(compStructure?.equityBandTypes.includes(
                      EquityBandName.EQUITY_REFRESH_GRANT
                    )
                      ? [EquityBandCell]
                      : []),
                  ]
                : []),
              ...(equity &&
              compStructure?.equityGrantMethod !== EquityGrantMethod.CASH
                ? [CurrentEquityUnitsCell, EquityUnitsCell]
                : []),
            ]}
            employees={employees}
            compCycle={compCycle}
            budget={budget}
            valuation={valuation}
          />
        </BulkActionsProvider>
      </TableDataContextProvider>
    </UserViewContextProvider>
  );
}

CondensedTable.fragments = {
  participant: gql`
    ${NameCell.fragments.participant}
    ${CurrentDepartmentCell.fragments.participant}
    ${NewDepartmentCell.fragments.participant}
    ${CurrentLadderCell.fragments.participant}
    ${NewLadderCell.fragments.participant}
    ${CurrentPositionCell.fragments.participant}
    ${NewPositionCell.fragments.participant}
    ${NewPositionIfChangedCell.fragments.participant}
    ${CurrentLevelCell.fragments.participant}
    ${NewLevelCell.fragments.participant}
    ${ManagerCell.fragments.participant}
    ${PerfRatingCell.fragments.participant}
    ${TenureCell.fragments.participant}
    ${LocationGroupCell.fragments.participant}
    ${PromotionCell.fragments.participant}
    ${SalaryBandCell.fragments.participant}
    ${NewSalaryBandCell.fragments.participant}
    ${CurrentSalaryCompaRatioCell.fragments.participant}
    ${CurrentTCCCompaRatioCell.fragments.participant}
    ${NewSalaryCompaRatioCell.fragments.participant}
    ${NewTCCCompaRatioCell.fragments.participant}
    ${PrevPayCell.fragments.participant}
    ${SalaryPercentChangeCell.fragments.participant}
    ${PayIncreaseCell.fragments.participant}
    ${SalaryPromoCell.fragments.participant}
    ${SalaryMarketCell.fragments.participant}
    ${SalaryMeritCell.fragments.participant}
    ${NewPayCell.fragments.participant}
    ${SalaryMeritPercentCell.fragments.participant}
    ${SalaryMeritGuidanceCell.fragments.participant}
    ${CondensedTableBonusGuidanceCell.fragments.participant}
    ${CondensedTableBonusGuidanceDifferenceCell.fragments.participant}
    ${ActionsCell.fragments.participant}
    # ^ needs fields
    ${ActivityCell.fragments.participant}
    # ^ needs fields
    ${ActualRecurringBonusCell.fragments.participant}
    ${BonusCell.fragments.participant}
    ${BonusPercentCell.fragments.participant}
    ${LastSalaryChangeDateCell.fragments.participant}
    ${LastSalaryChangeAmountCell.fragments.participant}
    ${CurrentEquityValueCell.fragments.participant}
    ${CurrentEquityUnitsCell.fragments.participant}
    ${EquityValueCell.fragments.participant}
    ${EquityUnitsCell.fragments.participant}
    ${EquityBandCell.fragments.participant}
    ${CurrentTargetCashCell.fragments.participant}
    ${NewTargetCashCell.fragments.participant}
    ${ActualRecurringBonusPercentCell.fragments.participant}
    ${CurrentTargetCommissionCell.fragments.participant}
    ${NewTargetCommissionCell.fragments.participant}
    ${CurrentTargetRecurringBonusCell.fragments.participant}
    ${NewTargetRecurringBonusCell.fragments.participant}
    ${CurrentTargetCommissionPercentCell.fragments.participant}
    ${NewTargetCommissionPercentCell.fragments.participant}
    ${CurrentTargetRecurringBonusPercentCell.fragments.participant}
    ${NewTargetRecurringBonusPercentCell.fragments.participant}
    ${LastActivityCell.fragments.participant}
    ${BulkActionsBar.fragments.participant}
    # ^ needs fields

    fragment CondensedTable_participant on CompCycleParticipant {
      subjectId
      ...CondensedTablePrevSalaryCell2_participant @include(if: $loadSalary)
      ...CondensedTableSalaryPercentChangeCell2_participant
        @include(if: $loadSalary)
      ...CondensedTableSalaryIncreaseCell2_participant @include(if: $loadSalary)
      ...CondensedTableNewSalaryCell2_participant @include(if: $loadSalary)
      ...CondensedTableSalaryMarketCell2_participant @include(if: $loadMarket)
      ...CondensedTableSalaryMeritCell2_participant @include(if: $loadMerit)
      ...CondensedTableSalaryMeritGuidanceCell2_participant
        @include(if: $loadMerit)
      ...CondensedTableBonusGuidanceCell_participant
        @include(if: $loadActualRecurringBonus)
      ...CondensedTableBonusGuidanceDifferenceCell_participant
        @include(if: $loadActualRecurringBonus)
      ...CondensedTableSalaryMeritPercentCell2_participant
        @include(if: $loadMerit)
      ...CondensedTableNewPositionCell2_participant
        @include(if: $loadPromotions)
      ...CondensedTableNewLadderCell2_participant @include(if: $loadPromotions)
      ...CondensedTableNewDepartmentCell2_participant
        @include(if: $loadPromotions)
      ...CondensedTableNewPositionIfChangedCell2_participant
        @include(if: $loadPromotions)
      ...CondensedTableNewLevelCell2_participant @include(if: $loadPromotions)
      ...CondensedTablePromotionCell2_participant @include(if: $loadPromotions)
      ...CondensedTableSalaryPromoCell2_participant
        @include(if: $loadPromotions)
      ...CondensedTableEquityBandCell2_compCycleParticipant
        @include(if: $loadEquity)
      ...CondensedTableCurrentEquityValueCell2_participant
        @include(if: $loadEquity)
      ...CondensedTableCurrentEquityUnitsCell2_participant
        @include(if: $loadEquity)
      ...CondensedTableEquityValueCell2_participant @include(if: $loadEquity)
      ...CondensedTableEquityUnitsCell2_participant @include(if: $loadEquity)
      ...CondensedTableCurrentTargetCommissionCell2_participant
        @include(if: $loadTargetCommission)
      ...CondensedTableCurrentTargetCommissionPercentCell2_participant
        @include(if: $loadTargetCommission)
      ...CondensedTableNewTargetCommissionCell2_participant
        @include(if: $loadTargetCommission)
      ...CondensedTableNewTargetCommissionPercentCell2_participant
        @include(if: $loadTargetCommission)
      ...CondensedTableCurrentTargetRecurringBonusCell2_participant
        @include(if: $loadTargetRecurringBonus)
      ...CondensedTableCurrentTargetRecurringBonusPercentCell2_participant
        @include(if: $loadTargetRecurringBonus)
      ...CondensedTableNewTargetRecurringBonusCell2_participant
        @include(if: $loadTargetRecurringBonus)
      ...CondensedTableNewTargetRecurringBonusPercentCell2_participant
        @include(if: $loadTargetRecurringBonus)
      ...CondensedTableActualRecurringBonusCell2_participant
        @include(if: $loadActualRecurringBonus)
      ...CondensedTableActualRecurringBonusPercentCell2_participant
        @include(if: $loadActualRecurringBonus)
      ...CondensedTableBonusCell2_participant @include(if: $loadBonus)
      ...CondensedTableBonusPercentCell2_participant @include(if: $loadBonus)
      ...CondensedTableLastSalaryChangeDateCell2_participant
        @skip(if: $skipLastCompChange)
      ...CondensedTableLastSalaryChangeAmountCell2_participant
        @skip(if: $skipLastCompChange)
      ...CondensedTableCurrentTargetCashCell2_participant
        @include(if: $loadTargetCash)
      ...CondensedTableNewTargetCashCell2_participant
        @include(if: $loadTargetCash)
      ...CondensedTableNameCell2_participant
      ...CondensedTableActionsCell2_participant
      ...CondensedTableActivityCell2_participant
      ...CondensedTableLastActivityCell_participant
      ...CondensedTableCurrentDepartmentCell2_participant
      ...CondensedTableCurrentLadderCell2_participant
      ...CondensedTableCurrentPositionCell2_participant
      ...CondensedTableCurrentLevelCell2_participant
      ...CondensedTableManagerCell2_participant
      ...CondensedTablePerfRatingCell2_participant
      ...CondensedTableTenureCell2_participant
      ...CondensedTableLocationGroupCell2_participant
      ...CondensedTableSalaryBandCell2_participant
      ...CondensedTableNewSalaryBandCell2_participant
      ...CondensedTableCurrentSalaryCompaRatioCell2_participant
      ...CondensedTableCurrentTCCCompaRatioCell_participant
      ...CondensedTableNewSalaryCompaRatioCell2_participant
      ...CondensedTableNewTCCCompaRatioCell_participant
      ...BulkActionsBar_participant
      compRecommendation(skipEligibility: $skipEligibility) {
        subjectId
        compCycleId
        reviewStatus
      }
    }
  `,

  position: gql`
    ${TableDataContextProvider.fragments().position}
    fragment CondensedTable_position on Position {
      id
      ...TableDataContext2_position
    }
  `,
  valuation: gql`
    ${CycleBudgetAppBar.fragments.valuation}
    fragment CondensedTable_valuation on Valuation {
      id
      ...CycleBudgetAppBar_valuation
    }
  `,
  compCycleBudget: gql`
    ${CycleBudgetAppBar.fragments.compCycleBudget}
    fragment CondensedTable_compCycleBudget on CompCycleBudget {
      ...CycleBudgetAppBar_compCycleBudget
    }
  `,
  user: gql`
    ${TableDataContextProvider.fragments().user}
    fragment CondensedTable_user on User {
      id
      ...TableDataContext_user
    }
  `,
  compCycle: gql`
    ${CycleBudgetAppBar.fragments.compCycle}
    ${ActivityCell.fragments.compCycle2}
    fragment CondensedTable_compCycle on CompCycle2 {
      id
      finalApproversIds
      recommendationsPreFill
      bonusGuidanceRecommendationsPrefill
      organizationCompCyclePerfRating
      phases {
        id
        phaseOrder
        startDate
      }
      organizationCompCyclePerfRating
      ...CycleBudgetAppBar_compCycle
      ...CondensedTableActivityCell2_compCycle2
    }
  `,
};
