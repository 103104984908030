import { gql } from "@apollo/client";
import { makeStyles } from "@material-ui/core";
import uniqBy from "lodash/uniqBy";
import {
  MatrixTable_employee as Employee,
  MatrixTypeEnum,
} from "src/__generated__/graphql";
import { ImpliedPayout } from "./ImpliedPayout";
import { ManualUpload } from "./ManualUpload";
import { MatrixColumn } from "./MatrixColumn";
import { PrefillWithGuidance } from "./PrefillWithGuidance";
import { useBonusGuidanceAndBudget } from "./useBonusGuidanceAndBudget";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  headerRow: {
    display: "flex",
    flexDirection: "row",
  },
}));

type Props = { employees: Employee[] };

export function MatrixTable({ employees }: Props): JSX.Element {
  const classes = useStyles();

  const {
    matrices: { selectedMatrix },
  } = useBonusGuidanceAndBudget();

  const uniquePerfRatingOptions = uniqBy(
    (selectedMatrix?.bonusIndividual.matrixGuides ?? []).map(
      (guide) => guide.matrixPerfRatingOption
    ),
    "id"
  ).sort((a, b) => (a.rank < b.rank ? -1 : 1));

  return (
    <div className={classes.root}>
      <div className={classes.headerRow}>
        <MatrixColumn
          type={MatrixTypeEnum.BONUS_GUIDANCE_INDIVIDUAL_PERFORMANCE}
          title="Individual Performance"
          description="Enter the percentage bonus an individual should receive based on their performance"
          perfRatingOptions={uniquePerfRatingOptions}
          matrix={selectedMatrix?.bonusIndividual}
        />
        <MatrixColumn
          type={MatrixTypeEnum.BONUS_GUIDANCE_COMPANY_PERFORMANCE}
          title="Company Attainment"
          description="Enter the percentage bonus an individual should receive based on the company's attainment"
          matrix={selectedMatrix?.bonusCompany}
        />
      </div>
      <ImpliedPayout />
      <ManualUpload employees={employees} />
      <PrefillWithGuidance />
    </div>
  );
}

MatrixTable.fragments = {
  compCycle: gql`
    ${PrefillWithGuidance.fragments.compCycle}
    fragment MatrixTable_compCycle on CompCycle2 {
      id
      ...PrefillWithGuidance_compCycle
    }
  `,
  employee: gql`
    ${ManualUpload.fragments.employee}
    fragment MatrixTable_employee on Employee {
      id
      ...ManualUpload_employee
    }
  `,
};
